import axios from "axios";

import { Avatar } from "./types";



const useAvatars = () => {
  /******************/
  /** Create Avatar */
  const createAvatar = (data: any) => {
    return new Promise<Avatar[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/avatars/`,
        method: "post",
        data
      }).then((res) => {
        resolve(res.data);
      }).catch((error) => {
        reject(error)
      });
    });
  }

  /*****************/
  /** List Avatars */
  const listAvatars = () => {
    return new Promise<Avatar[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/avatars/`,
      }).then((res) => {
        resolve(res.data);
      }).catch((error) => {
        reject(error)
      });
    });
  }

  return {
    listAvatars,
    createAvatar
  }
}

export default useAvatars;