import styled from "styled-components";

import { colors, properties } from "../../../styles";



const Styles = styled.div`
  border: 1px solid ${colors.grey1};
  background-color: ${colors.grey3};
  border-radius: 7px;
  overflow: hidden;
  max-width: ${properties.maxFormWidth};

  .heading {
    padding: 0.5rem;
    background-color: #2f2f2f;
    .title {
      color: ${colors.grey1};
      font-size: 0.8rem;
    }
  }

  .code-wrapper {
    padding: 1rem;
    overflow: auto;
    code {
      white-space: pre-wrap;
      color: ${colors.white};
    }
  }
`;

export default Styles;