import axios from "axios";

import { ErrorResponse, PaginatedResponse } from "../../types";
import {
  PlanPriceDiscountCreateArgs,
  PlanPriceDiscount,
  PlanPriceDiscountUpdateArgs
} from "./types";



/*  ######################################### */
/** ## PLAN PRICE DISCOUNT NETWORK REQUESTS # */
/*  ######################################### */
const usePlanPriceDiscounts = () => {
  const createPlanPriceDiscount = (data: PlanPriceDiscountCreateArgs) => {
    return new Promise<PlanPriceDiscount>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/plan-price-discounts/`,
        method: "post",
        data
      }).then((res) => {
        const resData = res.data as PlanPriceDiscount;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  const listPlanPriceDiscounts = (params?: Record<string, string>) => {
    return new Promise<PaginatedResponse<PlanPriceDiscount>>(
      (resolve, reject) => {
        axios({
          url: `${process.env.REACT_APP_SONA_AUTH_API_URL
            }/v2/plan-price-discounts/`,
          ...(params ? { params } : {})
        }).then((res) => {
          const resData = res.data as PaginatedResponse<PlanPriceDiscount>;
          resolve(resData);
        }).catch((error) => {
          reject(error as ErrorResponse);
        });
      });
  }

  const getPlanPriceDiscount = (id: string | number) => {
    return new Promise<PlanPriceDiscount>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/plan-price-discounts/${id}`,
      }).then((res) => {
        const resData = res.data as PlanPriceDiscount;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  const updatePlanPriceDiscount = (
    id: string | number,
    data: PlanPriceDiscountUpdateArgs
  ) => {
    return new Promise<PlanPriceDiscount>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/plan-price-discounts/${id}`,
        method: "patch",
        data
      }).then((res) => {
        const resData = res.data as PlanPriceDiscount;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  const deletePlanPriceDiscount = (id: string | number) => {
    return new Promise<any>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/plan-price-discounts/${id}`,
        method: "delete",
      }).then((res) => {
        resolve(res);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }



  return {
    createPlanPriceDiscount,
    listPlanPriceDiscounts,
    getPlanPriceDiscount,
    updatePlanPriceDiscount,
    deletePlanPriceDiscount
  }
}

export default usePlanPriceDiscounts;