import { ChangeEvent, MouseEvent } from "react";

import { useQueryParams } from "../../../utils/components";
import { PaginatedResponse } from "../../../types";
import { useInput } from "../../../utils/forms";
import { PageSizeOptions } from "./utils";
import { SelectField } from "../../forms";



function TablePagination({
  pages,
  pageSize: initialPageSize,
  previousPage,
  nextPage
}: Partial<PaginatedResponse<unknown>>) {
  const { queryParams, updateFilters } = useQueryParams();
  const page = queryParams.get("page") || "1";
  const pageSize = queryParams.get("pageSize") || "";
  const {
    value: pageSizeValue,
    setValue: setPageSizeValue
  } = useInput({
    initialValue: pageSize ? pageSize
      : initialPageSize ? initialPageSize.toString()
        : "",
    selectValues: PageSizeOptions.map(option => option.value)
  });


  const updatePageSize = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setPageSizeValue(value);
    updateFilters("pageSize", value);
  }

  const getPageInUrl = (url: string) => {
    const query = url.split("?")[1];
    const queryParams = new URLSearchParams(query);
    const page = queryParams.get("page") ?? "1";

    return page;
  }

  const goToPage = (e: MouseEvent, page: number | string) => {
    e.preventDefault();
    updateFilters("page", page)
  }



  return (
    <div className="pagination flex justify-between align-center">
      {/* Pages */}
      <ul className="pages flex align-center">
        {previousPage ? (
          <button
            className="nav"
            onClick={(e) => goToPage(e, getPageInUrl(previousPage))}>
            Prev
          </button>
        ) : null}
        <div className="numbers">
          {[...Array(pages)].map((_, index) =>
            <button
              key={index}
              type="button"
              className={
                `pagination-btn ${((index + 1) === parseInt(page))
                  ? "active" : ""}`
              }
              onClick={(e) => goToPage(e, index + 1)}
            >
              {index + 1}
            </button>
          )}
        </div>

        {nextPage ? (
          <button
            className="nav"
            onClick={(e) => goToPage(e, getPageInUrl(nextPage))}
          >
            Next
          </button>
        ) : null}
      </ul>
      {/* Items per page */}
      <div className="pagesize">
        <SelectField
          options={PageSizeOptions}
          value={pageSizeValue}
          onChange={updatePageSize}
          placeholder="Results per Page"
        />
      </div>
    </div>
  );
}

export default TablePagination;