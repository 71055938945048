import { FormField } from "../../../../components/forms/Form/types";
import {
  coordinates,
  barren,
  seed,
  sprout,
  sproutWatering,
  seedling,
  seedlingWatering1,
  seedlingWatering2,
  sapling,
  saplingWatering1,
  saplingWatering2,
  youngTree,
  youngTreeWatering1,
  youngTreeWatering2,
  treeCompleted
} from "./fields"



const useTreeDetailUtils = () => {
  const fields: FormField[] = [
    ...coordinates,
    ...barren,
    ...seed,
    ...sprout,
    ...sproutWatering,
    ...seedling,
    ...seedlingWatering1,
    ...seedlingWatering2,
    ...sapling,
    ...saplingWatering1,
    ...saplingWatering2,
    ...youngTree,
    ...youngTreeWatering1,
    ...youngTreeWatering2,
    ...treeCompleted
  ]


  return {
    fields
  }
}

export default useTreeDetailUtils;