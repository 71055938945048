import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { useFormNavigation } from "../../../components/forms/FormSteps";
import { FormStep } from "../../../components/forms/FormSteps/types";
import { FormValues } from "../../../components/forms/Form/types";
import useAffirmations from "../../../requests/affirmations";
import { Affirmation, Desert } from "../../../types/core";
import { PageHeader } from "../../../components/layout";
import useAppActions from "../../../redux/actions/app";
import { FormSteps } from "../../../components/forms";
import useMissions from "../../../requests/missions";
import { URLParams } from "../../../constants";
import useAffirmationUtils from "./utils";
import PreviewForm from "./PreviewForm";
import SetupForm from "./SetupForm";
import Styles from "./styles";



const CreateEditAffirmation = () => {
  const { affirmationId } = useParams<URLParams>();
  const { setDeserts } = useAppActions();
  const { listDeserts } = useMissions();
  const { getAffirmation } = useAffirmations();
  const { currentStep, ...formNavigation } = useFormNavigation();
  const [affirmation, setAffirmation] = useState<Affirmation>();
  const [formValues, setFormValues] = useState<FormValues>({});
  const { confirmChanges } = useAffirmationUtils({
    affirmation, formValues, affirmationId
  });


  /***************/
  /** Form Steps */
  const formSteps: FormStep[] = [
    {
      title: "Setup",
      content: (
        <SetupForm
          {...formNavigation}
          values={formValues}
          setValues={setFormValues}
        />
      )
    },
    {
      title: "Preview Affirmation",
      content: (
        <PreviewForm
          {...formNavigation}
          values={formValues}
          setValues={setFormValues}
          confirmChanges={confirmChanges}
        />
      )
    }
  ]


  useEffect(() => {
    if (!affirmationId) return;
    getAffirmation(affirmationId)
      .then((resData) => {
        const affirmation = resData as Affirmation;
        if (!affirmation) return;
        const {
          tree, createdAt, updatedAt, ...otherFields
        } = affirmation;
        setAffirmation(affirmation);
        setFormValues((prevState) => ({
          ...prevState,
          ...otherFields,
          desertId: tree?.desertId,
          region: tree?.region
        }));
      }).catch(() => null);
  }, [affirmationId])

  useEffect(() => {
    // Initialize
    listDeserts()
      .then((resData) => {
        const deserts = resData as Desert[];
        setDeserts(deserts);
      }).catch(() => null);
  }, [])



  return (
    <Styles className="root-content">
      <PageHeader
        title={affirmationId
          ? "Edit Affirmation"
          : "Create Affirmation"
        }
      />
      <div className="page-wrapper">
        <FormSteps
          currentStep={currentStep}
          steps={formSteps}
        />
      </div>
    </Styles>
  );
}

export default CreateEditAffirmation;