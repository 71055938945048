import { missionPaths } from "./paths";
import { RouteType } from "../types";
import {
  CreateEditMission,
  MissionDraft,
  MissionPublished
} from "../../pages/Missions/pages";



/*******************/
/** Mission Routes */
export const missionRoutes: RouteType[] = [
  {
    path: missionPaths.PUBLISHED,
    element: <MissionPublished />
  },
  {
    path: missionPaths.DRAFT,
    element: <MissionDraft />
  },
  {
    path: missionPaths.CREATE,
    element: <CreateEditMission />,
  },
  {
    path: missionPaths.EDIT,
    element: <CreateEditMission />,
  }
]