import * as ReactDOMClient from "react-dom/client";
import { StrictMode } from "react";

import ErrorBoundary from "./_App/ErrorBoundary";
import reportWebVitals from "./reportWebVitals";
import App from "./_App";



const container = document.getElementById("SONARoot");
const body = document.body;
const root = ReactDOMClient.createRoot(
  container
    ? container
    : body
);

root.render(
  <StrictMode>
    <ErrorBoundary fallback={<div>There was an error</div>}>
      <App />
    </ErrorBoundary>
  </StrictMode>
);

reportWebVitals();