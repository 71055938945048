import styled from "styled-components";

import { colors, properties, resolutions, snippets } from "../../../styles";



const Styles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  &>* {
    width: 100%;
  }

  ${'' /* Form Headings */}
  ol.form-headings {
    display: flex;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;
    row-gap: 1rem;
    margin-bottom: 2rem;
    @media(max-width: ${resolutions.tablet}) {
      gap: 1rem;
    }
    li.form-heading {
      display: flex;
      align-items: center;
      gap: 11px;
      &.active {
        .number {
          color: ${colors.primary};
          background-color: ${colors.accent1};
          border-color: ${colors.primary};
        }
        .title {
          color: ${colors.primary};
        }
      }
      &>* {
        color: ${colors.grey6};
        transition: all ${properties.transition};
        font-weight: 600;
      }
      .number {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: ${colors.grey9};
        font-size: 8px;
        border: 1px solid transparent;
        ${snippets.flexCenter};
      }
      .title {
        font-size: 12px;
      }
    }
  }

  ${'' /* Form Pages */}
  .form-pages {
    flex: 1;
    display: flex;
    align-items: stretch;
    overflow-x: visible;
    .form-page {
      flex-shrink: 0;
      width: 100%;
      position: relative;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      .form {
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: ${properties.maxFormWidth};
      }
      section {
        &:not(:last-child) {
          padding-bottom: 60px;
          margin-bottom: 60px;
          border-bottom: 1px solid ${colors.grey1};
        }
      }
    }
  }

  ${'' /* Form Navigation */}
  .form-controls {
    padding-top: 32px;
    margin-top: auto;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .button.next {
      margin-left: auto;
    }
  }
`;

export default Styles;