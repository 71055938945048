import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { CardLoader, FamilyCrest } from "../../../../components/layout";
import { accountPaths } from "../../../../routes/accounts/paths";
import { Family } from "../../../../requests/families/types";
import { capitalizeKey } from "../../../../utils/strings";
import { FilteredUserData } from "./types";



type Props = {
  family: Family<undefined> | null;
}

function FamilyData({ family }: Props) {
  const [formattedFamily, setFormattedFamily] = useState<any>();

  /*******************************/
  /** Generate Custom Family Data */
  const formatFamilyData = (family: Family) => {
    const filteredData = familyKeys.reduce((obj, rawKey) => {
      const rawValue = family[rawKey];
      const { key, value } = filterFamilyData(rawKey, rawValue) as any;
      obj[key] = value;
      return obj;
    }, {} as any);
    setFormattedFamily(filteredData);
  }


  useEffect(() => {
    if (!family) return;
    formatFamilyData(family);
  }, [family])



  return (
    !(family && formattedFamily) ? (
      <CardLoader />
    ) : (
      <>
        <div className="heading">
          <div className="card-title">
            Family
          </div>
          <Link
            to={accountPaths.familyDetail(family.id)}
            className="family-crest-link"
          >
            <FamilyCrest family={family} />
          </Link>
        </div>

        <ul className="detail-list flex column">
          {Object.entries(formattedFamily).map(([key, value]) =>
            <li className="list-item no-break" key={key}>
              <div className="key">
                {key}
              </div>
              <div className="value">
                {value ? value.toString() : "-"}
              </div>
            </li>
          )}
        </ul>
      </>
    )
  )
}

export default FamilyData;



// Fields to show on component
type FamilyKey = keyof Family<null>;
const familyKeys: FamilyKey[] = [
  "name", "userCount",
]

/*****************************************/
/** Format output data for Family fields */
const filterFamilyData = (
  rawKey: FamilyKey,
  rawValue: any
): FilteredUserData => {
  let key = capitalizeKey<Family<null>>(rawKey);
  let value = rawValue ? rawValue.toString() : "";
  switch (rawKey) {
    case "name":
      return {
        key: "Family Name",
        value
      }
    // case "clanId":
    //   return {
    //     key: "Clan",
    //     value: rawValue ? value : "-"
    //   }
    case "userCount":
      key = "Current Users"
      return {
        key,
        value
      }
    default: {
      return {
        key,
        value
      }
    }
  }
}