import styled from "styled-components";

import close_icon from "../../../assets/vector-images/generic/close-icon.svg";
import { colors, functions, properties } from "../../../styles";



const Styles = styled.div`
  position: relative;
  .countries {
    width: 200px;
    .field-column {
      margin-bottom: 0;
      input {
        padding: 5px;
      }
    }
    .country-list {
      background-color: ${colors.white};
      box-shadow: ${properties.boxShadow};
      position: absolute;
      width: inherit;
      z-index: 1;
      top: 100%;
      margin: 0.5rem 0;
      max-height: 18rem;
      height: auto;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      .radio-button-field {
        margin: 0.5rem;
        margin-bottom: 0;
        &:last-of-type {
          margin-bottom: 0.5rem;
        }
        label {
          .flex {
            .flag {
              font-size: 1.1rem;
            }
            .name {
              font-size: 0.8rem;
              font-weight: 400;
              color: ${colors.grey3};
            }
          }
        }
        input:checked ~ label {
          border-color: ${colors.primary};
          background-color: ${functions.rgba(colors.primary, 0.3)};
        }
      }
    }
  }
  
  .selected-country {
    width: auto;
    height: fit-content;
    padding: 4px;
    border-radius: 5px;
    border: 1px solid ${colors.primary};
    background-color: ${functions.rgba(colors.primary, 0.3)};
    font-size: 0.8rem;
    margin-bottom: 2px;
    text-align: left;
    position: relative;
    padding-right: 1.5rem;
    &::after {
      content: "";
      position: absolute;
      right: 0.25rem;
      top: 50%;
      transform: translateY(-50%);
      width: 0.8rem;
      height: 0.8rem;
      background-size: contain;
      background-image: url(${close_icon});
    }
  }
`;

export default Styles;