import { Dispatch, MouseEvent, SetStateAction, useRef } from "react";

import { ChildFormProps } from "../../../components/forms/FormSteps/types";
import { FormField } from "../../../components/forms/Form/types";
import { Form } from "../../../components/forms";
import { Quiz } from "../../../types/core";
import QuizQuestion, { createQuiz } from
  "../../../components/forms/QuizQuestion";



function QuizForm({
  values,
  setValues,
  nextStep,
  FormNavigation,
  quizzes,
  setQuizzes
}: ChildFormProps & {
  quizzes: Quiz[];
  setQuizzes: Dispatch<SetStateAction<Quiz[]>>;
}) {
  const formRef = useRef<HTMLFormElement>(null);


  /*************/
  /** Add Quiz */
  const addQuiz = (e: MouseEvent) => {
    e.preventDefault();
    const newQuiz = createQuiz();
    setQuizzes((qzs) => [...qzs, newQuiz]);
  }

  /****************/
  /** Quiz Fields */
  const fields: FormField[] = [
    {
      type: "text-editor",
      name: "additionalReading",
      label: "Additional Reading & References",
      placeholder: "Add additional reading & references for this mission",
      groupClass: "field-group 1"
    },
    {
      type: "react-node",
      name: "",
      label: "",
      groupClass: "field-group 1",
      reactNode: (
        <div className="quizzes">
          {quizzes.map((quiz) =>
            <QuizQuestion
              key={quiz.id}
              quiz={quiz}
              quizzes={quizzes}
              setQuizzes={setQuizzes}
            />
          )}
          <button
            className="button full-width"
            onClick={addQuiz}
          >
            Add Another Quiz
          </button>
        </div>
      )
    }
  ]



  return (
    <Form
      fields={fields}
      values={values}
      setValues={setValues}
      onSubmit={nextStep}
      ref={formRef}
    >
      <FormNavigation />
    </Form>
  );
}

export default QuizForm;