import { SelectFieldOption } from "../components/forms/SelectField";

export const ProductTypeOptions: SelectFieldOption[] = [
  {
    value: "Voucher",
    label: "Voucher"
  },
  {
    value: "Airtime",
    label: "Airtime"
  }
]