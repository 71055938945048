import styled from "styled-components";

import { colors } from "../../../styles";


const Styles = styled.div`
  background-color: ${colors.grey7};
  padding: 2rem;
  border-radius: 10px;
  .question {
    .btn-image.delete {
      background-color: ${colors.error};
      padding: 10px;
      width: 49px;
      height: 49px;
      border-radius: 5px;
      svg {
        height: 100%;
        width: 100%;
        path {
          stroke: ${colors.white};
        }
      }
    }
  }
`;

export default Styles;