import styled from "styled-components";

import { colors, properties } from "../../../styles";



const Styles = styled.div`
  flex: 1;
  .form-page {
    #mission, #affirmation {
      img {
        width: 90px;
        height: 50px;
        object-fit: cover;
        border-radius: 5px;
      }
      .title {
        font-size: 14px;
      }
    }
  }
  .title-label {
    font-size: 12px;
    font-weight: 600;
    color: ${colors.grey3};
    margin-bottom: 10px;
  }

  ${'' /* Setup */}
  #setupPage {
    .file-uploads {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 16px;
      .field-column {
        margin: 0;
      }
      .file-field {
        flex: 1;
      }
    }
  }

  ${'' /* Quizzes */}
  #quizzesPage {
    max-width: ${properties.maxFormWidth};
    .quizzes {
      display: flex;
      gap: 1.5rem;
      flex-direction: column;
      .field-column {
        margin-bottom: 0;
      }
      .button.full-width {
        height: max-content;
      }
    }
  }

  ${'' /* Preview */}
  #previewPage {
    max-width: ${properties.maxFormWidth};
    min-height: 100%;
    .field-column {
      margin-bottom: 3rem;
      gap: 0.5rem;
    }
    .edit-icon {
      width: 15px;
    }
    #description {
      .content {
        min-height: 5rem;
        padding: 1rem;
        border: 1px solid ${colors.grey1};
        border-radius: 5px;
        font-size: 12px;
      }
    }
    #quizzes {
      .quiz {
        padding: 1rem;
        border: 1px solid ${colors.grey1};
        border-radius: 5px;
        font-size: 12px;
        color: ${colors.grey3};
        &:not(:last-child) {
          margin-bottom: 2rem;
        }
        .heading {
          margin-bottom: 1rem;
          .title {
            color: ${colors.grey6};
          }
        }
        .question {
          font-weight: 600;
          color: ${colors.grey3};
        }
        .option {
          margin-top: 8px;
          &.correct {
            color: ${colors.primary};
          }
        }
      }
    }
  }
`;

export default Styles;