import { SelectFieldOption } from "../../components/forms/SelectField";
import { EmailUser } from "../users/types";



export type EmailStatus =
  | "scheduled"
  | "sending"
  | "sent"
  | "failed";

export type Email = {
  id: number;
  email: string;
  templateName: string;
  templateId: string;
  templateParams: any;
  status: EmailStatus;
  errorResponse: string;
  scheduledAt?: Date | string;
  sentAt?: Date | string;
  receivedBy?: EmailUser;
  receivedById?: string;
  sentBy?: EmailUser;
  sentById?: string;
  createdAt: Date;
  updatedAt: Date;
}

interface EmailStatusOption extends SelectFieldOption {
  value: EmailStatus | "";
}

export const EmailStatusOptions: EmailStatusOption[] = [
  {
    value: "",
    label: "All"
  },
  {
    value: "scheduled",
    label: "Scheduled"
  },
  {
    value: "sent",
    label: "Sent"
  },
  {
    value: "failed",
    label: "Failed"
  }
]

export type EmailTemplate = {
  id: string;
  name: string;
  type: string;
  image_path: string;
  createdAt: Date | string;
  variables: {
    variables: any[];
    personalization: {
      [key: string]: string;
    }
  };
}

export type EmailTemplateListResponse = {
  data: EmailTemplate[];
  links: {
    first: string;
    last: string | null;
    prev: string | null;
    next: string | null;
  }
  meta: {
    current_page: number;
    from: number;
    path: string;
    per_page: number;
    to: number;
  }
}