import styled from "styled-components";

import { colors, properties, snippets } from "../../../styles";



const Styles = styled.div`
  background-color: ${colors.grey5};
  #Console {
    height: calc(100vh - ${properties.navHeaderHeight});
    overflow: hidden;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
`;

export default Styles;