export const dashboardRoot = "/dashboard";

const dashEventFunnels = `${dashboardRoot}/events/funnels`



export const dashboardPaths = {
  // Events
  EVENTS: `${dashboardRoot}/events`,
  CREATE_EVENT_FUNNEL: `${dashEventFunnels}/create`,
  LIST_EVENT_FUNNELS: `${dashEventFunnels}/`,
  EVENT_FUNNEL_DETAIL: `${dashEventFunnels}/:funnelId`,
  eventFunnelDetail: (funnelId: string | number) => (
    `${dashEventFunnels}/${funnelId}`
  )
}