import styled from "styled-components";

import { colors, functions } from "../../../../styles";



const Styles = styled.div`
  fieldset {
    border: 1px dashed ${colors.grey3};
    border-radius: 7px;
    background-color: ${functions.rgba(colors.black, 0.01)};;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    legend {
      padding: 0;
      font-weight: 600;
    }
    .field-column {
      margin: 1rem;
    }
  }
`;

export default Styles;