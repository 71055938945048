import { Outlet, useOutletContext } from "react-router-dom";

import { AuthRouteContext } from "../../../components/auth/AuthRoute";



function Clans() {
  const { user } = useOutletContext<AuthRouteContext>();

  return (
    <Outlet context={{ user }} />
  );
}

export default Clans;