export const collabRoot = "/collaborators";
export const collabId = ":collaboratorId";



export const collabPaths = {
  LIST: `${collabRoot}/list`,
  INVITE: `${collabRoot}/invite`,
  EDIT: `${collabRoot}/${collabId}`,
  edit: (id: number | string) => `${collabRoot}/${id}`
}