import { affirmationPaths } from "./paths";
import { RouteType } from "../types";
import {
  CreateEditAffirmation,
  PublishedAffirmations,
  DraftAffirmations
} from "../../pages/Affirmations/pages";



export const affirmationRoutes: RouteType[] = [
  {
    path: affirmationPaths.CREATE,
    element: <CreateEditAffirmation />
  },
  {
    path: affirmationPaths.PUBLISHED,
    element: <PublishedAffirmations />
  },
  {
    path: affirmationPaths.DRAFT,
    element: <DraftAffirmations />
  },
  {
    path: affirmationPaths.EDIT,
    element: <CreateEditAffirmation />
  }
]