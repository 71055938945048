import styled from "styled-components";

import { planStyles } from "../List/styles";



const Styles = styled.div`
  .page-wrapper {
    gap: 2rem
  }

  .plan-preview {
    ${planStyles}
    height: fit-content;
  }
`;

export default Styles;