import { useNavigate } from "react-router-dom";
import { FormEvent, MouseEvent } from "react";
import { toast } from "react-toastify";

import { useAppModal } from "../../../components/layout/AppModal/utils";
import { affirmationPaths } from "../../../routes/affirmations/paths";
import save_icon from "../../../assets/vector-images/modal/save.svg";
import { FormValues } from "../../../components/forms/Form/types";
import useAffirmations from "../../../requests/affirmations";
import { Notification } from "../../../components/layout";
import { Affirmation } from "../../../types/core";



type Props = {
  affirmationId?: number | string;
  affirmation?: Affirmation;
  formValues: FormValues;
}

const useAffirmationUtils = ({
  affirmationId,
  affirmation,
  formValues
}: Props) => {
  const navigate = useNavigate();
  const { createAffirmation, updateAffirmation } = useAffirmations();
  const { closeModal, setAppModal } = useAppModal();


  /*********************************/
  /** Redirect to Affirmation List */
  const exitPage = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    closeModal();
    if (affirmation) {
      if (affirmation.isPublished)
        navigate(affirmationPaths.PUBLISHED)
      else
        navigate(affirmationPaths.DRAFT)
    } else {
      navigate(affirmationPaths.DRAFT)
    }
  }


  /****************************************************/
  /** Save new Affirmation or update existing details */
  const saveDetails = (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    const data = formValues;
    // CREATE AFFIRMATION
    if (!affirmationId) {
      createAffirmation(data)
        .then(() => {
          toast(
            <Notification type="success">
              Affirmation successfully added!
            </Notification>
          );
          exitPage();
        }).catch(() => null);
    }
    else {
      // UPDATE AFFIRMATION
      updateAffirmation(affirmationId, data)
        .then(() => {
          toast(
            <Notification type="success">
              Affirmation Updated!
            </Notification>
          );
          exitPage();
        }).catch(() => null);
    }
  }


  /**********************************/
  /** Confirm Changes before saving */
  const confirmChanges = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    setAppModal({
      icon: save_icon,
      title: (!affirmationId ?
        "You're about to create an Affirmation"
        : "Confirm Changes"
      ),
      children: (
        !affirmationId ? (
          `Once this Affirmation is created,${" "
          }Collaborators will be able to edit and publish changes.`
        ) : (
          `Are you sure you want to save these changes?`
        )
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button white-black"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="button"
            onClick={saveDetails}
          >
            Confirm
          </button>
        </div>
      )
    });
  }


  return {
    exitPage,
    saveDetails,
    confirmChanges
  }
}

export default useAffirmationUtils;