import Styles from "./styles";



type Props = {
  json: object | string;
  title: string;
  spaces?: number;
}

function PrettyJson({
  json,
  title,
  spaces = 4
}: Props) {
  let jsonObject: object;
  if (typeof json === "string") {
    try {
      jsonObject = JSON.parse(json);
    } catch (error) {
      return null;
    }
  } else if (typeof json === "object") {
    jsonObject = json;
  } else {
    return null;
  }
  const prettyJson = JSON.stringify(jsonObject, null, spaces);



  return (
    <Styles className="pretty-json">
      <div className="heading flex justify-between align-center">
        <div className="title">
          {title}
        </div>
      </div>
      <div className="code-wrapper">
        <code>
          {prettyJson}
        </code>
      </div>
    </Styles>
  );
}

export default PrettyJson;
