import { useState } from "react";

import hidden from "../../../assets/vector-images/generic/eye-off.svg";
import view from "../../../assets/vector-images/generic/eye.svg";
import { Props as TextFieldProps } from "../TextField";
import Styles, { PasswordStyles } from "./styles";
import PasswordStrength from "./PasswordStrength";



export type Props = TextFieldProps & {
  value: string;
  username?: string | null;
}

function PasswordField(props: Props) {
  const {
    value,
    className,
    username,
    autoComplete = "password",
    ...inputProps
  } = props
  const [fieldType, setFieldType] = useState<string>("password");


  // Show password strength guide when setting a new password
  const newPassword = (
    (autoComplete && autoComplete === "new-password") &&
    (inputProps.name === "password")
  );

  /*********************************/
  /** View or Hide Password Toggle */
  const changeFieldType = () => {
    if (fieldType === "password")
      setFieldType("text")
    else setFieldType("password")
  }



  return (
    <Styles className="flex column">
      <PasswordStyles className="password-field">
        <input
          {...inputProps}
          type={fieldType}
          value={value}
          id={inputProps.name}
          autoComplete={autoComplete}
        />
        <img
          onClick={changeFieldType}
          className="toggle-view"
          alt=""
          src={fieldType === "text" ? view : hidden}
        />
      </PasswordStyles>
      {(newPassword && value.length > 0) ? (
        <PasswordStrength
          value={value}
          username={username}
        />
      ) : null}
    </Styles>
  );
}

export default PasswordField;