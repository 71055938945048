import styled from "styled-components";

import { snippets, colors, properties } from "../../../styles";



const Styles = styled.div`
  transition: ${properties.transition};
  background-color: ${colors.white};
  border: 1px solid ${colors.grey1};
  ${snippets.flexCenter};
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 2rem;
  &.hover {
    border-style: dashed;
    border-color: ${colors.primary};
    background-color: ${colors.accent1};
  }
  &:focus-within {
    border-color: ${colors.primary};
  }
  input {
    ${snippets.absoluteCenter};
    opacity: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;
  }
  .previews,
  .actions {
    img {
      width: 50px;
      height: 50px;
    }
    figcaption {
      font-size: 0.8rem;
      color: ${colors.grey6};
    }
  }
  .actions {
    .link {
      margin-top: 0.5rem;
      font-weight: 600;
    }
  }
`;

export default Styles;