import { FormEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppModal } from "../../../../components/layout/AppModal/utils";
import { Card, Loader, PageHeader } from "../../../../components/layout";
import { FormValues } from "../../../../components/forms/Form/types";
import { billingPaths } from "../../../../routes/billing/paths";
import useAppActions from "../../../../redux/actions/app";
import { decimalPrice } from "../../../../utils/numbers";
import { Plan } from "../../../../requests/plans/types";
import Form from "../../../../components/forms/Form";
import { URLParams } from "../../../../constants";
import usePlans from "../../../../requests/plans";
import { useEditPlan } from "./utils";
import Styles from "./styles";



function EditPlan() {
  const navigate = useNavigate();
  const { planId } = useParams<URLParams>();
  const { getPlan, updatePlan } = usePlans();
  const { setAppModal } = useAppModal();
  const { setAppLoading } = useAppActions();
  const formRef = useRef<HTMLFormElement>(null);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [features, setFeatures] = useState<string[]>([]);
  const [featureTitle, setFeatureTitle] = useState<string>("");
  const [plan, setPlan] = useState<Plan>();
  const {
    formFields, updateFeatures
  } = useEditPlan({ formValues, setFeatures, setFeatureTitle });


  /*****************/
  /** Save Changes */
  const saveDetails = (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    if (!planId) return;
    setAppLoading(true);
    const { maxParents, maxLearners, maxCards } = formValues;
    const data = {
      maxParents,
      maxLearners,
      maxCards,
      planFeatures: features,
      featureTitle
    }
    updatePlan(planId, data)
      .then(() => {
        setAppModal({
          title: "Plan Updated",
          children: (
            "Changes to this Plan have been saved!"
          ),
          cancelCallback: () => {
            navigate(billingPaths.LIST_PLANS)
          }
        });
      })
      .catch(() => null)
      .finally(() => {
        setAppLoading(false)
      });
  }

  useEffect(() => {
    if (!planId) return;
    getPlan(planId)
      .then((plan) => {
        setPlan(plan);
        setFeatureTitle(plan.featureTitle);
        const { maxParents, maxLearners, maxCards } = plan;
        setFormValues((prevState) => ({
          ...prevState,
          maxParents,
          maxLearners,
          maxCards
        }))
      }).catch(() => null);
  }, [planId])

  useEffect(() => {
    if (!plan) return;
    updateFeatures(plan);
  }, [formValues, plan])



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader title="Edit Features" />

      {/* Main Content */}
      <div className="page-wrapper flex row wrap">
        {!plan ? (
          <Loader />
        ) : (
          <>
            <Form
              fields={formFields}
              values={formValues}
              setValues={setFormValues}
              onSubmit={saveDetails}
              ref={formRef}
            >
              <button className="button submit">
                Save Changes
              </button>
            </Form>
            <Card className="plan-preview">
              <div className="head">
                {/* Plan Name and Price */}
                <h2 className="name">
                  {plan.name}
                </h2>
                <div className="price flex">
                  {plan.region === "UK" ? "£" : "$"}
                  {decimalPrice(plan.planPrices[0].amount)}
                  <div className="interval">
                    {`/ ${plan.planPrices[0].interval}`}
                  </div>
                </div>
              </div>
              {/* Plan Description */}
              <div className="content">
                <div className="title">
                  {featureTitle}
                </div>
                <ul className="features flex column">
                  {features.map((feature, index) =>
                    <li className="feature" key={`${feature}-${index}`}>
                      {feature}
                    </li>
                  )}
                </ul>
              </div>
            </Card>
          </>
        )}
      </div>
    </Styles>
  );
}

export default EditPlan;