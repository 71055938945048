import { accountPaths } from "./paths";
import { RouteType } from "../types";
import {
  UserList, UserDetail,
  FamilyList, FamilyDetail,
  ClanList, ClanDetail,
  KycList, KycDetail,
  TribeList, TribeDetail
} from "../../pages/Accounts/pages";



/******************/
/* Account Routes */
export const accountRoutes: RouteType[] = [
  // Users
  {
    path: accountPaths.USERS,
    element: <UserList />,
  },
  {
    path: accountPaths.USER_DETAIL,
    element: <UserDetail />
  },
  // Families
  {
    path: accountPaths.FAMILIES,
    element: <FamilyList />
  },
  {
    path: accountPaths.FAMILY_DETAIL,
    element: <FamilyDetail />
  },
  // Clans
  {
    path: accountPaths.CLANS,
    element: <ClanList />
  },
  {
    path: accountPaths.CLAN_DETAIL,
    element: <ClanDetail />
  },
  // KYC
  {
    path: accountPaths.KYC,
    element: <KycList />
  },
  {
    path: accountPaths.KYC_DETAIL,
    element: <KycDetail />
  },
  // Tribe
  {
    path: accountPaths.TRIBE,
    element: <TribeList />
  },
  {
    path: accountPaths.TRIBE_DETAIL,
    element: <TribeDetail />
  }
]