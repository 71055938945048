import { nonAuthPaths } from "./paths";
import { RouteType } from "../types";
import {
  ForgotPassword,
  InviteExpired,
  InvitePassSuccess,
  InviteSetPass,
  LoadToken,
  ResetPass,
  ResetPassSuccess,
  SignIn
} from "../../pages/_nonAuth";



/********************/
/** Non-Auth Routes */
export const nonAuthRoutes: RouteType[] = [
  {
    path: nonAuthPaths.SIGN_IN,
    element: <SignIn />
  },
  {
    path: nonAuthPaths.LOAD_TOKEN,
    element: <LoadToken />
  },
  {
    path: nonAuthPaths.FORGOT_PASSWORD,
    element: <ForgotPassword />
  },
  {
    path: nonAuthPaths.RESET_PASS,
    element: <ResetPass />
  },
  {
    path: nonAuthPaths.RESET_PASS_SUCCESS,
    element: <ResetPassSuccess />
  },
  {
    path: nonAuthPaths.INVITE_SET_PASS,
    element: <InviteSetPass />
  },
  {
    path: nonAuthPaths.INVITE_PASS_SUCCESS,
    element: <InvitePassSuccess />
  },
  {
    path: nonAuthPaths.INVITE_EXPIRED,
    element: <InviteExpired />
  }
]