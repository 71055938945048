import Styles from "./styles";



function CardLoader() {
  return (
    <Styles className="card-loading">
      <div className="loading-animation" />
    </Styles>
  );
}

export default CardLoader;