import styled from "styled-components";

import TextFieldStyles from "../TextField/styles";
import { colors } from "../../../styles";



const Styles = styled(TextFieldStyles)`
  flex-direction: column;
  align-items: flex-end;
  &>* {
    padding: 16px 20px;
  }
  textarea {
    width: 100%;
    height: 65px;
    resize: none;
  }
  .max-length {
    font-size: 0.8rem;
    color: ${colors.grey6}
  }
`;

export default Styles;