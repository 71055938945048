import { Link, matchPath, useLocation, useOutletContext } from "react-router-dom";

import delete_icon from "../../../assets/vector-images/generic/delete.svg";
import edit_icon from "../../../assets/vector-images/generic/edit.svg";
import view_icon from "../../../assets/vector-images/generic/view.svg";
import lock_icon from "../../../assets/vector-images/generic/lock.svg";
import del_mdl_icon from "../../../assets/vector-images/modal/delete.svg";
import { TableColumn } from "../../../components/layout/Table/types";
import eye_hidden from "../../../assets/vector-images/generic/eye-off.svg";
import eye_visible from "../../../assets/vector-images/generic/eye.svg";
import { Affirmation } from "../../../types/core";
import { affirmationPaths } from "../../../routes/affirmations/paths";
import { useAppModal } from "../../../components/layout/AppModal/utils";
import { AuthRouteContext } from "../../../components/auth/AuthRoute";
import useAffirmations from "../../../requests/affirmations";
import { Notification } from "../../../components/layout";
import { toast } from "react-toastify";
import { MouseEvent } from "react";


const useAffirmationListUtils = () => {
  const { pathname } = useLocation();
  const { setAppModal, closeModal } = useAppModal();
  const { user } = useOutletContext<AuthRouteContext>();
  const {
    deleteAffirmation, updateAffirmation
  } = useAffirmations();


  /*********************************/
  /** Toggle Affirmation Published */
  const togglePublished = (e: MouseEvent, affirmation: Affirmation) => {
    if (matchPath(affirmationPaths.PUBLISHED, pathname)) {
      // Unpublish Affirmation
      setAppModal({
        title: "You're About to Unpublish Affirmation",
        icon: eye_hidden,
        children: (
          `When this affirmation is unpublished, Users will no${" "
          }longer be able to see this in the app.`
        ),
        cancelCallback: () => {
          updateAffirmation(
            affirmation.id,
            { isPublished: false }
          ).then(() => {
            toast(
              <Notification type="success">
                Affirmation has been successfully unpublished!
              </Notification>
            );
            setAppModal(null);
            window.location.reload();
          });
        }
      });
    } else {
      // Publish Affirmation
      setAppModal({
        title: "You're About to Publish Affirmation",
        icon: eye_visible,
        children: (
          `When this affirmation is published, Users will${" "
          }be able to see this in the app.`
        ),
        controls: (
          <div className="modal-controls">
            <button
              className="button white-black"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className="button"
              onClick={() => {
                updateAffirmation(
                  affirmation.id,
                  { isPublished: true }
                ).then(() => {
                  toast(
                    <Notification type="success">
                      Affirmation has been successfully published!
                    </Notification>
                  );
                  closeModal();
                  window.location.reload();
                })
              }}
            >
              Okay
            </button>
          </div>
        )
      });
    }
  }

  /*********************************/
  /** Confirm Affirmation Deletion */
  const confirmDelete = (e: MouseEvent, affirmation: Affirmation) => {
    e.preventDefault();
    setAppModal({
      title: "You're about to delete Affirmation",
      icon: del_mdl_icon,
      children: (
        `When this Affirmation is deleted, Users and Collaborators${" "
        }will not be able to see this.`
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button white-black"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="button error"
            onClick={() => {
              deleteAffirmation(affirmation.id).then(() => {
                toast(
                  <Notification type="success">
                    Affirmation has been successfully deleted.
                  </Notification>
                );
                closeModal();
                window.location.reload();
              });
            }}
          >
            Confirm
          </button>
        </div>
      )
    });
  }


  /******************/
  /** Table Columns */
  const tableColumns: TableColumn[] = [
    {
      title: "Name",
      key: "title",
      content: (affirmation: Affirmation) => (
        <Link
          to={affirmationPaths.edit(affirmation.id)}
          className="table-title"
        >
          <div>{affirmation.title}</div>
          {affirmation.tree ? (
            <div className="flex">
              <div className={`desert generic`}>
                {affirmation.tree.desert.name}
              </div>
              <div>+</div>
              <div className={`region ${affirmation.tree.region}`}>
                {affirmation.tree.region}
              </div>
            </div>
          ) : null}
        </Link>
      )
    },
    {
      title: "Affirmation",
      key: "text",
      className: "al-left"
    },
    {
      title: "Actions",
      key: "",
      className: "al-right",
      content: (affirmation: Affirmation) => (
        <div className="flex justify-end">
          <Link to={affirmationPaths.edit(affirmation.id)}>
            <img src={edit_icon} alt="" />
          </Link>
          <button onClick={(e) => togglePublished(e, affirmation)}>
            <img alt=""
              src={affirmation.isPublished ? lock_icon : view_icon}
            />
          </button>
          {user.userType === "admin" ? (
            <button onClick={(e) => confirmDelete(e, affirmation)}>
              <img src={delete_icon} alt="" />
            </button>
          ) : null}
        </div>
      )
    }
  ]


  return {
    tableColumns
  }
}

export default useAffirmationListUtils;