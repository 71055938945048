import styled from "styled-components";

import MissionStyles from "../../Missions/List/styles";



const Styles = styled(MissionStyles)`
  .table-root {
    .table-wrapper {
      table {
        tr {
          th, td {
            &:first-child {
              width: auto;
            }
          }
        }
      }
    }
  }
`;

export default Styles;