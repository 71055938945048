import { useNavigate } from "react-router-dom";
import { useRef } from "react";

import { ChildFormProps } from "../../../components/forms/FormSteps/types";
import { FormField } from "../../../components/forms/Form/types";
import { VideoPlayer } from "../../../components/layout";
import { RegionOptions } from "../../../constants/core";
import { useAppState } from "../../../redux/state";
import { rootPaths } from "../../../routes/paths";
import { Form } from "../../../components/forms";



function SetupForm({
  values,
  setValues,
  nextStep,
  FormNavigation
}: ChildFormProps) {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const { deserts } = useAppState();


  /*****************/
  /** Setup Fields */
  const fields: FormField[] = [
    {
      type: "text",
      name: "title",
      label: "Mission Title",
      groupClass: "field-group 1"
    },
    {
      type: "textarea",
      name: "description",
      label: "Description of Mission",
      placeholder: "Add description of this mission",
      groupClass: "field-group 1"
    },
    {
      type: "select",
      name: "desertId",
      label: "Desert",
      options: deserts,
      valueKey: "id",
      labelKey: "name",
      groupClass: "field-row 1"
    },
    {
      type: "select",
      name: "region",
      label: "Mission Region",
      options: RegionOptions,
      groupClass: "field-row 1"
    },
    {
      type: "text",
      name: "tokens",
      label: "Mission Tokens",
      groupClass: "field-row 1"
    },
    {
      type: "file",
      name: "thumbnailUrl",
      label: "Mission Thumbnail",
      groupClass: "file-uploads"
    },
    {
      type: "text",
      name: "videoUrl",
      label: "Video URL",
      groupClass: "field-group video"
    },
    {
      type: "react-node",
      name: "",
      label: "",
      groupClass: "field-group video",
      reactNode: (
        <VideoPlayer url={values.videoUrl} />
      )
    }
  ]



  return (
    <Form
      fields={fields}
      values={values}
      setValues={setValues}
      onSubmit={nextStep}
      ref={formRef}
    >
      <FormNavigation
        previous={
          <button
            className="button auto-width white-black"
            onClick={() => navigate(rootPaths.MISSIONS)}
          >
            Cancel
          </button>
        }
      />
    </Form>
  );
}

export default SetupForm;