import styled from "styled-components";

import { colors, properties, resolutions, snippets } from "../../../styles";



export const Styles = styled.div`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.grey1};
  height: ${properties.navHeaderHeight};
  .header-wrapper {
    height: 100%;
    padding: 0 1rem;
    position: relative;
    display: flex;
    align-items: center;
    .sidebar-toggle {
      display: none;
      margin-right: auto;
      @media(max-width: ${resolutions.tabletLarge}) {
        display: block;
        height: 1rem;
        width: 1rem;
      }
    }
    #logo {
      ${snippets.absoluteCenter};
      img {
        height: 14px;
      }
    }
  }
`;

export default Styles;