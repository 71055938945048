import { Outlet, useOutletContext } from "react-router-dom";

import { PageHeader } from "../../components/layout";
import { AuthRouteContext } from "../../components/auth/AuthRoute";
import { profilePaths } from "../../routes/profile/paths";
import Styles from "./styles";



const Profile = () => {
  const { user } = useOutletContext<AuthRouteContext>();


  return (
    <Styles className="root-content">
      <PageHeader />
      <Outlet context={{ user }} />
    </Styles>
  )
}

export default Profile;