import { ReactNode } from "react";

import success_icon from "../../../assets/vector-images/generic/success-icon.svg";
import error_icon from "../../../assets/vector-images/generic/error-icon.svg";
import Styles from "./styles";



type NType =
  | "error"
  | "success"
  | "info";

export type Props = {
  children: ReactNode;
  type?: NType;
}

function Notification({
  type = "info",
  children
}: Props) {
  return (
    <Styles className={type}>
      <img
        src={
          (type === "error")
            ? error_icon
            : success_icon
        }
        className="icon"
        alt=""
      />
      <div className="notify-msg">
        {children}
      </div>
    </Styles>
  );
}

export default Notification;