import { collabPaths } from "./paths";
import { RouteType } from "../types";
import {
  CollabList,
  CreateEditCollab
} from "../../pages/Collaborators/pages";



/******************/
/** Collab Routes */
export const collabRoutes: RouteType[] = [
  {
    path: collabPaths.LIST,
    element: <CollabList />
  },
  {
    path: collabPaths.INVITE,
    element: <CreateEditCollab />
  },
  {
    path: collabPaths.EDIT,
    element: <CreateEditCollab />
  }
]