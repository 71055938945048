import { MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import edit_icon from "../../assets/vector-images/generic/edit.svg";
import { profilePaths } from "../../routes/profile/paths";
import { getVerboseUserType } from "../../constants/auth";
import useAppActions from "../../redux/actions/app";
import { User } from "../../requests/users/types";
import useUsers from "../../requests/users";



const Personal = () => {
  const navigate = useNavigate();
  const { setAppLoading } = useAppActions();
  const { getMe } = useUsers();
  const [user, setUser] = useState<User>();
  const editProfile = (e: MouseEvent) => {
    e.preventDefault();
    navigate(profilePaths.MANAGE);
  }


  useEffect(() => {
    setAppLoading(true);
    getMe().then((resData) => {
      setUser(resData as User);
    }).finally(() => {
      setAppLoading(false);
    });
  }, [])



  return (
    user ? (
      <div id="Personal">
        {/* Heading */}
        <div className="heading flex align-center justify-between">
          <h3>Account Profile</h3>
          <button
            className="edit"
            style={{ backgroundImage: `url(${edit_icon})` }}
            onClick={editProfile}
          />
        </div>

        {/* User Details */}
        <div className="pseudo-table">
          <div className="table-row">
            <div className="title">First Name</div>
            <div className="detail">{user.firstName}</div>
          </div>
          <div className="table-row">
            <div className="title">Last Name</div>
            <div className="detail">{user.lastName}</div>
          </div>
          <div className="table-row">
            <div className="title">Email</div>
            <div className="detail">{user.username}</div>
          </div>
          <div className="table-row">
            <div className="title">Permissions</div>
            <div className={`detail row role ${user.userType}`}>
              {getVerboseUserType(user.userType)}
            </div>
          </div>
        </div>

        {/* Last Active */}
        <div className="flex justify-between align-center">
          <div className="grey-text">Last Login:</div>
          <div className="bold">
            {moment(user.lastLogin).format("dddd Do MMMM, YYYY - hh:mma")}
          </div>
        </div>
      </div>
    ) : null
  )
}

export default Personal;