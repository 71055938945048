import { RouteType } from "../types";
import { assetPaths } from "./paths";
import {
  Avatars,
  CreateEditCrest, CrestList,
  TreeDetail, TreeList
} from "../../pages/Assets/pages";



/*****************/
/** Asset Routes */
export const assetRoutes: RouteType[] = [
  // Trees
  {
    path: assetPaths.TREES,
    element: <TreeList />
  },
  {
    path: assetPaths.TREE_DETAIL,
    element: <TreeDetail />
  },
  // Avatars
  {
    path: assetPaths.AVATARS,
    element: <Avatars />
  },
  // Crests
  {
    path: assetPaths.CRESTS,
    element: <CrestList />
  },
  {
    path: assetPaths.ADD_CREST,
    element: <CreateEditCrest />
  },
  {
    path: assetPaths.EDIT_CREST,
    element: <CreateEditCrest />
  }
]