import { Link } from "react-router-dom";
import moment from "moment";

import { getVerboseInterval } from "../../../../requests/subscriptions/utils";
import { decimalPrice, getDiscountedPrice } from "../../../../utils/numbers";
import { TableColumn } from "../../../../components/layout/Table/types";
import { billingPaths } from "../../../../routes/billing/paths";
import { currencySymbol } from "../../../../utils/strings";
import {
  PlanPriceDiscount
} from "../../../../requests/planPriceDiscounts/types";



const useDiscountListUtils = () => {
  const tableColumns: TableColumn[] = [
    {
      title: "Discount Code",
      key: "id",
      orderByKey: "code",
      content: (discount: PlanPriceDiscount) => (
        <Link
          to={billingPaths.editDiscount(discount.id)}
          className="table-title"
        >
          {discount.code}
        </Link>
      )
    },
    {
      title: "Plan",
      key: "planPriceId",
      orderByKey: "planPrice.plan.name",
      content: (discount: PlanPriceDiscount) => (
        `${discount.planPrice.plan.name
        } (${discount.planPrice.plan.region
        } ${getVerboseInterval(discount.planPrice.interval)})`
      )
    },
    {
      title: "Percentage",
      key: "percentage",
      orderByKey: "percentage",
      content: (discount: PlanPriceDiscount) => (
        `${discount.percentage}% off for ${discount.daysDuration} days`
      )
    },
    {
      title: "Discounted Price",
      key: "planPrice",
      orderByKey: "planPrice.amount",
      content: (discount: PlanPriceDiscount) => (
        <div className="flex center">
          <div className="original">
            {`${currencySymbol(discount.planPrice.currency)
              }${decimalPrice(discount.planPrice.amount)}`}
          </div>
          <div className="discounted bold">
            {`${currencySymbol(discount.planPrice.currency)
              }${getDiscountedPrice(
                discount.planPrice.amount, discount.percentage
              )}`}
          </div>
        </div>
      )
    },
    {
      title: "Created At",
      key: "createdAt",
      orderByKey: "createdAt",
      content: (discount: PlanPriceDiscount) => (
        moment(discount.createdAt).format("Do MMM, YYYY")
      )
    }
  ]


  return {
    tableColumns
  }
}

export default useDiscountListUtils;