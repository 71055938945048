import { useEffect, useState } from "react";

import users_icon from "../../../../assets/vector-images/panel/users.svg";
import { PageHeader, Table } from "../../../../components/layout";
import { useQueryParams } from "../../../../utils/components";
import { Tribe } from "../../../../requests/tribe/types";
import { PaginatedResponse } from "../../../../types";
import useTribe from "../../../../requests/tribe";
import useTribeListUtils from "./utils";



function TribeList() {
  const { locationSearch, queryParams } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || "";
  const orderBy = queryParams.get("orderBy") || "";
  const search = queryParams.get("search") || "";
  const page = queryParams.get("page") || "";
  const { listTribeAccounts } = useTribe();
  const { tableColumns } = useTribeListUtils()
  const [loading, setLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<
    PaginatedResponse<Tribe>
  >();


  /*****************/
  /** Get all data */
  const retrieveData = () => {
    setLoading(true);
    const params = {
      search,
      page,
      pageSize,
      orderBy
    }
    listTribeAccounts(params)
      .then((data) => {
        setTableData(data);
      })
      .catch(() => null)
      .finally(() => {
        setLoading(false);
      });
  }


  useEffect(() => {
    retrieveData();
  }, [locationSearch])



  return (
    <div className="root-content">
      {/* Header */}
      <PageHeader showSearch={true} />

      {/* Main Content */}
      <div className="page-wrapper">
        <Table
          {...tableData}
          loading={loading}
          columns={tableColumns}
          noData={{
            icon: users_icon,
            title: "No Tribe Records"
          }}
        />
      </div>
    </div>
  );
}

export default TribeList;