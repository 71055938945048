import styled from "styled-components";

import { colors, functions, snippets } from "../../../styles";



const Styles = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${functions.rgba(colors.black, 0.9)};
  z-index: 99999999;
  ${snippets.absoluteCenter}
`;

export default Styles;