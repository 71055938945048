import axios from "axios";

import useAppActions from "../../redux/actions/app"
import { User } from "../users/types";



/*  ############################## */
/** ## COLLABORATOR API REQUESTS # */
/*  ############################## */
const useCollaborators = () => {
  const { setAppLoading } = useAppActions();

  /************************/
  /** Create Collaborator */
  const createCollaborator = (data: any) => {
    return new Promise<User>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/collaborators`,
        method: "post",
        data
      }).then((res) => {
        resolve(res.data as User);
      }).catch((error) => {
        reject(error);
      }).finally(() => {
        setAppLoading(false);
      });
    });
  }

  /******************************/
  /** Update collaborator by ID */
  const updateCollaborator = (id: string, data: any) => {
    return new Promise<User>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/collaborators/${id}/`,
        method: "patch",
        data
      }).then((res) => {
        const user = res.data as User;
        resolve(user);
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        setAppLoading(false);
      });
    });
  }


  return {
    createCollaborator,
    updateCollaborator
  }
}

export default useCollaborators;