import axios from "axios";

import { User } from "../users/types";
import { Family } from "./types";
import { PaginatedResponse } from "../../types";



const useFamilies = () => {
  const listFamilies = () => {
    return new Promise<PaginatedResponse<Family<undefined>>>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/families`,
      }).then((res) => {
        const response = res.data as PaginatedResponse<Family<undefined>>;
        resolve(response);
      }).catch((error) => {
        reject(error)
      });
    });
  }

  const getFamily = (familyId: string) => {
    return new Promise<Family<undefined>>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/families/${familyId}`,
      }).then((res) => {
        resolve(res.data as Family<undefined>);
      }).catch((error) => {
        reject(error)
      });
    });
  }

  const listFamilyUsers = (familyId: string) => {
    return new Promise<User[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/families/${familyId}/users/`,
      }).then((res) => {
        resolve(res.data.results as User[]);
      }).catch((error) => {
        reject(error);
      });
    });
  }


  return {
    listFamilies,
    getFamily,
    listFamilyUsers
  }
}

export default useFamilies;