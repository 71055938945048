import styled from "styled-components";
import { colors } from "../../../styles";



const Styles = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey1};
  padding: 24px 20px;
  border-radius: 10px;

  .title {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: ${colors.grey2};
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  .detail-list {
    .list-item {
      padding: 0.8rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:last-child) {
        border-bottom: 1px solid ${colors.grey1};
      }
      &:last-child {
        padding-bottom: 0;
      }
      .name,
      .description {
        font-size: 0.8rem;
      }
      .description {
        font-weight: 700;
      }
    }
  }
`;

export default Styles;