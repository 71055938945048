import axios from "axios";

import { Tree } from "../types/core";



const useTrees = () => {
  /***************/
  /** List Trees */
  const listTrees = () => {
    return new Promise<Tree[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/trees`,
      }).then((res) => {
        resolve(res.data.results)
      }).catch((error) => {
        reject(error);
      })
    });
  }

  /***************/
  /** Get Tree */
  const getTree = (id: number | string) => {
    return new Promise<Tree>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/trees/${id}`,
      }).then((res) => {
        resolve(res.data as Tree);
      }).catch((error) => {
        reject(error);
      })
    });
  }

  /****************/
  /** Update Tree */
  const updateTree = (id: number | string, data: any) => {
    return new Promise<Tree>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/trees/${id}`,
        method: "patch",
        data
      }).then((res) => {
        resolve(res.data)
      }).catch((error) => {
        reject(error);
      })
    });
  }

  /******************************/
  /** Update Tree's coordinates */
  const updateCoords = (id: number, data: any) => {
    return new Promise<Tree>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/trees/${id}/update-coords`,
        method: "patch",
        data
      }).then((res) => {
        resolve(res.data)
      }).catch((error) => {
        reject(error);
      })
    });
  }


  return {
    listTrees,
    getTree,
    updateTree,
    updateCoords
  }
}

export default useTrees;