import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";



export const useHiddenOutside = (
  ref: React.RefObject<HTMLElement>
) => {
  const [visible, setVisible] = useState<boolean>(false);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisible(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return {
    visible,
    setVisible
  }
}


/********************************/
/** Commonly used page controls */
export const usePageControls = () => {
  const navigate = useNavigate();

  const previousPage = () => {
    navigate(-1);
  }

  const nextPage = () => {
    navigate(1);
  };

  const reloadPage = () => {
    window.location.reload();
  }

  const goTo = (path: string, replace = false) => {
    if (replace) {
      navigate(path, { replace: true });
    } else {
      navigate(path);
    }
  };

  return {
    previousPage,
    nextPage,
    reloadPage,
    goTo
  }
}


export const useQueryParams = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const root = document.querySelector(".root-page");
  const otherRoot = document.querySelector(".root-content");

  const sanitizeQueryParams = (params: URLSearchParams) => {
    const sanitizedParams = new URLSearchParams();
    params.forEach((value, key) => {
      sanitizedParams.set(key, value.replace("/", ""));
    });
    return sanitizedParams;
  }

  const updateFilters = (key: string, value: string | number) => {
    // Start from the first page if filters are changed
    queryParams.delete("page");
    queryParams.set(key, encodeURIComponent(value.toString().trim()));
    navigate(
      `${pathname}?${queryParams.toString()}`,
      { replace: true }
    );
    root?.scrollTo({ top: 0 });
    otherRoot?.scrollTo({ top: 0 });
  }
  const sanitizedQueryParams = sanitizeQueryParams(queryParams);


  return {
    locationSearch: search,
    queryParams: sanitizedQueryParams,
    updateFilters
  }
}