import { useNavigate, Link } from "react-router-dom";
import { useRef, useState } from "react";

import { FormField, FormValues } from "../../../components/forms/Form/types";
import logo from "../../../assets/vector-images/sona/logo.svg";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import useAppActions from "../../../redux/actions/app";
import { rootPaths } from "../../../routes/paths";
import { Form } from "../../../components/forms";
import useAuth from "../../../requests/auth";



function ForgotPassword() {
  const navigate = useNavigate();
  const { setAppLoading } = useAppActions();
  const { requestPasswordReset } = useAuth();
  const formRef = useRef<HTMLFormElement>(null);
  const [formValues, setFormValues] = useState<FormValues>({});


  /****************/
  /** Submit Data */
  const onSubmit = () => {
    setAppLoading(true);
    const username = formValues.username;
    requestPasswordReset({ username })
      .then(() => {
        const encodedUsername = encodeURIComponent(username);
        navigate(`${nonAuthPaths.RESET_PASS
          }?username=${encodedUsername}`);
      })
      .catch(() => null)
      .finally(() => {
        setAppLoading(false);
      });
  }

  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "email",
      name: "username",
      label: "Please enter your email",
      placeholder: "you@example.com",
      autoComplete: "email",
      groupClass: "field-group"
    }
  ]



  return (
    <div className="root-content">
      {/* Header */}
      <header className="root-header">
        <Link to={rootPaths.HOMEPAGE} className="home-link-icon">
          <img src={logo} alt="" />
        </Link>
        <div className="description">
          Type in your email to reset your password
        </div>
      </header>

      {/* Main Content */}
      <div className="page-wrapper">
        <Form
          fields={formFields}
          values={formValues}
          setValues={setFormValues}
          onSubmit={onSubmit}
          ref={formRef}
        >
          <button
            className="button submit"
            type="submit"
          >
            Send Verification Code
          </button>
        </Form>
      </div>
    </div>
  );
}

export default ForgotPassword;