import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { TableColumn } from "../../../../components/layout/Table/types";
import { PageHeader, Table } from "../../../../components/layout";
import { accountPaths } from "../../../../routes/accounts/paths";
import { Clan } from "../../../../requests/clans/types";
import { PaginatedResponse } from "../../../../types";
import useClans from "../../../../requests/clans";



function ClanList() {
  const { listClans } = useClans();
  const [tableData, setTableData] = useState<PaginatedResponse<Clan>>();


  const columns: TableColumn[] = [
    {
      title: "Name",
      key: "name",
      className: "al-left",
      content: (clan: Clan) => (
        <Link
          to={accountPaths.clanDetail(clan.id)}
          className="table-title"
        >
          {clan.crest ? (
            <img
              alt=""
              src={clan.crest.url}
              className="crest"
            />
          ) : null}
          <div className="flex column">
            <div className="name">
              {clan.name}
            </div>
          </div>
        </Link>
      )
    }
  ]


  useEffect(() => {
    listClans()
      .then((resData) => {
        setTableData(resData);
      }).catch(() => null);
  }, [])



  return (
    <div className="root-content">
      {/* Header */}
      <PageHeader showSearch={true} />

      {/* Main Content */}
      <div className="page-wrapper">
        <Table
          {...tableData}
          columns={columns}
        />
      </div>
    </div>
  );
}

export default ClanList;