import moment from "moment";



export function convertUnixToDate(unixTimestamp: number): string {
  // Use moment to format the timestamp into a readable ISO 8601 date
  const isoDate = moment(unixTimestamp).toISOString()
  const formattedDate = moment(isoDate).format("Do, MMMM, YYYY @ hh:mma");
  return formattedDate;
}

export const defaultDateFormat = "Do MMM, YYYY";

export const dateAndTime = "Do MMM, YYYY @ HH:mm";