import styled from "styled-components";
import { colors, properties, snippets } from "../../../styles";



const Styles = styled.div`
  .form-steps {
    .radio-button-field {
      .title {
        font-size: 1.15rem;
      }
    }

    .email-type {
      .field-column {
        margin: 0;
      }
    }

    /*****************/
    /* Template List */
    .template-list {
      .fieldsets {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 2rem;
        fieldset {
          padding-bottom: 0;
          position: relative;
          max-width: ${properties.maxFieldWidth};
          width: auto;
          .field-column {
            .wrapper {
              gap: 12px;
              .image-preview {
                max-width: 100%;
              }
            }
          }
          .template-link {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            font-size: 0.8rem;
            ${snippets.flexCenter}
            a {
              font-size: 0.8rem;
            }
          }
        }
      }
    }

    /*****************/
    /* Compose Email */
    .compose-email {
      .template-summary {
        ${snippets.scrollBar}
        ::-webkit-scrollbar {
          width: 0;
        }
        .title {
          font-weight: bold;
          font-size: 2rem;
          color: ${colors.grey2};
        }
        .link {
          font-size: 0.8rem;
          margin-bottom: 0.5rem;
        }
        .template-preview {
          width: 100%;
          max-width: ${properties.maxFormWidth};
          max-height: 25vh;
          overflow: auto;
          .title {
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
          }
          img {
            width: 100%;
            object-fit: cover;
            object-position: top;
          }
        }
      }
     
      

      fieldset.variables {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 2rem;
        .field-column {
          margin: 0;
        }
      }
    }
  }
`;

export default Styles;