import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import edit_icon from "../../../../assets/vector-images/generic/edit.svg";
import { assetPaths } from "../../../../routes/assets/paths";
import useAppActions from "../../../../redux/actions/app";
import { Card, PageHeader } from "../../../../components/layout";
import useTrees from "../../../../requests/trees";
import { Tree } from "../../../../types/core";
import Styles from "./styles";



const Trees = () => {
  const { listTrees } = useTrees();
  const { setAppLoading } = useAppActions();
  const [desertGroups, setDesertGroups] = useState<Record<string, Tree[]>>({});


  /*****************************/
  /** Group trees by desert ID */
  const sortTreesByDesert = (trees: Tree[]) => {
    const desertIds = Array.from(
      new Set(trees.map((tree) => tree.desertId.toString()))
    );
    const groups: Record<string, Tree[]> = {};
    desertIds.forEach((desertId) => {
      groups[desertId] = []
    });
    trees.forEach((tree) => {
      const { desertId } = tree;
      groups[desertId].push(tree);
    });
    setDesertGroups(groups);
  }

  /***************/
  /** List Trees */
  const getTreeList = async () => {
    setAppLoading(true);
    await listTrees()
      .then((resData) => {
        sortTreesByDesert(resData);
        setAppLoading(false);
      })
      .catch(() => null);
  }


  useEffect(() => {
    getTreeList();
  }, [])



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader />

      {/* Main Content */}
      <div className="page-wrapper">
        <div className="flex column">
          {Object.entries(desertGroups).map(([desertId, trees]) =>
            <div className="desert-group" key={desertId}>
              {/* Desert name */}
              <div className="flex align-center">
                <div className="title jumbo">
                  {trees[0].desert.name}
                </div>
              </div>
              <div className="grid">
                {/* Tree Regions */}
                {trees.map(tree =>
                  <Card key={tree.id}>
                    <div className={`region ${tree.region}`}>
                      {tree.region}
                    </div>
                    <div className="coords">
                      <div className="flex align-center justify-between">
                        <div className="card-title">
                          Map Coordinates
                        </div>
                        <Link to={assetPaths.treeDetail(tree.id)}
                        >
                          <img src={edit_icon} alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="flex column mt-1 lat-lng">
                      <div className="flex align-center justify-between">
                        <div className="key">Latitude:</div>
                        <div className="value">{tree.lat}</div>
                      </div>
                      <div className="flex align-center justify-between">
                        <div className="key">Longitude:</div>
                        <div className="value">{tree.lng}</div>
                      </div>
                    </div>
                  </Card>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Styles>
  );
}


export default Trees;