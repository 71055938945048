import { useNavigate, useParams } from "react-router-dom";
import {
  useState, useCallback, useEffect,
  FormEvent, MouseEvent, useRef
} from "react";

import { useAppModal } from "../../../../components/layout/AppModal/utils";
import useCrests, { CrestTypes } from "../../../../requests/crests";
import { assetPaths } from "../../../../routes/assets/paths";
import { PageHeader } from "../../../../components/layout";
import Form from "../../../../components/forms/Form";
import { URLParams } from "../../../../constants";
import { FormValues, FormField } from
  "../../../../components/forms/Form/types";



function CrestDetail() {
  const navigate = useNavigate();

  const { crestId } = useParams<URLParams>();
  const { setAppModal } = useAppModal();
  const formRef = useRef<HTMLFormElement>(null);
  const { getCrest, updateCrest, createCrest } = useCrests();
  const [formValues, setFormValues] = useState<FormValues>({});


  /*****************/
  /** Save changes */
  const saveDetails = (e: FormEvent | MouseEvent) => {
    e.preventDefault();
    const data = new FormData();
    Object.keys(formValues).forEach((key) => {
      data.append(key, formValues[key]);
    });
    if (crestId) {
      updateCrest(crestId, data)
        .then(() => {
          exitPage();
        }).catch(() => null);
    } else {
      createCrest(data)
        .then(() => {
          exitPage();
        }).catch(() => null);
    }
  }

  /***********************/
  /** Get existing Crest */
  const retrieveCrest = useCallback((id: string | number) => {
    getCrest(id)
      .then((crest) => {
        const { url, name, name2, type, description } = crest;
        setFormValues((prevState) => ({
          ...prevState,
          url,
          name,
          name2,
          type,
          description
        }));
      }).catch(() => null)
  }, [])

  /***************************************/
  /** Confirmation Modal & Page Redirect */
  const exitPage = () => {
    setAppModal({
      title: "Crest Saved",
      children: (
        `${crestId
          ? "Changes Saved!"
          : "Crest successfully added!"
        }`
      ),
      cancelCallback: () => {
        navigate(assetPaths.CRESTS);
      }
    });
  }

  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "text",
      name: "name",
      label: "Name",
      groupClass: "field-row 1"
    },
    {
      type: "text",
      name: "name2",
      label: "Local Name",
      required: false,
      groupClass: "field-row 1"
    },
    {
      type: "select",
      name: "type",
      label: "Crest Type",
      options: CrestTypes,
      groupClass: "field-group 1"
    },
    {
      type: "text",
      name: "description",
      label: "Description",
      groupClass: "field-group 1"
    },
    {
      type: "file",
      name: "url",
      label: "Crest Image",
      placeholder: "Select Crest",
      groupClass: "field-group 1"
    },
  ]

  useEffect(() => {
    if (!crestId) return;
    retrieveCrest(crestId);
  }, [crestId, retrieveCrest])



  return (
    <div className="root-content">
      {/* Header */}
      <PageHeader
        title={crestId
          ? "Edit"
          : "Create"
        }
      />

      {/* Main Content */}
      <div className="page-wrapper">
        <Form
          fields={formFields}
          values={formValues}
          setValues={setFormValues}
          onSubmit={saveDetails}
          ref={formRef}
        >
          <button
            className="button submit auto-width"
            type="submit"
          >
            {crestId
              ? "Save Changes"
              : "Create Crest"
            }
          </button>
        </Form>
      </div>
    </div>
  );
}

export default CrestDetail;