import axios from "axios";

import { ErrorResponse, PaginatedResponse } from "../../types";
import { TribeTxn } from "./types";



/*  ########################### */
/** ## TRIBE TXN API REQUESTS # */
/*  ########################### */
const useTribeTxns = () => {
  /******************************/
  /** List  User's Transactions */
  const listUsersTxns = (params: Record<string, string>) => {
    return new Promise<PaginatedResponse<TribeTxn>>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_PAYMENTS_API_URL
          }/v2/tribe-txns/users`,
        params
      }).then((res) => {
        const resData = res.data as PaginatedResponse<TribeTxn>;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    listUsersTxns
  }
}

export default useTribeTxns;