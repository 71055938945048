import { affirmationPaths } from "../../../routes/affirmations/paths";
import { collabPaths } from "../../../routes/collaborators/paths";
import { dashboardPaths } from "../../../routes/dashboard/paths";
import { accountPaths } from "../../../routes/accounts/paths";
import { missionPaths } from "../../../routes/missions/paths";
import { billingPaths } from "../../../routes/billing/paths";
import { profilePaths } from "../../../routes/profile/paths";
import { offersPaths } from "../../../routes/offers/paths";
import { assetPaths } from "../../../routes/assets/paths";
import { emailPaths } from "../../../routes/emails/paths";
import { rootPaths } from "../../../routes/paths";
import {
  ReactComponent as DashboardIcon
} from "../../../assets/vector-images/side-menu/00.svg";
import {
  ReactComponent as AccountsIcon
} from "../../../assets/vector-images/side-menu/01.svg";
import {
  ReactComponent as MissionsIcon
} from "../../../assets/vector-images/side-menu/02.svg";
import {
  ReactComponent as ProfileIcon
} from "../../../assets/vector-images/side-menu/07.svg";
import {
  ReactComponent as WalletsIcon
} from "../../../assets/vector-images/side-menu/04.svg";
import {
  ReactComponent as AffirmIcon
} from "../../../assets/vector-images/side-menu/03.svg";
import {
  ReactComponent as OffersIcon
} from "../../../assets/vector-images/side-menu/05.svg";
import {
  ReactComponent as CollabIcon
} from "../../../assets/vector-images/side-menu/06.svg";
import { User } from "../../../requests/users/types";



type NvLnk = {
  name: string;
  path: string;
  icon?: React.ReactElement;
  children?: NvLnk[];
}

export const getNavLinks = (user: User) => ([
  // Dashboard
  {
    name: "Dashboard",
    path: rootPaths.DASHBOARD,
    icon: <DashboardIcon />,
    children: [
      {
        name: "Events",
        path: dashboardPaths.EVENTS
      }
    ]
  },
  // Accounts
  {
    name: "Accounts",
    path: rootPaths.ACCOUNTS,
    icon: <AccountsIcon />,
    children: [
      {
        name: "Users",
        path: accountPaths.USERS
      },
      {
        name: "Families",
        path: accountPaths.FAMILIES
      },
      {
        name: "Clans",
        path: accountPaths.CLANS
      },
      ...(user.userType === "admin" ? ([
        {
          name: "KYC",
          path: accountPaths.KYC
        },
        {
          name: "Tribe",
          path: accountPaths.TRIBE
        }
      ]) : [])
    ]
  },
  // Assets
  {
    name: "Assets",
    path: rootPaths.ASSETS,
    icon: <AccountsIcon />,
    children: [
      {
        name: "Trees",
        path: assetPaths.TREES
      },
      {
        name: "Avatars",
        path: assetPaths.AVATARS
      },
      {
        name: "Crests",
        path: assetPaths.CRESTS
      }
    ]
  },
  // Missions
  {
    name: "Missions",
    path: rootPaths.MISSIONS,
    icon: <MissionsIcon />,
    children: [
      {
        name: "Published",
        path: missionPaths.PUBLISHED
      },
      {
        name: "Drafts",
        path: missionPaths.DRAFT
      }
    ]
  },
  // Affirmations
  {
    name: "Affirmations",
    path: rootPaths.AFFIRMATIONS,
    icon: <AffirmIcon />,
    children: [
      {
        name: "Published",
        path: affirmationPaths.PUBLISHED
      },
      {
        name: "Drafts",
        path: affirmationPaths.DRAFT
      }
    ]
  },
  // Billing
  {
    name: "Billing",
    path: rootPaths.BILLING,
    icon: <WalletsIcon />,
    children: [
      {
        name: "Plans",
        path: billingPaths.LIST_PLANS
      },
      {
        name: "Discounts",
        path: billingPaths.LIST_DISCOUNTS
      },
      {
        name: "Subscriptions",
        path: billingPaths.LIST_SUBSCRIPTIONS
      }
    ]
  },
  // Emails
  {
    name: "Emails",
    path: rootPaths.EMAILS,
    icon: <WalletsIcon />,
    children: [
      {
        name: "List",
        path: emailPaths.LIST
      },
      {
        name: "Send Email",
        path: emailPaths.CREATE
      }
    ]
  },
  // Offers
  {
    name: "Offers",
    path: rootPaths.OFFERS,
    icon: <OffersIcon />,
    children: [
      {
        name: "Metrics",
        path: offersPaths.METRICS
      },
      {
        name: "Products",
        path: offersPaths.PRODUCTS
      }
    ]
  },
  // Collaborators
  {
    name: "Collaborators",
    path: rootPaths.COLLABORATORS,
    icon: <CollabIcon />,
    children: [
      {
        name: "List",
        path: collabPaths.LIST
      }
    ]
  },
  // Profile
  {
    name: "Profile",
    path: rootPaths.PROFILE,
    icon: <ProfileIcon />,
    children: [
      {
        name: "View Profile",
        path: profilePaths.PERSONAL
      },
      {
        name: "Edit Profile",
        path: profilePaths.MANAGE
      }
    ]
  }
]);