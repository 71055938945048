import { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { PageHeader, PrettyJson } from "../../../../components/layout";
import { accountPaths } from "../../../../routes/accounts/paths";
import { KycWithUser } from "../../../../requests/kyc/types";
import useAppActions from "../../../../redux/actions/app";
import Form from "../../../../components/forms/Form";
import { URLParams } from "../../../../constants";
import useKyc from "../../../../requests/kyc";
import useKycDetailUtils from "./utils";
import Styles from "./styles";



function KYCDetail() {
  const navigate = useNavigate();
  const { kycId } = useParams<URLParams>();
  const { setAppLoading } = useAppActions();
  const { getKyc, updateKyc } = useKyc();
  const formRef = useRef<HTMLFormElement>(null);
  const [kyc, setKyc] = useState<KycWithUser>();
  const {
    fields, kycData, addressData, formValues,
    buildData, setFormValues
  } = useKycDetailUtils();


  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!kyc) return;
    setAppLoading(true);
    updateKyc(kyc.id, formValues)
      .then(() => {
        navigate(accountPaths.KYC);
      })
      .catch(() => null)
      .finally(() => {
        setAppLoading(false);
      });
  }


  useEffect(() => {
    if (!kycId) return;
    getKyc(kycId)
      .then((kyc) => {
        setKyc(kyc);
        buildData(kyc);
      })
      .catch(() => null);
  }, [kycId])



  return (
    <Styles className="root-content">
      <PageHeader
        className="username-link"
        title={kyc
          ? kyc.id
          : "-"
        }
      />
      <div className="page-wrapper">
        {/* KYC Data */}
        <div className="flex row wrap">
          <div className="table-data form-width flex-fill">
            <h2 className="title jumbo">
              KYC
            </h2>
            {kycData.map((data) =>
              <div
                key={data.name}
                className="table-row flex justify-between align-center"
              >
                <div className="name">
                  {data.name}
                </div>
                <div className="description">
                  {data.description}
                </div>
              </div>
            )}
          </div>
          <div className="table-data form-width flex-fill">
            <h2 className="title jumbo">
              Address
            </h2>
            {addressData.map((data) =>
              <div
                key={data.name}
                className="table-row flex justify-between align-center"
              >
                <div className="name">
                  {data.name}
                </div>
                <div className="description">
                  {data.description}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* KYC Response */}
        <div className="flex row wrap">
          <div className="wrapper flex-fill">
            <PrettyJson
              json={kyc?.autoKycResponse ?? ""}
              title="Auto KYC Response"
            />
          </div>
          <div className="flex column flex-fill gap-1">
            <PrettyJson
              json={kyc?.docUploadResponse ?? ""}
              title="Document Upload Response"
            />
            <div className="table-data form-width">
              <h2 className="title jumbo">Uploaded Document IDs</h2>
              <ul className="ids">
                {kyc?.documentIds.map((id, index) =>
                  <li key={`${id}-${index}`}>
                    {id}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>

        {/* KYC Update Form */}
        <div className="card">
          <h2 className="title jumbo">
            Manual KYC Override
          </h2>
          <Form
            fields={fields}
            values={formValues}
            setValues={setFormValues}
            onSubmit={onSubmit}
            ref={formRef}
          >
            <button
              className="button submit auto-width"
              type="submit"
            >
              Save Changes
            </button>
          </Form>
        </div>
      </div>
    </Styles>
  );
}

export default KYCDetail;