import { dashboardPaths } from "./paths";
import { RouteType } from "../types";

import {
  Events,
  FunnelDetail,
  FunnelList
} from "../../pages/Dashboard/pages";



export const dashboardRoutes: RouteType[] = [
  {
    path: dashboardPaths.EVENTS,
    element: <Events />
  },
  {
    path: dashboardPaths.LIST_EVENT_FUNNELS,
    element: <FunnelList />
  },
  {
    path: dashboardPaths.EVENT_FUNNEL_DETAIL,
    element: <FunnelDetail />
  }
]