import { Link } from "react-router-dom";
import { getName } from "country-list";
import { useState } from "react";

import { ToggleOne } from "../../../components/forms/ToggleField/styles";
import { TableColumn } from "../../../components/layout/Table/types";
import { currencySymbol } from "../../../utils/strings";
import { decimalPrice } from "../../../utils/numbers";
import useProducts from "../../../requests/products";
import file_icon from "../../../assets/vector-images/generic/file.svg";
import { PaginatedResponse } from "../../../types";
import { Product } from "../../../types/offers";
import { offersPaths } from "../../../routes/offers/paths";



type ProductListUtilsArgs = {
  search: string;
  country: string;
  page: string;
  pageSize: string;
}
const useProductListUtils = (args: ProductListUtilsArgs) => {
  const [tableData, setTableData] = useState<PaginatedResponse<Product>>();
  const { listProducts, updateProduct } = useProducts();


  const updateProductList = () => {
    const { country, search, page, pageSize } = args;
    const cty = country === "all"
      ? "" : country;
    const params = {
      search,
      country: cty,
      page,
      pageSize
    };
    listProducts(params)
      .then((data) => {
        setTableData(data);
      });
  }

  const toggleActive = async (id: string | number, isActive: boolean) => {
    await updateProduct(id, { isActive: !isActive });
    updateProductList();
  }

  const tableColumns: TableColumn[] = [
    {
      title: "Product",
      key: "name",
      content: (product: Product) => (
        <Link
          to={offersPaths.productDetail(product.id)}
          className="table-title"
        >
          <img src={product.smallIcon} alt="" />
          <div>{product.name}</div>
        </Link>
      )
    },
    {
      title: "Prices",
      key: "prices",
      className: "al-left",
      content: (product: Product) => (
        <div className="flex align-center price-range">
          {product.variants.filter(
            v => (v.isActive && v.fullPrice) || v.minPrice
          ).slice(0, 3).map(variant => (
            variant.minPrice ? (
              <div key={variant.id} className="product-price">
                {`${currencySymbol(product.currency)}${""
                  }${decimalPrice(variant.minPrice)} - ${""
                  }${currencySymbol(product.currency)}${""
                  }${decimalPrice(variant.maxPrice)}`}
              </div>
            ) : (variant.isActive ? (
              <div key={variant.id} className="product-price">
                {`${currencySymbol(product.currency)}${""
                  }${decimalPrice(variant.fullPrice)}`}
              </div>
            ) : null)
          ))}
        </div>
      )
    },
    {
      title: "Category",
      key: "category",
      className: "al-left",
      content: (product: Product) => (
        <div className="grey-text">
          {product.category}
        </div>
      )
    },
    {
      title: "Type",
      key: "type",
      className: "al-left",
      content: (product: Product) => (
        <div className="grey-text">
          {product.type}
        </div>
      )
    },
    {
      title: "Country",
      key: "country",
      className: "al-left",
      content: (product: Product) => (
        <div className="grey-text">
          {getName(product.country)}
        </div>
      )
    },
    {
      title: "Tags",
      key: "tags",
      content: (product: Product) => (
        <div className="flex wrap center">
          {product.tags.slice(0, 2).map((tag, index) => (
            <div
              key={`${tag}-${index}`}
              className="status pending reward"
            >
              {tag}
            </div>
          ))}
        </div>
      )
    },
    {
      title: "Rewards",
      key: "rewards",
      content: (product: Product) => (
        <div className="flex center">
          <div className="status success reward">
            {parseFloat(product.walletReward ?? "0").toFixed(2)} %
          </div>
          <div className="status pending reward">
            {parseFloat(product.cardReward ?? "0").toFixed(2)} %
          </div>
        </div>
      )
    },

    {
      title: "Actions",
      key: "",
      className: "al-right",
      content: (product: Product) => (
        <div
          className="flex align-end justify-end"
          style={{ gap: "1.5rem" }}
        >
          <Link to={offersPaths.productDetail(product.id)}>
            <img src={file_icon} alt="" />
          </Link>
          <ToggleOne>
            <input
              type="checkbox"
              name={`product-${product.id}`}
              id={`product-${product.id}`}
              onChange={() => toggleActive(product.id, product.isActive)}
              checked={product.isActive}
            />
            <label htmlFor={`product-${product.id}`} />
          </ToggleOne>
        </div>
      )
    }
  ]


  return {
    tableData,
    tableColumns,
    updateProductList
  }
}

export default useProductListUtils;