import getSymbolFromCurrency from "currency-symbol-map";



export const compareStrings = (a: string, b: string) => {
  return a.localeCompare(b);
};

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const isEmail = (input: string): boolean => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(input);
}

export const validUrl = (string: string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const parseBool = (value: string): boolean => {
  if (typeof value === "string") {
    value = value.toLowerCase();
    if (value === "true")
      return true;
  }
  return false;
}

export const titleCase = (str: string) => {
  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}


/*************************************************/
/** Returns a Currency Code from a Currency Code */
export const currencySymbol = (currency: string) => {
  const symbol = getSymbolFromCurrency(currency);
  if (symbol) return symbol
  else return currency;
}

const capitalizeObjKey = (key: string): string => {
  const words = key.split(/(?=[A-Z])/);

  return (
    words.map((word) => (
      word.charAt(0).toUpperCase() + word.slice(1))
    ).join(" ")
  );
}


/********************************************/
/** Convert a key of a specific type to a readable string */
export const capitalizeKey = <T>(key: keyof T): string => {
  if (typeof key === "string") {
    return capitalizeObjKey(key);
  } else {
    throw new Error("Key must be a string");
  }
}

export function firstTwoWords(str: string = "") {
  return str.split(" ").slice(0, 2).join(" ");
}

export const optionalString = (value: any) => {
  return value ? value.toString() : "-"
}
