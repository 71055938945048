import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Loader, PageHeader, Table } from "../../../../components/layout";
import { TableColumn } from "../../../../components/layout/Table/types";
import { accountPaths } from "../../../../routes/accounts/paths";
import { Family } from "../../../../requests/families/types";
import useFamilies from "../../../../requests/families";
import { PaginatedResponse } from "../../../../types";



function FamilyList() {
  const [tableData, setTableData] = useState<
    PaginatedResponse<Family<undefined>>
  >();
  const { listFamilies } = useFamilies();
  const [loading, setLoading] = useState<boolean>(true);


  const columns: TableColumn[] = [
    {
      title: "Name",
      key: "name",
      content: (family: Family<null>) => (
        <Link
          to={accountPaths.familyDetail(family.id)}
          className="table-title"
        >
          {family.name ? (
            <>
              {family.crest ? (
                <img
                  src={family.crest.url}
                  alt=""
                  className="crest"
                />
              ) : null}
              <div>{family.name}</div>
            </>
          ) : (
            <div>{family.id}</div>
          )}
        </Link >
      )
    },
    {
      title: "Members",
      key: "members",
      content: (family: Family<null>) => (
        family.userCount
      )
    }
  ]

  /*****************/
  /** Get all data */
  const syncData = (e?: MouseEvent) => {
    if (e) e.preventDefault();
    listFamilies()
      .then((resData) => {
        setTableData(resData);
      }).catch(() => null)
      .finally(() => { setLoading(false) });
  }


  useEffect(() => {
    syncData();
  }, [])



  return (
    <div className="root-content">
      {/* Header */}
      <PageHeader
        showSearch={true}
      />

      {/* Main Content */}
      <div className="page-wrapper">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Table
              {...tableData}
              columns={columns}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default FamilyList;