import styled, { keyframes } from "styled-components";

import { colors } from "../../../styles";



const pulseAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const Styles = styled.div`
  flex: 1;
  padding: 24px 20px;
  background-color: ${colors.white};
  border: 2px solid ${colors.grey7};
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  height: auto;

  .loading-animation {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(-45deg, ${colors.grey7},  ${colors.grey8});
    background-size: 400% 400%;
    animation: ${pulseAnimation} 1.5s ease infinite;
  }
`;

export default Styles;
