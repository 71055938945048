import {
  ChangeEvent, Dispatch, MouseEvent, SetStateAction, useEffect
} from "react";

import close_icon from "../../../assets/vector-images/generic/close-icon.svg";
import { LabelTooltip, TextField } from "../../../components/forms";
import { FormValues } from "../../../components/forms/Form/types";
import { Product } from "../../../types/offers";
import { useInput } from "../../../utils/forms";



type Props = {
  product?: Product;
  tagValues: FormValues;
  setTagValues: Dispatch<SetStateAction<FormValues>>;
}

function Tags({
  product,
  tagValues,
  setTagValues
}: Props) {
  const {
    value: newTag,
    setValue: setNewTag,
    stringChange: newTagCh
  } = useInput({
    initialValue: ""
  });

  const addTag = (e: MouseEvent) => {
    e.preventDefault();
    if (!newTag) return;
    setTagValues((prevState) => ({
      ...prevState,
      [newTag]: newTag,
    }));
    setNewTag("");
  }

  const editTag = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTagValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const deleteTag = (e: MouseEvent, key: string) => {
    e.preventDefault();
    setTagValues((prevState) => {
      const { [key]: _, ...rest } = prevState;
      return rest;
    });
  }

  useEffect(() => {
    if (!product) return;
    if (product.tags.length) {
      const tags = product.tags.reduce((acc, tag) => {
        acc[tag] = tag;
        return acc;
      }, {} as FormValues);
      setTagValues(tags);
    }
  }, [product]);



  return (
    <div className="product-tags field-column">
      <LabelTooltip>
        Tags
      </LabelTooltip>
      <div className="tags-wrapper flex wrap">
        <ul className="tag-list flex wrap align-center">
          {Object.entries(tagValues).map(([key, value]) => (
            <li className="tag flex align-center" key={key}>
              <TextField
                name={key}
                value={value}
                onChange={editTag}
              />
              <button
                type="button"
                className="delete-tag"
                onClick={(e) => deleteTag(e, key)}
              >
                <img src={close_icon} alt="" />
              </button>
            </li>
          ))}
        </ul>
        <div className="add-tag flex align-center">
          <TextField
            className="new-tag"
            name="new-tag"
            value={newTag}
            onChange={newTagCh}
            placeholder="Type a new tag and click 'Add'"
            required={false}
          />
          <button
            type="button"
            onClick={addTag}
            className="add-tag button sm"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
}

export default Tags;