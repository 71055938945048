import styled from "styled-components";
import { colors } from "../../../styles";



const Styles = styled.div`
  .countries {
    label {
      display: none;
    }
  }
  table {
    img {
      max-width: 32px;
    }
    .price-range {
      flex-wrap: wrap;
      gap: 0;
      .product-price {
        &:not(:last-child) {
          position: relative;
          padding-right: 1.5rem;
          &::after {
            content: "•";
            color: ${colors.grey6};
            position: absolute;
            display: block;
            border-radius: 50%;
            right: 0.75rem;
            top: 50%;
            transform: translate(50%, -50%);
          }
        }
      }
    }
    .reward {
      font-size: 10px !important;
    }
  }
`;

export default Styles;