import { NavLink } from "react-router-dom";
import { useRef } from "react";

import { getVerboseUserType } from "../../../constants/auth";
import { useHiddenOutside } from "../../../utils/components";
import useAppActions from "../../../redux/actions/app";
import { User } from "../../../requests/users/types";
import { getNavLinks } from "./utils";
import Styles from "./styles";



type Props = {
  open: boolean;
  user: User;
}

function Sidebar({
  open,
  user
}: Props) {
  const { logoutUser } = useAppActions();
  const userPopup = useRef<HTMLDivElement>(null);
  const navLinks = getNavLinks(user);
  const {
    visible: dropdown,
    setVisible: setDropdown
  } = useHiddenOutside(userPopup);

  const logout = () => {
    logoutUser();
  }



  return (
    <Styles id="SideBar" className={open ? "open" : ""}>
      {/* Nav Links */}
      <nav className="nav-links">
        {navLinks.map((navlink, index) => (
          navlink.children?.length ? (
            <div
              key={index}
              className="parent-link"
            >
              <div className="nav-link parent">
                <div className="icon">{navlink.icon}</div>
                <div className="text">{navlink.name}</div>
              </div>
              <div className="children-links">
                {navlink.children.map((childLink, childIndex) =>
                  <NavLink
                    key={`${index}-${childIndex}`}
                    to={childLink.path}
                    className={({ isActive }) => (
                      `nav-link child
                      ${isActive ? ' active' : ""}`
                    )}
                  >
                    <div className="text">
                      {childLink.name}
                    </div>
                  </NavLink>
                )}
              </div>
            </div>
          ) : (
            <NavLink
              key={index}
              to={navlink.path}
              className={({ isActive }) => (
                `nav-link parent
                ${isActive ? ' active' : ""}`
              )}
            >
              <div className="icon">{navlink.icon}</div>
              <div className="text">{navlink.name}</div>
            </NavLink>
          )
        ))}
      </nav>

      {/* User Panel */}
      <div id="userPanel" ref={userPopup}>
        <button
          className="user"
          onClick={() => setDropdown(!dropdown)}
        >
          {user.avatar ? (
            <img
              className="avatar"
              src={user.avatar.url}
              alt=""
            />
          ) : (
            <div className="avatar placeholder">
              {user.firstName?.charAt(0)}
              {user.lastName?.charAt(0)}
            </div>
          )}
          <div className="details">
            <div className="name">
              {user.firstName} {user.lastName}
            </div>
            <div className={`role ${user.userType}`}>
              {getVerboseUserType(user.userType)}
            </div>
          </div>
        </button>
        {dropdown &&
          <button
            className="user-dropdown"
            onClick={logout}
          >
            Logout
          </button>
        }
      </div>
    </Styles>
  );
}

export default Sidebar;