import styled from "styled-components";

import { colors } from "../../../styles";



const Styles = styled.figcaption`
  .image {
    height: 40px;
    max-width: 40px;
  }
  .no-name {
    color: ${colors.grey12}
  }
  .details {
    gap: 2px;
  }
`;


export default Styles;