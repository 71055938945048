import { Link } from "react-router-dom";

import icon from "../../../assets/vector-images/generic/broken-link.svg";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";



function InviteExpired() {
  return (
    <div className="root-content">
      {/* Header */}
      <header className="root-header">
        <img src={icon} alt="" />
        <div className="description">
          Snap! This invite link has expired.<br />
          Ask the Admin on SONA to resend the invite link.
        </div>
      </header>

      {/* Footer */}
      <footer>
        <Link
          to={nonAuthPaths.SIGN_IN}
          className="button submit"
        >
          Back to Login
        </Link>
      </footer>
    </div>
  );
}

export default InviteExpired;