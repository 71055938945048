import axios from "axios";

import { Crest, CrestTypeSelect } from "./types";
import { ErrorResponse } from "../../types";



/*  ####################### */
/** ## CREST API REQUESTS # */
/*  ####################### */
const useCrests = () => {
  /*****************/
  /** Create Crest */
  const createCrest = (data: FormData) => {
    return new Promise<Crest>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/crests/`,
        method: "post",
        data
      }).then((res) => {
        resolve(res.data as Crest);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /****************/
  /** List Crests */
  const listCrests = (params?: Record<string, string>) => {
    return new Promise<Crest[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/crests/`,
        ...(params ? { params } : {})
      }).then((res) => {
        resolve(res.data as Crest[]);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /**************/
  /** Get Crest */
  const getCrest = (id: string | number) => {
    return new Promise<Crest>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/crests/${id}`,
      }).then((res) => {
        resolve(res.data as Crest);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /*****************/
  /** Update Crest */
  const updateCrest = (id: string | number, data: FormData) => {
    return new Promise<Crest>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/crests/${id}`,
        method: "patch",
        data
      }).then((res) => {
        resolve(res.data as Crest);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    createCrest,
    listCrests,
    getCrest,
    updateCrest
  }
}

export default useCrests;



export const CrestTypes: CrestTypeSelect[] = [
  {
    value: "family",
    label: "Family"
  },
  {
    value: "clan",
    label: "Clan"
  }
];

export const AllCrestTypes: CrestTypeSelect[] = [
  ...CrestTypes,
  {
    value: "",
    label: "All Crests"
  }
];