import { useNavigate, useParams } from "react-router-dom";
import { FormEvent, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getName } from "country-list";

import { CustomProductVariant, Product } from "../../../types/offers";
import { Notification, PageHeader } from "../../../components/layout";
import { FormField, FormValues } from "../../../components/forms/Form/types";
import { ProductTypeOptions } from "../../../constants/offers";
import { LabelTooltip } from "../../../components/forms";
import useProducts from "../../../requests/products";
import { minorPrice } from "../../../utils/numbers";
import Form from "../../../components/forms/Form";
import StandardVariants from "./StandardVariants";
import { useInput } from "../../../utils/forms";
import { URLParams } from "../../../constants";
import CustomVariants from "./CustomVariants";
import { ProductFormProps } from "../types";
import Styles from "./styles";
import Tags from "./Tags";



function ProductDetail() {
  const navigate = useNavigate();
  const { productId } = useParams<URLParams>();
  const { getProduct, updateProduct } = useProducts();
  const [product, setProduct] = useState<Product>();
  const formRef = useRef<HTMLFormElement>(null);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [tagValues, setTagValues] = useState<FormValues>({});
  const [activeVariants, setActiveVariants] = useState<string[]>([]);
  const {
    value: minPrice,
    stringChange: minPriceCh,
    setValue: setMinPrice
  } = useInput({
    initialValue: ""
  });
  const {
    value: maxPrice,
    stringChange: maxPriceCh,
    setValue: setMaxPrice
  } = useInput({
    initialValue: ""
  });


  /**************/
  /** Exit Page */
  const exitPage = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    navigate(-1);
  }

  /********************************************/
  /** Toggle active state of Standard Variant */
  const toggleVariantActive = (id: string) => {
    if (activeVariants.includes(id)) {
      setActiveVariants(activeVariants.filter(vId => vId !== id));
    } else {
      setActiveVariants([
        ...activeVariants,
        id
      ]);
    }
  }

  /*****************/
  /** Save changes */
  const saveDetails = (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    if (!productId || !product) {
      exitPage();
      return;
    };
    const formData = new FormData();
    Object.keys(formValues).forEach((key) => {
      formData.append(key, formValues[key]);
    });
    // Standard Variants
    const variants = product.variants.map(variant => {
      if (variant.minPrice) {
        return {
          ...variant,
          minPrice: minorPrice(parseFloat(minPrice).toFixed(2)),
          maxPrice: minorPrice(parseFloat(maxPrice).toFixed(2))
        }
      } else {
        return {
          ...variant,
          isActive: activeVariants.includes(variant.id)
        };
      }
    });
    formData.delete("variants");
    formData.append("variants", JSON.stringify(variants));
    // Custom Variants
    if (formValues.customVariants) {
      const customVariants: CustomProductVariant[] =
        formValues.customVariants.map((variant: CustomProductVariant) => {
          return {
            ...variant,
            fullPrice: minorPrice(variant.fullPrice ?? 0)
          }
        });
      formData.delete("customVariants");
      formData.append(
        "customVariants",
        JSON.stringify(customVariants)
      );
    }
    if (tagValues) {
      formData.delete("tags");
      formData.append(
        "tags",
        JSON.stringify(Object.values(tagValues))
      );
    }
    updateProduct(productId, formData)
      .then(() => {
        toast(
          <Notification type="success">
            Product Updated!
          </Notification>
        );
        exitPage();
      }).catch(() => null);
  }



  /*******************************/
  /** Props for child components */
  const variantProps: ProductFormProps = {
    product,
    minPrice,
    formValues,
    setFormValues,
    onSubmit: saveDetails,
    exitPage,
    minPriceCh,
    setMinPrice,
    maxPrice,
    maxPriceCh,
    setMaxPrice,
    activeVariants,
    setActiveVariants,
    toggleVariantActive
  }


  /****************/
  /** Form fields */
  const fields: FormField[] = [
    {
      type: "text",
      name: "name",
      label: "Product Name",
      placeholder: "Name of the Product",
      groupClass: "field-row 1"
    },
    {
      type: "react-node",
      name: "",
      label: "",
      groupClass: "field-row 1",
      reactNode: (
        product ? (
          <div className="field-column">
            <LabelTooltip>
              Country
            </LabelTooltip>
            <div className="pseudo-field">
              {getName(product.country)}
            </div>
          </div>
        ) : null
      )
    },
    {
      type: "textarea",
      name: "description",
      label: "Description",
      placeholder: "Description of the product",
      required: false,
      groupClass: "field-group 2"
    },
    {
      type: "select",
      name: "type",
      label: "Type",
      options: ProductTypeOptions,
      placeholder: "Choose Product Type",
      groupClass: "field-row 6"
    },
    {
      type: "file",
      name: "smallIcon",
      label: "Small Icon",
      placeholder: "Select Icon",
      groupClass: "field-row flex align-end img-file",
    },
    {
      type: "file",
      name: "midIcon",
      label: "Medium Icon",
      placeholder: "Select Icon",
      groupClass: "field-row flex align-end img-file",
    },
    {
      type: "file",
      name: "largeIcon",
      label: "Large Icon",
      placeholder: "Select Icon",
      groupClass: "field-row flex align-end img-file",
    },
    {
      type: "react-node",
      name: "",
      label: "",
      groupClass: "custom-variants",
      reactNode: <CustomVariants  {...variantProps} />
    },
    {
      type: "react-node",
      name: "",
      label: "",
      groupClass: "standard-variants",
      reactNode: <StandardVariants {...variantProps} />
    },
    {
      type: "react-node",
      name: "",
      label: "",
      groupClass: "tags",
      reactNode: <Tags
        product={product}
        tagValues={tagValues}
        setTagValues={setTagValues}
      />
    },
    {
      type: "text",
      name: "discountPercent",
      label: "Total Discount (%)",
      placeholder: "Total Discount",
      groupClass: "field-row 4"
    },
    {
      type: "text",
      name: "walletReward",
      label: "SONA Card Discount (%)",
      placeholder: "SONA Card Discount",
      groupClass: "field-row 4"
    },
    {
      type: "text",
      name: "cardReward",
      label: "3rd-Party Card Discount (%)",
      placeholder: "3rd-Party Card Discount",
      groupClass: "field-row 4"
    },
    {
      type: "text-editor",
      name: "terms",
      label: "Terms & Conditions",
      groupClass: "field-group 5"
    },
    {
      type: "text-editor",
      name: "redeemInstructions",
      label: "How to Redeem",
      groupClass: "field-group 5"
    }
  ];


  useEffect(() => {
    if (!productId) {
      exitPage();
      return;
    }
    getProduct(productId)
      .then((resData) => {
        const product = resData as Product;
        setProduct(product);
        setFormValues(product);
      }).catch(() => null);
  }, [productId])



  return (
    <div className="root-content">
      <PageHeader />
      <Styles id="ProductDetail">
        <Form
          fields={fields}
          values={formValues}
          setValues={setFormValues}
          onSubmit={saveDetails}
          ref={formRef}
        >
          <div className="flex align-center justify-between">
            <button
              className="button auto-width white-black"
              onClick={exitPage}
            >
              Cancel
            </button>
            <button
              className="button auto-width"
              type="submit"
            >
              Save Changes
            </button>
          </div>
        </Form>
      </Styles>
    </div>
  );
}

export default ProductDetail;