import { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { PageHeader, Notification } from "../../../../components/layout";
import { FormValues } from "../../../../components/forms/Form/types";
import { assetPaths } from "../../../../routes/assets/paths";
import Spinner from "../../../../components/layout/Spinner";
import Form from "../../../../components/forms/Form";
import { URLParams } from "../../../../constants";
import useTrees from "../../../../requests/trees";
import { Tree } from "../../../../types/core";
import useTreeDetailUtils from "./utils";
import Styles from "./styles";



function TreeDetail() {
  const navigate = useNavigate();
  const { treeId } = useParams<URLParams>();
  const { getTree, updateTree } = useTrees();
  const formRef = useRef<HTMLFormElement>(null);
  const { fields } = useTreeDetailUtils();
  const [formValues, setFormValues] = useState<FormValues>({});
  const [tree, setTree] = useState<Tree>();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!treeId) return;
    updateTree(treeId, formValues).then(() => {
      toast(
        <Notification type="success">
          Tree has been successfully saved
        </Notification>
      );
      navigate(assetPaths.TREES);
    }).catch(() => null)

  }

  /***************************/
  /** Redirect to Trees List */
  const exitPage = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    navigate(assetPaths.TREES)
  }


  useEffect(() => {
    if (!treeId) {
      exitPage();
      return;
    }
    getTree(treeId)
      .then((tree) => {
        setTree(tree);
        const {
          desert, desertId, createdAt, updatedAt, id,
          createdById, region,
          ...relevantFields
        } = tree;
        setFormValues(relevantFields);
      }).catch(() => null);
  }, [treeId])



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader
        title={tree ? (
          <div className="tree-details flex">
            <div className="flex">
              <span className={`desert generic`}>
                {tree.desert.name}
              </span>
            </div>
            +
            <div className="flex">
              <span className={`region ${tree.region}`}>
                {tree.region}
              </span>
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      />

      {/* Main Content */}
      <div className="page-wrapper">
        <Form
          fields={fields}
          values={formValues}
          setValues={setFormValues}
          onSubmit={onSubmit}
          ref={formRef}
        >
          <button
            className="button submit"
            type="submit"
          >
            Save Changes
          </button>
        </Form>
      </div>
    </Styles>
  );
}

export default TreeDetail;