import { ChangeEvent, MouseEvent, useMemo } from "react";

import Styles from "./styles";

import {
  ReactComponent as DelIcon
} from "../../../assets/vector-images/generic/delete.svg";
import { OptionValue } from "../../../constants/core";
import { Quiz, QzOption } from "../../../types/core";
import { genRanHex } from "../../../utils/numbers";
import LabelTooltip from "../LabelTooltip";
import QuizOption from "../QuizOption";
import TextField from "../TextField";


export const answers: OptionValue[] = [
  "A", "B", "C", "D", "E", "F"
];
export type Props = {
  quiz: Quiz;
  quizzes: Quiz[];
  setQuizzes: React.Dispatch<React.SetStateAction<Quiz[]>>;
}


function QuizQuestion({ quiz, quizzes, setQuizzes }: Props) {
  const { id: quizId, question, options } = quiz;

  /** Add quiz option */
  const addOption = (e: MouseEvent) => {
    e.preventDefault();
    const newOption = createOption(answers[options.length], quizId);
    const updatedOptions = [...options, newOption]
    const updatedQuizzes = [...quizzes];
    updatedQuizzes[quizMeta.quizIndex].options = updatedOptions;
    setQuizzes(updatedQuizzes);
  }

  /** Question onChange handler */
  const setQuestion = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedQuizzes = [...quizzes];
    updatedQuizzes[quizMeta.quizIndex].question = e.target.value;
    setQuizzes(updatedQuizzes);
  };

  /** Delete handler */
  const deleteQuiz = (e: MouseEvent) => {
    e.preventDefault();
    const updatedQuizzes = quizzes.filter((q) => q.id !== quizId);
    setQuizzes(updatedQuizzes);
  };

  /** Quiz Metadata */
  const quizMeta = useMemo(() => {
    const index = quizzes.findIndex((quiz) => quiz.id === quizId)
    return {
      quizIndex: index,
      fieldName: `${index}-${quizzes.length}`
    };
  }, [quizzes])



  return (
    <Styles className="field-column">
      <LabelTooltip htmlFor={quizMeta.fieldName}>
        Quiz - Question {quizMeta.quizIndex + 1}
      </LabelTooltip>
      <div className="question flex align-center">
        <TextField
          name={quizMeta.fieldName}
          value={question}
          onChange={setQuestion}
          placeholder="Question for mission goes here"
        />
        <button
          className="btn-image delete"
          onClick={deleteQuiz}
        >
          <DelIcon />
        </button>
      </div>
      <div className="options field-column">
        {options.map((option) =>
          <QuizOption
            key={option.value}
            option={{ ...option, quizId }}
            quizIndex={quizMeta.quizIndex}
            quiz={quiz}
            quizzes={quizzes}
            setQuizzes={setQuizzes}
          />
        )}
      </div>
      {(options.length < 6)
        ? (
          <button
            className="link"
            onClick={addOption}
          >
            + Add another option
          </button>
        ) : null
      }
    </Styles>
  )
}

export default QuizQuestion;



/** Create a new option */
export const createOption = (
  answer: OptionValue, quizId: number | string
): QzOption => ({
  value: answer,
  text: "",
  isCorrect: false,
  quizId
});

/** Create a new quiz */
export const createQuiz = (): Quiz => {
  const quizId = genRanHex(10);
  const newOption = createOption("A", quizId);
  return {
    id: quizId,
    question: "",
    options: [newOption]
  }
}