import { useEffect, useState } from "react";
import moment from "moment";

import { capitalizeKey } from "../../../../utils/strings";
import { decimalPrice } from "../../../../utils/numbers";
import { Tribe } from "../../../../requests/tribe/types";
import { Card } from "../../../../components/layout";
import { FilteredUserData } from "./types";



type Props = {
  tribe: Tribe | null;
}

function TribeData({ tribe }: Props) {
  const [formattedTribe, setFormattedTribe] = useState<any>();

  /**********************/
  /** Format Tribe Data */
  const formatTribeData = (tribe: Tribe) => {
    const filteredData = tribeKeys.reduce((obj, rawKey) => {
      const rawValue = tribe[rawKey];
      const { key, value } = filterTribeData(
        rawKey,
        rawValue
      ) as any;
      obj[key] = value;
      return obj;
    }, {} as any);
    setFormattedTribe(filteredData);
  }

  useEffect(() => {
    if (!tribe) return;
    formatTribeData(tribe);
  }, [tribe])



  return (
    !(tribe && formattedTribe) ? (
      null
    ) : (
      <Card>
        <div className="heading">
          <div className="card-title">
            Tribe
          </div>
        </div>
        <ul className="detail-list flex column">
          {Object.entries(formattedTribe).map(([key, value]) =>
            <li className="list-item" key={key}>
              <div className="key">
                {key}
              </div>
              <div className="value">
                {value ? value.toString() : "-"}
              </div>
            </li>
          )}
        </ul>
      </Card>
    )
  );
}

export default TribeData;


// Fields to show on component
type TribeKey = keyof Tribe;
const tribeKeys: TribeKey[] = [
  "holderId",
  "reserveAccountId", "reserveAccountBalance",
  "currentAccountId", "currentAccountBalance",
  "cardId", "cardStatus", "cardNumber",
  "createdAt"
]

/****************************************/
/** Format output data for Tribe fields */
const filterTribeData = (
  rawKey: TribeKey,
  rawValue: any
): FilteredUserData => {
  let key = capitalizeKey<Tribe>(rawKey);
  let value = rawValue ? rawValue.toString() : "";
  switch (rawKey) {
    case "reserveAccountId":
      key = "Reserve Account ID";
      return {
        key,
        value
      }
    case "reserveAccountBalance":
      const reserveBalance = `£${decimalPrice(value)}`;
      return {
        key,
        value: reserveBalance
      }
    case "currentAccountId":
      key = "Current Account ID";
      return {
        key,
        value
      }
    case "cardId":
      key = "Card ID";
      return {
        key,
        value
      }
    case "currentAccountBalance":
      const currentBalance = `£${decimalPrice(value)}`;
      return {
        key,
        value: currentBalance
      }
    case "cardStatus":
      return {
        key,
        value: capitalizeKey<any>(value)
      }

    case "createdAt":
      const createdAtValue = rawValue ? (
        moment(rawValue).format("Do MMMM, YYYY - hh:mma")
      ) : "-";
      return {
        key,
        value: createdAtValue
      }
    default: {
      return {
        key,
        value
      }
    }
  }
}