import MissionList from ".";



function Published() {
  return (
    <MissionList isPublished={true} />
  );
}

export default Published;