import { ReactNode } from "react";

import Spinner from "../Spinner";
import Styles from "./styles";



export type Props = {
  message?: ReactNode;
}

function Loader({ message }: Props) {
  return (
    <Styles className="loader">
      <Spinner />
      {message ? (
        <div className="load-message">
          {message}
        </div>
      ) : null}
    </Styles>
  );
}

export default Loader;