import { useNavigate, Link } from "react-router-dom";
import { useRef, useState } from "react";

import { FormField, FormValues } from "../../../components/forms/Form/types";
import logo from "../../../assets/vector-images/sona/logo.svg";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import { useQueryParams } from "../../../utils/components";
import useAppActions from "../../../redux/actions/app";
import { rootPaths } from "../../../routes/paths";
import { Form } from "../../../components/forms";
import useUsers from "../../../requests/users";



function InviteSetPass() {
  const navigate = useNavigate();
  const { setAppLoading } = useAppActions();
  const { setPassword } = useUsers();
  const { queryParams } = useQueryParams();
  const firstName = queryParams.get("firstName");
  const username = queryParams.get("username");
  const formRef = useRef<HTMLFormElement>(null);
  const [formValues, setFormValues] = useState<FormValues>({});


  /****************/
  /** Submit Data */
  const onSubmit = () => {
    setAppLoading(true);
    const data = {
      username,
      password: formValues.password
    }
    setPassword(data)
      .then(() => {
        navigate(nonAuthPaths.INVITE_PASS_SUCCESS);
      }).catch(() => null);
  }

  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "password",
      name: "password",
      label: "Password",
      placeholder: "Your new secure password",
      autoComplete: "new-password",
      username: username,
      groupClass: "field-group"
    },
    {
      type: "password",
      name: "confirm-password",
      label: "Confirm Password",
      placeholder: "Re-enter your password",
      autoComplete: "new-password",
      groupClass: "field-group"
    }
  ]



  return (
    <div className="root-content">
      {/* Header */}
      <header className="root-header">
        <Link to={rootPaths.HOMEPAGE} className="home-link-icon">
          <img src={logo} alt="" />
        </Link>
        <div className="description">
          Hey {firstName}, set a new password for your account:
          <br />
          {username}
        </div>
      </header>

      {/* Main Content */}
      <div className="page-wrapper">
        <Form
          fields={formFields}
          values={formValues}
          setValues={setFormValues}
          onSubmit={onSubmit}
          ref={formRef}
        >
          <button
            className="button submit"
            type="submit"
          >
            Set Password
          </button>
        </Form>
      </div>
    </div >
  );
}

export default InviteSetPass;