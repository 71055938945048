import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import { ChildFormProps } from "../../../components/forms/FormSteps/types";
import { FormField } from "../../../components/forms/Form/types";
import { EmailTemplate, EmailTemplateListResponse } from "../../../requests/emails/types";
import useAppActions from "../../../redux/actions/app";
import { Form } from "../../../components/forms";
import useEmails from "../../../requests/emails";



function TemplateList({
  values,
  setValues,
  nextStep,
  FormNavigation,
  setTemplate
}: ChildFormProps & {
  setTemplate: Dispatch<SetStateAction<EmailTemplate | undefined>>;
}) {
  const formRef = useRef<HTMLFormElement>(null);
  const { setAppLoading } = useAppActions();
  const { listEmailTemplates } = useEmails();
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [fields, setFields] = useState<FormField[]>([]);


  /**********************************/
  /** Select the Preferred Template */
  const chooseTemplate = () => {
    const currentTemplate = values["email-template"];
    const selectedTemplate = templates.find(template =>
      template.id === currentTemplate
    );
    if (selectedTemplate) {
      setTemplate(selectedTemplate);
      nextStep();
    }
  }

  /*************************/
  /** List Email Templates */
  const retrieveTemplates = () => {
    const batchEmail = values["email-type"] && values["email-type"] === "batch";
    setAppLoading(true);
    listEmailTemplates()
      .then((resData) => {
        setTemplates(resData.data);
        const templateFields: FormField[] = [];
        for (const template of resData.data) {
          // Skip emails with variables on Batch emails
          if (batchEmail) {
            if (Object.keys(template.variables.personalization).length > 0) {
              continue;
            }
          }
          const formFields: FormField[] = [
            {
              type: "radio",
              name: "email-template",
              label: "",
              value: template.id,
              groupClass: `template ${template.id}`,
              className: "style-2",
              children: (
                <div className="wrapper flex column">
                  <div className="title bold">
                    {template.name}
                  </div>
                  <img
                    className="image-preview"
                    src={template.image_path}
                    alt=""
                  />
                </div>
              )
            },
            {
              type: "react-node",
              name: "",
              label: "",
              groupClass: `template ${template.id}`,
              reactNode: (
                <div className="template-link">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                    href={`https://app.mailersend.com/templates/${template.id
                      }/edit`}
                  >
                    View / Edit This Template
                  </a>
                </div>
              )
            }
          ]
          templateFields.push(...formFields);
        }
        setFields(templateFields);
      }).catch(() => null)
      .finally(() => {
        setAppLoading(false);
      })
  }


  useEffect(() => {
    retrieveTemplates();
  }, [])



  return (
    <Form
      values={values}
      setValues={setValues}
      fields={fields}
      onSubmit={chooseTemplate}
      ref={formRef}
      className="template-list"
    >
      <FormNavigation />
    </Form>
  );
}

export default TemplateList;