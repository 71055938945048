import { useState, ChangeEvent, Fragment, useEffect, MouseEvent } from "react";
import { v4 } from "uuid";

import { ToggleTwo } from "../../../components/forms/ToggleField/styles";
import { LabelTooltip, TextField } from "../../../components/forms";
import {
  ReactComponent as DeleteIcon
} from "../../../assets/vector-images/generic/close-icon.svg";
import { currencySymbol } from "../../../utils/strings";
import { decimalPrice } from "../../../utils/numbers";
import { ProductFormProps } from "../types";
import {
  CustomProductVariant,
  ProductVariant
} from "../../../types/offers";



function CustomVariants({
  product,
  minPrice,
  minPriceCh,
  setMinPrice,
  maxPrice,
  maxPriceCh,
  setMaxPrice,
  activeVariants,
  setFormValues,
  toggleVariantActive
}: ProductFormProps) {
  const [initialMin, setInitialMin] = useState<number | string>(0);
  const [initialMax, setInitialMax] = useState<number | string>(0);
  const [customVariants, setCustomVariants] = useState<
    CustomProductVariant[]
  >([]);
  const [existingCustomVariants, setExistingCustomVariants] = useState<
    ProductVariant[]
  >([]);


  /*************************/
  /** Add a Custom Variant */
  const addVariant = async (e: MouseEvent, parentVariant: ProductVariant) => {
    e.preventDefault();
    const {
      // Omit fields which makes this look like a ranged variant
      minPrice, maxPrice,
      initialMaxPrice, initialMinPrice,
      ...otherFields
    } = parentVariant
    const variant: CustomProductVariant = {
      ...otherFields,
      rangedParentId: parentVariant.id,
      id: v4(),
      isActive: true,
      fullPrice: ""
    }
    setCustomVariants((prevState) => ([
      ...prevState,
      variant
    ]));
  }

  /****************************/
  /** Remove a Custom Variant */
  const removeVariant = async (e: MouseEvent, variant: CustomProductVariant
  ) => {
    e.preventDefault();
    const filteredVariants = customVariants.filter(
      v => v.id !== variant.id
    );
    setCustomVariants(filteredVariants);
  }

  /***************************************************/
  /** OnChange handler for editinga a Custom Variant */
  const setVariantValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedVariants = [...customVariants];
    const index = updatedVariants.findIndex((v) => v.id === name);
    updatedVariants[index].fullPrice = value;
    setCustomVariants(updatedVariants)
  }


  useEffect(() => {
    if (!product) return;
    // Ranged Variants
    const rangedVariant = product.variants.find((variant) => (
      variant.minPrice && !variant.fullPrice
    ));
    if (rangedVariant) {
      setMinPrice(decimalPrice(rangedVariant.minPrice?.toString() || ""));
      setInitialMin(decimalPrice(rangedVariant.initialMinPrice?.toString() || ""))
      setMaxPrice(decimalPrice(rangedVariant.maxPrice?.toString() || ""));
      setInitialMax(decimalPrice(rangedVariant.initialMaxPrice?.toString() || ""));
    }
    // Custon Variants
    const customVariants = product.variants.filter((variant) => (
      variant.rangedParentId && variant.fullPrice
    ));
    setExistingCustomVariants(customVariants);
  }, [product])


  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      customVariants
    }));
  }, [customVariants])


  return (
    <>
      {product && product.variants.map((variant) =>
        variant.minPrice ? (
          <Fragment key={variant.id}>
            <div className="flex" key={variant.id}>
              <div className="field-column">
                <LabelTooltip>
                  Price Ranges
                </LabelTooltip>
                <div className="pseudo-field" key={variant.id}>
                  {currencySymbol(product.currency)}{" "}
                  {initialMin} - {initialMax}
                </div>
              </div>
              <div className="field-column">
                <LabelTooltip>
                  Min Price ({currencySymbol(product.currency)})
                </LabelTooltip>
                <TextField
                  type="number"
                  min={initialMin}
                  max={initialMax}
                  step={0.01}
                  value={minPrice}
                  onChange={minPriceCh}
                />
              </div>
              <div className="field-column">
                <LabelTooltip>
                  Max Price ({currencySymbol(product.currency)})
                </LabelTooltip>
                <TextField
                  type="number"
                  min={initialMin}
                  max={initialMax}
                  step={0.01}
                  value={maxPrice}
                  onChange={maxPriceCh}
                />
              </div>
            </div>

            <div className="field-column">
              <LabelTooltip>
                New Custom Variants ({currencySymbol(product.currency)})
              </LabelTooltip>
              {/* New Custom Variants */}
              <div className="variants flex wrap">
                {customVariants.map((variant) =>
                  <div className="variant" key={variant.id}>
                    <TextField
                      type="number"
                      min={initialMin}
                      max={initialMax}
                      key={variant.id}
                      name={variant.id}
                      value={variant.fullPrice ?? ""}
                      onChange={setVariantValue}
                      placeholder="E.g 10"
                    />
                    <button
                      type="button"
                      className="remove"
                      onClick={(e) => removeVariant(e, variant)}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                )}
                <button
                  className="button sm"
                  onClick={(e) => addVariant(e, variant)}
                >
                  Add Variant
                </button>
              </div>
            </div>
            {existingCustomVariants.length > 0 ? (
              <div className="field-column">
                <LabelTooltip>
                  Previous Custom Variants
                </LabelTooltip>
                {/* Existing Custom Variants */}
                <div className="existing-variants flex wrap">
                  {existingCustomVariants.map((variant) =>
                    <div className="variant" key={variant.id}>
                      <ToggleTwo className="variant-toggle">
                        <input
                          type="checkbox"
                          name={`variant-${variant.id}`}
                          id={`variant-${variant.id}`}
                          onChange={() => toggleVariantActive(variant.id)}
                          checked={activeVariants.includes(variant.id)}
                        />
                        <label htmlFor={`variant-${variant.id}`} />
                      </ToggleTwo>
                      <div
                        key={variant.id}
                        className={`pseudo-field${" "
                          }${activeVariants.includes(variant.id) ?
                            "active" : ""}`
                        }
                      >
                        {currencySymbol(product.currency)}{" "
                        }{decimalPrice(variant.fullPrice)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </Fragment>
        ) : null)}
    </>
  );
}

export default CustomVariants;