import styled from "styled-components";



const Styles = styled.div`
  .discount-list {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    .card {
      flex: unset;
    }
  }

  .table-root {
    .original {
      text-decoration: line-through;
    }
  }
`;

export default Styles;