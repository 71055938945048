import styled from "styled-components";

import { colors } from "../../../styles";



const Styles = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  ${''/* Maintain 16:9 aspect ratio for video */}
  padding-bottom: 56.25%;
  iframe, .video-preview {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
  .video-preview {
    border: 1px dashed ${colors.grey1};
    padding: 1rem;
    figure {
      filter: grayscale(100%);
      img {
        width: 5rem;
      }
      figcaption {
        color: ${colors.grey6};
        font-size: 0.9rem;
        text-align: center;
      }
    }
  }
`;

export default Styles;