import styled from "styled-components";

import { colors, snippets } from "../../../styles";



const Styles = styled.figcaption`
  .image {
    width: 48px;
    height: 48px;
  }
  .avatar-placeholder {
    user-select: none;
    flex-shrink: 0;
    text-transform: uppercase;
    height: 48px;
    width: 48px;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 50%;
    background-color: ${colors.primary};
    ${snippets.flexCenter}
    font-weight: 600;
  }
  .details {
    gap: 2px;
    .username {
      font-size: 12px;
      color: ${colors.grey6};
    }
  }
`;

export default Styles;