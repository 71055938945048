import axios from "axios";

import useAppActions from "../redux/actions/app";
import { Desert, Mission } from "../types/core";



/*  ######################### */
/** # MISSIONS API REQUESTS # */
/*  ######################### */
const useMissions = () => {
  const { setAppLoading } = useAppActions();

  /*******************/
  /** Create Mission */
  const createMission = (data: any) => {
    return new Promise<Mission>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/missions`,
        method: "post",
        data
      }).then((res) => {
        resolve(res.data as Mission);
      }).catch((error) => {
        reject(error);
      }).finally(() => {
        setAppLoading(false);
      });
    });
  }

  /***************************/
  /** Get a list of Missions */
  const listMissions = (params?: any) => {
    return new Promise<Mission[]>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/missions/`,
        params
      }).then((res) => {
        const missions = res.data.results as Mission[];
        resolve(missions);
      }).catch(() => {
        reject([])
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }

  /**********************************/
  /** Get an existing Mission by ID */
  const getMission = (id: number | string) => {
    setAppLoading(true)
    return new Promise<Mission | null>((resolve) => {
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/missions/${id}`
      }).then((res) => {
        const mission = res.data as Mission;
        resolve(mission);
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }

  /*************************/
  /** Update Mission by ID */
  const updateMission = (id: number | string, data: any) => {
    return new Promise<Mission>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/missions/${id}/`,
        method: "patch",
        data
      }).then((res) => {
        const mission = res.data as Mission;
        resolve(mission);
      }).catch(() => {
        reject(null)
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }

  /*************************************/
  /** Delete an existing Mission by ID */
  const deleteMission = (id: number | string) => {
    return new Promise<null>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/missions/${id}`,
        method: "delete"
      }).then(() => {
        resolve(null);
      }).catch((error) => {
        reject(error);
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }


  /**************************/
  /** Get a list of Deserts */
  const listDeserts = () => {
    return new Promise<Desert[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/deserts/`
      }).then((res) => {
        resolve(res.data.results as Desert[]);
      }).catch(() => {
        reject([]);
      });
    });
  }

  const setSortNo = (id: number, sortNo: number) => {
    return new Promise<Mission>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/missions/${id}/set-sort`,
        method: "post",
        data: { sortNo }
      }).then((res) => {
        resolve(res.data as Mission);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  return {
    createMission,
    getMission,
    listMissions,
    updateMission,
    deleteMission,

    setSortNo,

    listDeserts
  }
}

export default useMissions;