import { Dispatch, SetStateAction } from "react";

import { Plan, PlanType } from "../../../../requests/plans/types";
import { FormField, FormValues } from
  "../../../../components/forms/Form/types";



type EditPlanUtilsArgs = {
  formValues: FormValues;
  setFeatures: Dispatch<SetStateAction<string[]>>;
  setFeatureTitle: Dispatch<SetStateAction<string>>;
}

const planOrder: string[] = [
  "Individual",
  "Standard",
  "Family",
  "Family Plus"
];

function getPreviousPlanName(planType: string) {
  const index = planOrder.indexOf(planType);
  if (index > 0) {
    return planOrder[index - 1]; // Return the previous plan name
  }
  return "Individual"; // Return null if there's no previous plan
}


export const useEditPlan = (args: EditPlanUtilsArgs) => {
  const { formValues, setFeatures, setFeatureTitle } = args;

  /*********************/
  /** Plan Form Fields */
  const formFields: FormField[] = [
    {
      type: "number",
      name: "maxLearners",
      label: "Max Learners / Children",
      max: 10,
      placeholder: "Max no. of Learners in the Family",
      groupClass: "field-group"
    },
    {
      type: "number",
      name: "maxParents",
      max: 10,
      label: "Max Family Admins / Parents",
      placeholder: "Max no. of Parents in the Family",
      groupClass: "field-group"
    },
    {
      type: "number",
      name: "maxCards",
      label: "Maximum Cards",
      max: 10,
      placeholder: "Max no. of cards for the card program",
      groupClass: "field-group"
    }
  ]

  const getFeatureTitle = (planName: string) => (
    `All the features in the ${planName} plan and:`
  )

  const updateFeatures = (plan: Plan) => {
    const {
      maxParents: maxP, maxLearners: maxL, maxCards: maxC
    } = formValues;

    const maxParents = parseInt(maxP);
    const maxLearners = parseInt(maxL);
    const maxCards = parseInt(maxC);
    const featureList = [];
    if (maxLearners) {
      featureList.push(
        (
          `${maxLearners} ${(maxLearners > 1) ? (
            "Learner Accounts"
          ) : (
            "Learner Account"
          )}`
        )
      );
    }
    if (maxCards) {
      featureList.push(
        (
          `${maxCards} Learner Prepaid ${(maxCards > 1) ? (
            "Cards"
          ) : (
            "Card"
          )} (UK Only)`
        )
      );
    }
    switch (plan.planType) {
      case "individual":
        setFeatureTitle("Membership plan includes:")
        if (maxParents) {
          featureList.push(
            `${maxParents} ${maxParents > 1
              ? "Parent Accounts"
              : "Parent Account"
            }`
          );
        }
        featureList.push(
          "Access to Study Missions",
          "Daily Affirmation Tool",
          "Community Interaction"
        );
        break;
      case "standard":
        setFeatureTitle(
          getFeatureTitle("Individual")
        );
        if (maxParents) {
          featureList.push(
            `${maxParents} ${maxParents > 1
              ? "Parent Accounts"
              : "Parent Account"
            }`
          );
        }
        if (maxCards) {
          featureList.push(
            "In-app Money Management (UK Only)"
          );
        }
        break;
      default:
        const previousName = getPreviousPlanName(plan.name)
        setFeatureTitle(getFeatureTitle(previousName));
        break;
    }

    setFeatures(featureList);
  }


  return {
    formFields,
    updateFeatures
  }
}