import axios from "axios";

import { ErrorResponse } from "../../types";
import { Plan } from "./types";



/*  ########################## */
/** ## PLAN NETWORK REQUESTS # */
/*  ########################## */
const usePlans = () => {
  const listPlans = (params: Record<string, string>) => {
    return new Promise<Plan[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/plans/`,
        params
      }).then((res) => {
        const resData = res.data.results as Plan[];
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    })
  }

  const getPlan = (planId: string | number) => {
    return new Promise<Plan>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/plans/${planId}`
      }).then((res) => {
        const resData = res.data as Plan;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  const updatePlan = (
    planId: string | number,
    data: Record<string, any>
  ) => {
    return new Promise<Plan>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/plans/${planId}`,
        method: "patch",
        data
      }).then((res) => {
        const resData = res.data as Plan;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    })
  }


  return {
    listPlans,
    getPlan,
    updatePlan
  }
}

export default usePlans;