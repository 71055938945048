import styled from "styled-components";

import { colors, resolutions } from "../../../styles";
import arr_right_grey from
  "../../../assets/vector-images/generic/arr-right-grey.svg";



const icon_width = "7px";

const Styles = styled.header`
   &.username-link {
    .breadcrumbs .crumb {
      &:last-child {
        text-transform: unset !important;
      }
    }
  }
  /* Primary Row (Breadcrumbs, Search) */
  .primary-row {
    padding-bottom: 7px;
    border-bottom: 1px solid ${colors.grey1};
    height: 35px;
    @media(max-width: ${resolutions.tablet}) {
      height: auto;
    }
    .breadcrumbs {
      display: flex;
      align-items: center;
      .crumb {
        font-weight: 600;
        font-size: 15px;
        padding-right: calc(1rem + ${icon_width});
        margin-right: 1rem;
        position: relative;
        color: ${colors.grey6};
        text-transform: capitalize;
        a {
          color: inherit;
          font-size: inherit;
        }
        &::after {
          content: "";
          width: ${icon_width};
          height: 11px;
          background-image: url(${arr_right_grey});
          background-size: cover;
          display: block;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &.current {
          color: ${colors.black};
          &::after {
            display: none;
          }
          .spinner {
            padding: 0;
            margin: 0;
            .circle {
              height: 1rem;
              width: 1rem;
              border-width: 2px;
            }
          }
        }
      }
    }
    .search-form {
      margin-left: auto;
      @media(max-width: ${resolutions.tablet}) {
        margin-left: 0;
        width: 100%;
      }
      .text-field {
        position: relative;
        border-radius: 4px;
        input {
          font-size: 12px;
          padding: 5px;
          font-weight: 600;
          &::placeholder {
            color: ${colors.grey9};
          }
        }
      }
    }
  }
 
  /* Secondary Row (Custom Filters) */
  .secondary-row {
    .select-field {
      width: fit-content;
      border: none;
      background-color: transparent;
      padding: 0;
      select {
        padding: 0;
      }
    }
  }
`;

export default Styles;