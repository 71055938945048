import { Link } from "react-router-dom";
import { useRef } from "react";

import { ChildFormProps } from "../../../components/forms/FormSteps/types";
import { FormField } from "../../../components/forms/Form/types";
import { emailPaths } from "../../../routes/emails/paths";
import { Form } from "../../../components/forms";



function EmailType({
  values,
  setValues,
  nextStep,
  FormNavigation
}: ChildFormProps) {
  const formRef = useRef<HTMLFormElement>(null);


  /****************/
  /** Form Fields */
  const fields: FormField[] = [
    {
      type: "radio",
      name: "email-type",
      label: "",
      value: "single",
      groupClass: "field-row",
      className: "style-2",
      children: (
        <div className="wrapper">
          <div className="title bold">
            Single Email
          </div>
          <div className="grey-text mt-0-5">
            Send a personalized email using a template to a single recipient.
          </div>
        </div>
      )
    },
    {
      type: "radio",
      name: "email-type",
      label: "",
      value: "batch",
      groupClass: "field-row",
      className: "style-2",
      children: (
        <div className="wrapper">
          <div className="title bold">
            Batch Emails (not done yet)
          </div>
          <div className="grey-text mt-0-5">
            Send an email using a template to multiple recipients.
          </div>
        </div>
      )
    }
  ]



  return (
    <Form
      values={values}
      setValues={setValues}
      fields={fields}
      onSubmit={nextStep}
      className="email-type"
      ref={formRef}
    >
      <FormNavigation
        previous={
          <Link
            to={emailPaths.LIST}
            className="button submit white-black auto-width"
          >
            Exit
          </Link>
        }
      />
    </Form>
  );
}

export default EmailType;