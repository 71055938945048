import styled from "styled-components";

import { colors, functions } from "../../../../styles";



const Styles = styled.div`
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  }
  .card {
    .heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
      .card-title {
        margin-bottom: 0.5rem;
        font-size: 2rem;
        font-weight: 600;
        color: ${colors.grey2};
      }
    }
  }
  .detail-list {
    gap: unset;
    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.8rem;
      color: ${colors.grey3};
      border-bottom: 1px solid ${colors.grey1};
      padding: 10px 0;
      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
      &.no-break {
        border-bottom: 1px solid ${colors.grey1} !important;
        padding: 10px 0 !important;
      }
    }
  }
  .form-heading {
    margin-bottom: 1.5rem;
    .head {
      font-weight: 600;
      margin-bottom: 5px;
      padding-bottom: 5px;
      font-size: 1.1rem;
      border-bottom: 1px solid ${colors.grey1};
    }
    .caption {
      font-size: 0.8rem;
      color: ${colors.grey6};
    }
  }

  .family-crest-link {
    padding: 1rem;
    border-radius: 10px;
    background-color: ${functions.rgba(colors.black, 0.05)};
  }
`;

export default Styles;