import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";

import { PageHeader } from "../../../../components/layout";
import Form from "../../../../components/forms/Form";
import { useDiscountCreateEditUtils } from "./utils";
import { URLParams } from "../../../../constants";



function CreateEditDiscount() {
  const { planPriceDiscountId } = useParams<URLParams>();
  const formRef = useRef<HTMLFormElement>(null);
  const {
    formFields, formValues,
    loadPlanPrices, getCurrentDiscount, saveDetails,
    confirmDeletion, setFormValues
  } = useDiscountCreateEditUtils(planPriceDiscountId);


  useEffect(() => {
    if (!planPriceDiscountId) return;
    getCurrentDiscount(planPriceDiscountId);
  }, [planPriceDiscountId])

  useEffect(() => {
    loadPlanPrices();
  }, [])



  return (
    <div className="root-content">
      {/* Header */}
      <PageHeader
        title={`${planPriceDiscountId
          ? "Edit Discount"
          : "Add Discount"}`
        }
      />

      {/* Main Content */}
      <div className="page-wrapper">
        <Form
          fields={formFields}
          values={formValues}
          setValues={setFormValues}
          onSubmit={saveDetails}
          ref={formRef}
        >
          <div className="flex">
            <button
              className="button submit auto-width"
              type="submit"
            >
              {planPriceDiscountId
                ? "Save Changes"
                : "Create Discount"
              }
            </button>
            {planPriceDiscountId ? (
              <button
                className="button auto-width submit error"
                type="button"
                onClick={confirmDeletion}
              >
                Delete Discount
              </button>
            ) : null}
          </div>
        </Form>
      </div>
    </div>
  );
}

export default CreateEditDiscount;