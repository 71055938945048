import styled from "styled-components";

import { properties, colors } from "../../../../styles";



const Styles = styled.div`
  .page-wrapper {
    gap: 2rem;
  }
  .form-width {
    max-width: ${properties.maxFormWidth};
    &.table-data {
      padding: 24px 20px;
      background-color: ${colors.white};
      border: 2px solid ${colors.grey7};
      border-radius: 10px;
      .table-row {
        font-size: 0.9rem;
        &:not(:last-child) {
          padding-bottom: 0.5rem;
          margin-bottom: 0.5rem;
          border-bottom: 1px solid ${colors.grey1};
        }
        .name {
          color: ${colors.grey6};
        }
        .description {
          font-weight: 700;
        }
      }
    }
  }

  .details {
    word-break: break-word;
  }
`;

export default Styles;