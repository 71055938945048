import styled from "styled-components";
import { colors, functions } from "../../../styles";


interface Props {
  color?: string;
}

const radius = "70px";

const Styles = styled.div`
  width: 100%;
  .label {
    margin-bottom: 0.5rem;
    justify-content: space-between;
    align-items: center;
    .title, .value {
      font-size: 10px;
    }
    .value {
      color: ${(props: Props) => (
    props.color
      ? props.color
      : colors.primary)};
    }
  }
  .bar {
    background-color: ${(props: Props) => (
    props.color
      ? functions.rgba(props.color, 0.2)
      : functions.rgba(colors.primary, 0.2))};
    border-radius: ${radius};
    overflow: hidden;
    width: 100%;
    height: 8px;
    .fill {
      width: 10%;
      height: 100%;
      border-radius: ${radius};
      background-color: ${(props: Props) => (
    props.color
      ? props.color
      : colors.primary)};
    }
  }
`;

export default Styles;