import styled from "styled-components";

import { properties, colors, snippets } from "../../../styles";



const Styles = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  .card-wrapper {
    border-radius: ${properties.borderRadius};
    background-color: ${colors.white};
    border: 1px solid ${colors.grey1};
    padding: 25px 27px;
    max-width: 120rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    ${snippets.scrollBar}
    .table-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: auto;
      table {
        width: 100%;
        border-collapse: collapse;
        min-width: 30rem;
        td, th {
          vertical-align: middle;
          padding: 20px 0.5rem;
          border-bottom: 1px solid ${colors.grey8};
          text-align: center;
          &:nth-of-type(1) {
            text-align: left;
            padding-left: 0;
          }
          &:last-child {
            text-align: right;
            padding-right: 0;
          }
          img {
            max-height: 5rem;
          }
        }
        th {
          background-color: ${colors.white};
          font-size: 0.7rem;
          padding-top: 0;
          user-select: none;
          position: sticky;
          top: 0;
          font-weight: 600;
          &:nth-of-type(1) {
            .column-sort {
              justify-content: flex-start;
            }
          }
          .column-sort {
            border-radius: 4px;
            cursor: pointer;
            &:hover {
              background-color: ${colors.grey7};
            }
            .column-title {
              margin-top: 2px; /** Yes it's a hack. Get off my back */
            }
            .sort {
              gap: 0;
              .icon {
                height: 10px;
                width: 13.5px;
                svg {
                  height: inherit;
                  width: inherit;
                }
                &.active {
                  height: 13.5px;
                  svg {
                    path {
                      stroke: ${colors.primary};
                    }
                  }
                }
              }
            }
          }
        }
        td {
          color: ${colors.grey6};
          font-size: 0.8rem;
          width: auto;
          &:nth-of-type(1) {
            color: ${colors.grey3};
            font-weight: 600;
            &>* {
              font-size: inherit;
            }
          }
        }
        tr {
          &.clickable {
            cursor: pointer;
          }
          &:last-child {
            td {
              border: none;
              padding-bottom: 0;
            }
          }
          .table-title {
            display: flex;
            align-items: center;
            gap: 7px;
            font-weight: 600;
            img {
              height: 30px;
            }
          }
        }
      }
      .info-panel {
        padding: 2rem 0;
        flex: 1;
      }
      .table-loading {
        flex: 1;
        ${snippets.flexCenter}
      }
    }
    
    ${''/* PAGINATION */}
    .pagination {
      margin-top: 40px;
      gap: 50px;
      .pagination-btn {
        border: 1px solid ${colors.grey11};
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 10px;
        color: ${colors.grey11};
        font-weight: 600;
      }
      .pages {
        gap: 15px;
        flex: 1;
        overflow-x: auto;
        .nav {
          color: ${colors.primary};
          font-size: 11px;
          &:disabled {
            color: ${colors.grey11};
          }
        }
        .numbers {
          display: flex;
          align-items: center;
          gap: 10px;
          .pagination-btn {
            &.active {
              background-color: ${colors.primary};
              color: ${colors.white};
              border-color: ${colors.primary};
            }
          }
        }
      }
      .pagesize {
        flex-shrink: 0;
        .select-field {
          width: 10rem;
          padding: 0;
          select {
            padding: 4px 8px;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  /* Total Count */
  .total-count {
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 0.5rem;
    color: ${colors.grey6};
  }
`;

export default Styles;