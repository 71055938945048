import { useEffect, useState } from "react";

import { Subscription } from "../../../../requests/subscriptions/types";
import useSubscriptions from "../../../../requests/subscriptions";
import { useQueryParams } from "../../../../utils/components";
import { PageHeader, Table } from "../../../../components/layout";
import { PaginatedResponse } from "../../../../types";
import useSubscriptionListUtils from "./utils";
import Styles from "./styles";



function SubscriptionList() {
  const { locationSearch, queryParams } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || "";
  const search = queryParams.get("search") || "";
  const page = queryParams.get("page") || "1";
  const orderBy = queryParams.get("orderBy") || "";
  const { listSubscriptions } = useSubscriptions();
  const { tableColumns } = useSubscriptionListUtils();
  const [loading, setLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<
    PaginatedResponse<Subscription>
  >();


  const getSubscriptions = () => {
    setLoading(true);
    const params = {
      search,
      page,
      pageSize,
      orderBy
    }
    listSubscriptions(params)
      .then((resData) => {
        setTableData(resData);
      }).catch(() => null)
      .finally(() => { setLoading(false) });
  }

  useEffect(() => {
    getSubscriptions();
  }, [locationSearch])



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader showSearch>
        {/*  */}
      </PageHeader>

      {/* Main Content */}
      <div className="page-wrapper">
        <Table
          {...tableData}
          columns={tableColumns}
          loading={loading}
        />
      </div>
    </Styles>
  );
}

export default SubscriptionList;