import styled from "styled-components";

import check_fill from "../../../assets/vector-images/generic/check-fill.svg";
import TextFieldStyles from "../TextField/styles";
import { colors } from "../../../styles";



export const PasswordStyles = styled(TextFieldStyles)`
  position: relative;
  input {
    padding-right: 2rem;
  }
  .toggle-view {
    position: absolute;
    right: 0.5rem;
    top: 1.8rem;
    transform: translateY(-50%);
    width: 1.4rem;
    cursor: pointer;
  }
`;

const Styles = styled.div`
  .password-strength {
    .title {
      margin-bottom: 0.5rem;
    }
    .parameters {
      .param {
        font-size: 0.8rem;
        position: relative;
        padding-left: 18px;
        color: ${colors.grey6};
        &::before {
          content: "";
          background-image: url(${check_fill});
          filter: grayscale(100%);
          height: 12px;
          width: 12px;
          background-size: cover;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &.valid {
          &::before {
            filter: grayscale(0);
          }
        }
      }
    }
  }
`;

export default Styles;