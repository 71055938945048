import { ChildFormProps } from "../../../components/forms/FormSteps/types";

import edit_icon from "../../../assets/vector-images/generic/edit.svg";



function PreviewForm({
  affirmationId,
  values,
  confirmChanges,
  setCurrentStep,
  FormNavigation
}: ChildFormProps & {
  confirmChanges: () => void;
  affirmationId?: string | number;
}) {
  return (
    <div id="previewPage" className="flex">
      <div className="wrapper flex-fill">
        <div id="affirmation" className="field-column">
          <div className="title-label">
            Affirmation
          </div>
          <div className="flex column">
            <div className="title">
              {values["title"]}
            </div>
            <div className="flex">
              <div className="desert generic-region">
                {values["region"]}
              </div>
            </div>
          </div>
        </div>
        {/* Text */}
        <div id="description" className="field-column">
          <div className="flex justify-between">
            <div className="title-label">
              Affirmation Text
            </div>
            <button className="btn-image" onClick={() => setCurrentStep(0)}>
              <img src={edit_icon} className="edit-icon" alt="" />
            </button>
          </div>
          <div className="content">
            {values["text"]}
          </div>
        </div>
        <FormNavigation
          next={
            <button
              className="button auto-width"
              onClick={confirmChanges}
            >
              {affirmationId ? "Save Changes" : "Save Affirmation"}
            </button>
          }
        />
      </div>
      <div id="phonePreview" className="flex column justify-end">
        <div className="text al-center">
          {values["text"]}
        </div>
      </div>
    </div>
  );
}

export default PreviewForm;