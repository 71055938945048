import styled from "styled-components";

import TextFieldStyles from "../TextField/styles";
import { colors } from "../../../styles";



const Styles = styled(TextFieldStyles)`
  display: block;
  .ql-toolbar,
  .ql-container {
    border: none;
  }
  .ql-toolbar {
    border-bottom: 1px solid ${colors.grey1};
  }
  .ql-container {
    min-height: 5rem;
  }
`;


export default Styles;