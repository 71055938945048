import { useParams } from "react-router-dom";
import { useState } from "react";

import { useFormNavigation } from "../../../components/forms/FormSteps";
import { FormStep } from "../../../components/forms/FormSteps/types";
import { FormValues } from "../../../components/forms/Form/types";
import { EmailTemplate } from "../../../requests/emails/types";
import { PageHeader } from "../../../components/layout";
import { FormSteps } from "../../../components/forms";
import { URLParams } from "../../../constants";
import TemplateList from "./TemplateList";
import ComposeEmail from "./ComposeEmail";
import useEmailUtils from "./utils";
import EmailType from "./EmailType";
import Styles from "./styles";



function CreateEmail() {
  const { emailId } = useParams<URLParams>();
  const { currentStep, ...formNavigation } = useFormNavigation(0);
  const [configValues, setConfigValues] = useState<FormValues>({});
  const [formValues, setFormValues] = useState<FormValues>({});
  const [template, setTemplate] = useState<EmailTemplate>();
  const { confirmChanges } = useEmailUtils({ formValues, template });


  /***************/
  /** Form Steps */
  const formSteps: FormStep[] = [
    {
      title: "Email Type",
      content: <EmailType
        {...formNavigation}
        values={configValues}
        setValues={setConfigValues}
      />
    },
    {
      title: "Select Template",
      content: (
        <TemplateList
          {...formNavigation}
          values={configValues}
          setValues={setConfigValues}
          setTemplate={setTemplate}
        />
      )
    },
    {
      title: "Compose Email",
      content: (
        <ComposeEmail
          {...formNavigation}
          values={formValues}
          setValues={setFormValues}
          template={template}
          onSubmit={confirmChanges}
        />
      )
    }
  ]



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader
        title={emailId
          ? "Edit"
          : "Create"
        }
      />

      {/* Main Content */}
      <div className="page-wrapper">
        <FormSteps
          steps={formSteps}
          currentStep={currentStep}
        />
      </div>
    </Styles>
  );
}

export default CreateEmail;