import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppModal } from "../../../components/layout/AppModal/utils";
import save_icon from "../../../assets/vector-images/modal/save.svg";
import { FormValues } from "../../../components/forms/Form/types";
import { missionPaths } from "../../../routes/missions/paths";
import { Notification } from "../../../components/layout";
import useMissions from "../../../requests/missions";
import { Mission, Quiz } from "../../../types/core";



type Props = {
  formValues: FormValues;
  missionId?: string | number;
  mission?: Mission;
  quizzes: Quiz[]
}

const useMissionUtils = ({
  formValues,
  missionId,
  mission,
  quizzes
}: Props) => {
  const navigate = useNavigate();
  const { setAppModal, closeModal } = useAppModal();
  const { createMission, updateMission } = useMissions();


  /*****************************/
  /** Redirect to Mission List */
  const exitPage = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    closeModal();
    if (mission && mission.isPublished) {
      navigate(missionPaths.PUBLISHED)
    } else {
      navigate(missionPaths.DRAFT)
    }
  }

  /************************************************/
  /** Save new Mission or update existing details */
  const saveDetails = async () => {
    const formData = new FormData();
    Object.keys(formValues).forEach((key) => {
      formData.append(key, formValues[key]);
    });
    formData.append("quizzes", JSON.stringify(quizzes));
    // CREATE MISSION
    if (!missionId) {
      createMission(formData)
        .then(() => {
          toast(
            <Notification type="success">
              Mission successfully added!
            </Notification>
          );
          exitPage();
        })
        .catch(() => null)
    }
    else {
      // UPDATE MISSION
      updateMission(missionId, formData)
        .then(() => {
          toast(
            <Notification type="success">
              Mission Updated!
            </Notification>
          );
          exitPage();
        })
        .catch(() => null)
    }
  }

  /********************/
  /** Confirm Changes */
  const confirmChanges = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    setAppModal({
      icon: save_icon,
      title: (!missionId
        ? "You're about to create a Mission"
        : "Confirm Changes"
      ),
      children: (
        !missionId ? (
          `Once this Mission is created,${" "
          }Collaborators will be able to edit and publish changes.`
        ) : (
          `Are you sure you want to save these changes?`
        )
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button white-black"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="button"
            onClick={saveDetails}
          >
            Confirm
          </button>
        </div>
      )
    });
  }



  return {
    saveDetails,
    confirmChanges
  }
}

export default useMissionUtils;