import { Link } from "react-router-dom";

import logo from "../../../assets/vector-images/sona/logo-cup.svg";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import { rootPaths } from "../../../routes/paths";



function ResetPassSuccess() {
  return (
    <div className="root-content">
      {/* Header */}
      <header className="root-header">
        <Link to={rootPaths.HOMEPAGE} className="home-link-icon">
          <img src={logo} alt="" />
        </Link>
        <div className="description">
          <h3>Password Change Successful</h3>
          <div className="description">
            You can now login with your new password.
          </div>
        </div>
      </header>

      {/* Footer */}
      <footer>
        <Link
          className="button submit"
          to={nonAuthPaths.SIGN_IN}
        >
          Sign In
        </Link>
      </footer>
    </div>
  );
}

export default ResetPassSuccess;