import { useCallback, useState, useEffect } from "react";
import "quill/dist/quill.snow.css";
import Quill from "quill";

import Styles from "./styles";



const TOOLBAR_OPTIONS = [
  ['bold', 'italic', 'underline'],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'align': [] }, { 'indent': '-1' }, { 'indent': '+1' }],
  ['link'],
];

type Props = {
  placeholder?: string;
  value: any;
  onChange: any;
  name: string;
}

function TextEditor({
  placeholder = "",
  value = "",
  onChange,
  name
}: Props) {
  // const [value, setValue] = useState(""); // State to hold the editor value
  const [quillRef, setQuillRef] = useState<any>()
  const wrapperRef = useCallback((wrapper: any) => {
    if (!wrapper) return;
    wrapper.innerHTML = "";

    const editor = document.createElement("div");
    wrapper.append(editor);

    const quill = new Quill(editor, {
      theme: "snow",
      modules: { toolbar: TOOLBAR_OPTIONS },
      placeholder
    });
    setQuillRef(quill);
  }, []);


  useEffect(() => {
    if (!quillRef) return;

    quillRef.on("text-change", () => {
      const content = quillRef.root.innerHTML;
      onChange(content);
    });
  }, [quillRef]);

  useEffect(() => {
    if (quillRef && value !== quillRef.root.innerHTML) {
      quillRef.root.innerHTML = value;
    }
  }, [value])



  return (
    <Styles
      className="text-editor"
      id={name}
      ref={wrapperRef}
    />
  );
}

export default TextEditor;
