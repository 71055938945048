export const assetsRoot = "/assets";



/****************/
/** Asset Paths */
export const assetPaths = {
  // Trees
  TREES: `${assetsRoot}/trees`,
  TREE_DETAIL: `${assetsRoot}/trees/:treeId`,
  treeDetail: (treeId: string | number) =>
    `${assetsRoot}/trees/${treeId}`,

  // Avatars
  AVATARS: `${assetsRoot}/avatars`,

  // Crests
  CRESTS: `${assetsRoot}/crests`,
  ADD_CREST: `${assetsRoot}/crests/add`,
  EDIT_CREST: `${assetsRoot}/crests/:crestId`,
  editCrest: (crestId: string | number) =>
    `${assetsRoot}/crests/${crestId}`,
}
