import { SelectFieldOption } from "../../components/forms/SelectField";
import { SubscriptionTxnStatus, SubscriptionTxnType } from "./types";



interface TxnStatusOption extends SelectFieldOption {
  value: SubscriptionTxnStatus | "";
}

interface TxnTypeOption extends SelectFieldOption {
  value: SubscriptionTxnType | "";
}


export const TxnStatusOptions: TxnStatusOption[] = [
  {
    value: "",
    label: "All Statuses"
  },
  {
    value: "open",
    label: "Open"
  },
  {
    value: "successful",
    label: "Successful"
  },
  {
    value: "failed",
    label: "Failed"
  }
]

export const TxnTypeOptions: TxnTypeOption[] = [
  {
    value: "",
    label: "All Types"
  },
  {
    value: "purchase",
    label: "Subscription Renewal"
  },
  {
    value: "register",
    label: "Subscription Registration / Upgrade"
  },
]