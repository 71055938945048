import { Outlet } from "react-router-dom";

import Styles from "./styles";
import { RootPage } from "..";



function NonAuthRoute() {
  return (
    <RootPage>
      <Styles className="root-page">
        <Outlet />
      </Styles>
    </RootPage>
  );
}

export default NonAuthRoute;