import axios from "axios";

import { Address } from "./types";



/*  ############################## */
/** ## COLLABORATOR API REQUESTS # */
/*  ############################## */
const useAddresses = () => {
  /***************************/
  /** Get address by User ID */
  const getUsersAddress = (userId: string) => {
    return new Promise<Address | null>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/addresses/users/${userId}`
      }).then((res) => {
        resolve(res.data);
      }).catch((error) => {
        reject(error)
      });
    });
  }


  return {
    getUsersAddress
  }
}

export default useAddresses;