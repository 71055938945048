import { ReactNode } from "react";

import Styles from "./styles";



export type ModelDetailData = {
  name: ReactNode;
  description: ReactNode;
}

type Props = {
  title?: ReactNode;
  className?: string;
  data: ModelDetailData[];
}

function ModelDetail({
  title,
  data,
  className
}: Props) {
  return (
    <Styles className={`model-detail ${className ? className : ""}`}>
      {title ?
        (typeof title === "string") ? (
          <h3 className="title">
            {title}
          </h3>
        ) : (
          title
        ) : null}
      <ul className="detail-list">
        {data.map((item, index) =>
          <li className="list-item" key={`${item.name}-${index}`}>
            <div className="name flex align-center wrap">
              {item.name}
            </div>
            <div className="description flex align-center wrap">
              {item.description}
            </div>
          </li>
        )}
      </ul>
    </Styles>
  );
}

export default ModelDetail;