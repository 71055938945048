import { SelectFieldOption } from "../components/forms/SelectField";



export type Region =
  | "prehistory"
  | "ancient"
  | "medieval"
  | "colonial"
  | "modern";

interface IRegion extends SelectFieldOption {
  value: Region | "";
}

export const RegionOptions: IRegion[] = [
  {
    value: "prehistory",
    label: "Pre-History"
  },
  {
    value: "ancient",
    label: "Ancient"
  },
  {
    value: "medieval",
    label: "Medieval"
  },
  {
    value: "colonial",
    label: "Colonial"
  },
  {
    value: "modern",
    label: "Modern"
  }
]

export const AllRegionOptions: IRegion[] = [
  {
    value: "",
    label: "All Regions"
  },
  ...RegionOptions
];


export type OptionValue =
  | "A"
  | "B"
  | "C"
  | "D"
  | "E"
  | "F";