import { useEffect, useState } from "react";

import { ChildFormProps } from "../../../components/forms/FormSteps/types";
import edit_icon from "../../../assets/vector-images/generic/edit.svg";
import { VideoPlayer } from "../../../components/layout";
import { Quiz } from "../../../types/core";



function PreviewMissionChanges({
  missionId,
  values,
  setCurrentStep,
  confirmChanges,
  FormNavigation,
  quizzes
}: ChildFormProps & {
  confirmChanges: () => void;
  missionId?: string | number;
  quizzes: Quiz[];
}) {
  const [thumbnailPreview, setThumbnailPreview] = useState<string>("");


  /****************************/
  /** Mission Preview Element */
  const MissionPreview = () => (
    <div id="mission" className="field-column">
      <div className="title-label">
        Mission
      </div>
      <figure className="flex align-end">
        <img src={thumbnailPreview} alt="" />
        <figcaption className="flex column">
          <div className="title">
            {values["title"]}
          </div>
          <div className="flex align-center">
            <div className="desert generic-region">
              {values["region"]}
            </div>
            <div className="stats-list">
              <div>{values["tokens"]} Tokens</div>
              <div>{quizzes.length} Quizzes</div>
            </div>
          </div>
        </figcaption>
      </figure>
    </div>
  );

  useEffect(() => {
    const image = values["thumbnailUrl"];
    if (image instanceof File) {
      setThumbnailPreview(URL.createObjectURL(image))
    } else if (typeof image === "string") {
      setThumbnailPreview(image);
    }
  }, [values])



  return (
    <div id="previewPage">
      {/* Mission Preview */}
      <MissionPreview />

      {/* Description */}
      <div id="description" className="field-column">
        <div className="flex justify-between">
          <div className="title-label">
            Description of Mission
          </div>
          <button className="btn-image" onClick={() => setCurrentStep(0)}>
            <img src={edit_icon} className="edit-icon" alt="" />
          </button>
        </div>
        <div className="content">
          {values["description"]}
        </div>
      </div>

      {/* Video */}
      <div id="video" className="field-column">
        <div className="title-label">
          Mission Video
        </div>
        <VideoPlayer url={values.videoUrl} />
      </div>

      {/* Description */}
      <div id="description" className="field-column">
        <div className="flex justify-between">
          <div className="title-label">
            Additional Reading & References
          </div>
          <button className="btn-image" onClick={() => setCurrentStep(1)}>
            <img src={edit_icon} className="edit-icon" alt="" />
          </button>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: values["additionalReading"] }}
        />
      </div>

      {/* Quizzes */}
      <div id="quizzes">
        {quizzes.map((quiz, index) =>
          <div className="quiz" key={quiz.id}>
            <div className="heading flex justify-between">
              <div className="title">Question {index + 1}</div>
              <button className="btn-image" onClick={() => setCurrentStep(1)}>
                <img src={edit_icon} className="edit-icon" alt="" />
              </button>
            </div>
            <div className="question">{quiz.question}</div>
            <div className="options">
              {quiz.options.map(((option, index) =>
                <div
                  key={index}
                  className={`option ${option.isCorrect ? "correct" : ""}`}
                >
                  {option.value}. {option.text}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <FormNavigation
        next={(
          <div className="flex">
            <button
              className="button auto-width"
              onClick={confirmChanges}
            >
              {missionId
                ? "Save Changes"
                : "Save Mission"
              }
            </button>
          </div>
        )}
      />
    </div>
  );
}

export default PreviewMissionChanges;