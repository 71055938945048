import styled from "styled-components";



const Styles = styled.div`
  .desert-group {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    .coords {
      margin-top: 1.5rem;
      font-size: 0.8rem;
      min-width: unset;
      max-width: unset;
      .card-title {
        font-weight: 600;
        margin: 0;
      }
      button {
        font-size: inherit;
      }
    }
    .lat-lng {
      margin-top: 10px;
      .key,
      .value {
        font-size: 0.8rem;
      }
    }
  }
`;

export default Styles;