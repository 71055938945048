import { useNavigate } from "react-router-dom";
import { useRef } from "react";

import { ChildFormProps } from "../../../components/forms/FormSteps/types";
import { FormField } from "../../../components/forms/Form/types";
import { RegionOptions } from "../../../constants/core";
import { useAppState } from "../../../redux/state";
import { rootPaths } from "../../../routes/paths";
import { Form } from "../../../components/forms";



function SetupForm({
  values,
  setValues,
  nextStep,
  FormNavigation
}: ChildFormProps) {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const { deserts } = useAppState();


  /****************/
  /** Form Fields */
  const fields: FormField[] = [
    {
      type: "text",
      name: "title",
      label: "Affirmation Title",
      placeholder: "Affirmation title here ...",
      groupClass: "field-group 1"
    },
    {
      type: "select",
      name: "desertId",
      label: "Desert (Optional)",
      required: false,
      placeholder: "Select Desert",
      valueKey: "id",
      labelKey: "name",
      options: deserts,
      groupClass: "field-row 1"
    },
    {
      type: "select",
      name: "region",
      label: "Region (Optional)",
      required: false,
      placeholder: "Choose Region",
      options: RegionOptions,
      groupClass: "field-row 1"
    },
    {
      type: "textarea",
      name: "text",
      label: "Affirmation Text",
      placeholder: "Add affirmation text",
      maxLength: 100,
      groupClass: "field-group 2"
    }
  ]



  return (
    <Form
      fields={fields}
      values={values}
      setValues={setValues}
      onSubmit={nextStep}
      ref={formRef}
    >
      <FormNavigation
        previous={
          <button
            className="button auto-width white-black"
            onClick={() => navigate(rootPaths.MISSIONS)}
          >
            Cancel
          </button>
        }
      />
    </Form>
  );
}

export default SetupForm;