import { ReactNode } from "react";

import Styles from "./styles";



export type StatsCard = {
  title: string;
  content: ReactNode;
}

type Props = {
  className?: string;
  children?: ReactNode;
}

function Card({ className, children }: Props) {
  return (
    <Styles className={`card ${className ? className : ""}`}>
      {children
        ? children
        : null
      }
    </Styles>
  )
}

export default Card;