import axios from "axios";



const useStatistics = () => {
  /*******************************/
  /** Get Sochitel float balance */
  const getBalance = () => {
    return new Promise<any>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_OFFERS_API_URL
          }/v1/statistics/balance`,
      }).then((res) => {
        resolve(res.data)
      }).catch((error) => {
        reject(error);
      })
    });
  }

  /*****************************/
  /** Get SONA's total Revenue */
  const getRevenue = () => {
    return new Promise<any>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_OFFERS_API_URL
          }/v1/statistics/revenue`,
      }).then((res) => {
        resolve(res.data)
      }).catch((error) => {
        reject(error);
      })
    });
  }


  return {
    getBalance,
    getRevenue
  }
}

export default useStatistics;