import { useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment";

import { SelectFieldOption } from "../../../../components/forms/SelectField";
import useSubscriptions from "../../../../requests/subscriptions";
import { billingPaths } from "../../../../routes/billing/paths";
import {
  SubcriptionStatusOptions
} from "../../../../requests/subscriptions/utils";
import {
  FormField, FormValues
} from "../../../../components/forms/Form/types";
import { useBillingUtils } from "../../utils";



export const useSubscriptionEditUtils = (
  subscriptionId?: string
) => {
  const navigate = useNavigate();
  const { getPlanPriceOptions } = useBillingUtils();
  const { getSubscription, overrideSubscription } = useSubscriptions();
  const [loading, setLoading] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [planPriceOptions, setPlanPriceOptions] = useState<
    SelectFieldOption[]
  >([]);



  /**************************/
  /** Retrieve Subscription */
  const retrieveSubscription = async (id: string) => {
    try {
      setLoading(true);
      // Set Subscription
      const subscription = await getSubscription(id);
      const {
        freeTrialStart, freeTrialEnd, periodStart, periodEnd,
        nextBillingPeriod, status, planPriceId
      } = subscription;
      setFormValues((prevState) => ({
        ...prevState,
        status,
        periodStart: formatDateTime(periodStart),
        ...(freeTrialStart ? {
          freeTrialStart: formatDateTime(freeTrialStart)
        } : {}),
        ...(freeTrialEnd ? {
          freeTrialEnd: formatDateTime(freeTrialEnd)
        } : {}),
        ...(periodEnd ? {
          periodEnd: formatDateTime(periodEnd)
        } : {}),
        ...(nextBillingPeriod ? {
          nextBillingPeriod: formatDateTime(nextBillingPeriod)
        } : {}),
        ...(planPriceId ? {
          planPriceId: planPriceId.toString(),
        } : {})
      }));
    } catch { }
    finally {
      setLoading(false);
    }
  }

  /****************/
  /** Submit Data */
  const onSubmit = async () => {
    if (!subscriptionId) return;
    setLoading(true);
    try {
      const response = await overrideSubscription(
        subscriptionId,
        formValues
      );
    } catch { }
    finally {
      setLoading(false);
      navigate(billingPaths.subscriptionDetail(subscriptionId));
    }
  }

  /*******************************/
  /** Get the Plan Price Options */
  const loadPlanPrices = async () => {
    const planPrices = await getPlanPriceOptions();
    setPlanPriceOptions(planPrices);
  }

  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "datetime-local",
      name: "freeTrialStart",
      label: "Free Trial Start",
      groupClass: "field-row free-trial"
    },
    {
      type: "datetime-local",
      name: "freeTrialEnd",
      label: "Free Trial End",
      groupClass: "field-row free-trial"
    },
    {
      type: "datetime-local",
      name: "periodStart",
      label: "Period Start",
      groupClass: "field-row period"
    },
    {
      type: "datetime-local",
      name: "periodEnd",
      label: "Period End",
      groupClass: "field-row period"
    },
    {
      type: "datetime-local",
      name: "nextBillingPeriod",
      label: "Next Billing Period",
      groupClass: "field-row next-bill"
    },
    {
      type: "select",
      name: "status",
      options: SubcriptionStatusOptions,
      label: "Status",
      groupClass: "field-row sub-status"
    },
    {
      type: "select",
      name: "planPriceId",
      label: "Plan",
      options: planPriceOptions,
      groupClass: "field-group plans"
    }
  ]


  return {
    planPriceOptions,
    formFields,
    formValues,
    loading,

    retrieveSubscription,
    loadPlanPrices,
    setFormValues,
    onSubmit
  }
}


const formatDateTime = (dateString: string | Date) => {
  return moment(dateString).format("YYYY-MM-DDTHH:mm");
}