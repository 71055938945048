import axios from "axios";



const useOrders = () => {
  /****************/
  /** List Orders */
  const listOrders = () => {
    return new Promise<any>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_OFFERS_API_URL
          }/v1/orders`,
      }).then((res) => {
        resolve(res.data)
      }).catch((error) => {
        reject(error);
      })
    });
  }


  return {
    listOrders,
  }
}

export default useOrders;