import styled from "styled-components";

import mini_check from "../../../assets/vector-images/generic/mini-check.svg"
import { colors, properties } from "../../../styles";



export const rootStyles = `
  position: relative;
  font-size: 0.8rem;
  flex-shrink: 0;
  max-width: ${properties.maxFieldWidth};
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    ${'' /* For keyboard focusing */}
    &:focus {
      & ~ label {
        border-color: ${colors.secondary} !important;
      }
    }
  }
  label {
    user-select: none;
    font-size: inherit;
    margin: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    transition: ${properties.transition};
    ${'' /* For keyboard focusing */}
    border: 1px solid transparent;
  }
`

export const ToggleBlank = styled.div`
  ${rootStyles}
  label {
    .text {
      color: #fff;
    }
  }
  max-width: none;
`;

export const ToggleOne = styled.div`
  ${rootStyles}
  label {
    width: 30px;
    height: 16px;
    border-radius: 12px;
    background-color: ${colors.white};
    background-image: url(${mini_check});
    background-position-y: center;
    background-position-x: 2px;
    background-size: 8px;
    position: relative;
    border: 2px solid ${colors.black};
    &::after {
      content: "";
      position: absolute;
      left: 3px;
      top: 50%;
      transform: translate(0%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${colors.black};
      transition: ${properties.transition};
    }
  }
  input:checked ~ label {
    background-color: ${colors.primary};
    border-color: ${colors.primary};
    &::after {
      background-color: ${colors.white};
      left: 16px;
    }
  }
`;

export const ToggleTwo = styled.div`
  ${rootStyles}
  label {
    width: 16px;
    height: 16px;
    border-radius: 12px;
    background-color: ${colors.white};
    background-image: url(${mini_check});
    background-position-y: center;
    background-position-x: 2px;
    background-size: 8px;
    position: relative;
    border: 2px solid ${colors.black};
  }
  input:checked ~ label {
    background-color: ${colors.primary};
    border-color: ${colors.primary};
    &::after {
      background-color: ${colors.white};
      left: 16px;
    }
  }
`;