import { FormEvent, MouseEvent, useRef, useState } from "react";
import { toast } from "react-toastify";

import { useAppModal } from "../../../../components/layout/AppModal/utils";
import user_mdl_icon from "../../../../assets/vector-images/modal/user.svg";
import { Card, Notification } from "../../../../components/layout";
import { User } from "../../../../requests/users/types";
import { Form } from "../../../../components/forms";
import useAuth from "../../../../requests/auth";
import { FormValues, FormField } from
  "../../../../components/forms/Form/types";



type Props = {
  user: User | null;
}

function AccessManagement({ user }: Props) {
  const { collabSetPassword } = useAuth();
  const { setAppModal, closeModal } = useAppModal();
  const [formValues, setFormValues] = useState<FormValues>({});
  const formRef = useRef<HTMLFormElement>(null);

  // New Password Fields
  const passwordFields: FormField[] = [
    {
      type: "text",
      name: "password",
      label: "New Password",
      groupClass: "1"
    },
    {
      type: "text",
      name: "confirm-password",
      label: "Confirm New Password",
      groupClass: "1"
    }
  ]

  /*************************************/
  /** Confirm reset of user's password */
  const confirmPasswordReset = (e: FormEvent | MouseEvent) => {
    e.preventDefault();
    if (!user) return;
    setAppModal({
      icon: user_mdl_icon,
      title: (
        `Are you sure you want to reset${" "}
        ${user.firstName ? `${user.firstName}'s` : "this"}${" "}
        password?`
      ),
      children: (
        `After you reset their password, they would need to use${" "
        }it to log into their account again.${" "
        }`
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button white-black"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="button"
            onClick={resetUsersPassword}
          >
            Reset Password
          </button>
        </div>
      )
    });
  }

  /**************************/
  /** Reset user's password */
  const resetUsersPassword = (e: FormEvent | MouseEvent) => {
    e.preventDefault();
    closeModal();
    if (!user) return;
    const data = {
      username: user.username,
      password: formValues.password
    }
    collabSetPassword(data).then(() => {
      toast(
        <Notification type="success">
          {user.firstName}'s password has been successfully reset!
        </Notification>
      );
      setFormValues({});
    });
  }



  return (
    <Card>
      <div className="heading">
        <div className="card-title">
          Access Management
        </div>
      </div>
      <div className="wrapper">
        <div className="form-heading">
          <div className="head">
            Reset Password
          </div>
          <div className="caption">
            Enable users to regain access by resetting{" "}
            their forgotten passwords here
          </div>
        </div>
        <Form
          fields={passwordFields}
          values={formValues}
          setValues={setFormValues}
          onSubmit={confirmPasswordReset}
          ref={formRef}
        >
          <button
            className="button auto-width"
            type="submit"
          >
            Reset Password
          </button>
        </Form>
      </div>
    </Card>
  )
}

export default AccessManagement;