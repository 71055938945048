import { EventFunnel } from "../requests/eventFunnels/types";

import { FunnelStep, SONAEvent } from "../requests/events/types";



// Utility function to generate a random date
const randomDate = (start: Date, end: Date) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};

// Utility to generate a random username
const randomUsername = () => `user_${Math.floor(Math.random() * 1000)}`;


interface Stage {
  label: string;
  backgroundColor: string;
}

export const stages: Stage[] = [
  { label: 'Signups', backgroundColor: '#FFA500' },
  { label: 'Onboarding', backgroundColor: '#008000' },
  { label: 'Subscriptions', backgroundColor: '#00FF00' },
  { label: 'Profile & KYC', backgroundColor: '#808080' },
  { label: 'Main App', backgroundColor: '#0000FF' }
];


// Generate fake events for each stage
export const generateEvents = (): SONAEvent[] => {
  return stages.map((stage, index) => ({
    id: index + 1,
    name: stage.label,
    description: `This is ${stage.label}`,
    stage: stage.label,
    username: randomUsername(),
    createdAt: randomDate(new Date(2022, 0, 1), new Date()),
    metadata: {},
    deviceInfo: "Device_Info",
    browserInfo: "Browser_Info",
    count: Math.floor(Math.random() * 1000) + (1000 - index * 200) // decreasing count
  }));
};


// Generate fake events
// const generateEvents = (stepId: number, count: number): SONAEvent[] => {
//   return Array.from({ length: count }, (_, i) => ({
//     id: i + 1,
//     name: `Event_${i + 1}`,
//     description: `This is event ${i + 1}`,
//     username: randomUsername(),
//     deviceInfo: "Device_Info",
//     browserInfo: "Browser_Info",
//     metadata: {},
//     userId: `user_${i + 1}`,
//     funnelStepId: stepId,
//     createdAt: randomDate(new Date(2022, 0, 1), new Date())
//   }));
// };

// Generate fake funnel steps
const generateFunnelSteps = (funnelId: number): FunnelStep[] => {
  const steps = [
    { id: 1, name: "Step 1: Signup", order: 1 },
    { id: 2, name: "Step 2: Email Verification", order: 2 },
    { id: 3, name: "Step 3: Profile Setup", order: 3 },
    { id: 4, name: "Step 4: First Purchase", order: 4 }
  ];

  return steps.map(step => ({
    ...step,
    description: `${step.name} description`,
    eventFunnelId: funnelId,
    // events: generateEvents(step.id, Math.floor(Math.random() * 20)), // Random number of events
    events: [],
    createdAt: new Date()
  }));
};

// Generate fake event funnel
export const generateEventFunnel = (): EventFunnel => {
  const funnelId = 1;
  return {
    id: funnelId,
    name: "User Signup Funnel",
    description: "This funnel tracks user signup flow",
    steps: generateFunnelSteps(funnelId),
    createdAt: new Date(),
    updatedAt: new Date()
  };
};


export const generateFakeData = (): EventFunnel => {
  // Generate fake events per funnel step
  const funnelSteps: FunnelStep[] = [
    {
      id: 1,
      name: 'Signups',
      description: 'User signed up',
      events: Array.from({ length: 673 }, (_, i) => ({
        id: i + 1,
        name: 'Signup Event',
        description: 'User signed up',
        username: `user_${i + 1}`,
        deviceInfo: 'Device info',
        browserInfo: 'Browser info',
        metadata: {},
        funnelStepId: 1,
        createdAt: new Date(),
      })),
      createdAt: new Date(),
    },
    {
      id: 2,
      name: 'Onboarding',
      description: 'User completed onboarding',
      events: Array.from({ length: 486 }, (_, i) => ({
        id: i + 1,
        name: 'Onboarding Event',
        description: 'User completed onboarding',
        username: `user_${i + 1}`,
        deviceInfo: 'Device info',
        browserInfo: 'Browser info',
        metadata: {},
        funnelStepId: 2,
        createdAt: new Date(),
      })),
      createdAt: new Date(),
    },
    {
      id: 3,
      name: 'Subscriptions',
      description: 'User subscribed',
      events: Array.from({ length: 183 }, (_, i) => ({
        id: i + 1,
        name: 'Subscription Event',
        description: 'User subscribed',
        username: `user_${i + 1}`,
        deviceInfo: 'Device info',
        browserInfo: 'Browser info',
        metadata: {},
        funnelStepId: 3,
        createdAt: new Date(),
      })),
      createdAt: new Date(),
    },
    {
      id: 4,
      name: 'Profile & KYC',
      description: 'User completed profile & KYC',
      events: Array.from({ length: 72 }, (_, i) => ({
        id: i + 1,
        name: 'KYC Event',
        description: 'User completed KYC',
        username: `user_${i + 1}`,
        deviceInfo: 'Device info',
        browserInfo: 'Browser info',
        metadata: {},
        funnelStepId: 4,
        createdAt: new Date(),
      })),
      createdAt: new Date(),
    },
    {
      id: 5,
      name: 'Main App',
      description: 'User accessed main app',
      events: Array.from({ length: 45 }, (_, i) => ({
        id: i + 1,
        name: 'Main App Event',
        description: 'User accessed main app',
        username: `user_${i + 1}`,
        deviceInfo: 'Device info',
        browserInfo: 'Browser info',
        metadata: {},
        funnelStepId: 5,
        createdAt: new Date(),
      })),
      createdAt: new Date(),
    }
  ];

  return {
    id: 1,
    name: 'User Engagement Funnel',
    description: 'Funnel tracking user journey from signup to main app',
    steps: funnelSteps,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
};