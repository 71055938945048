import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import video_preview from "../../../assets/vector-images/panel/video.svg";
import Styles from "./styles";



type Props = {
  url: string;
  placeholder?: string;
}

function VideoPlayer({
  url,
  placeholder = "Enter the Youtube/Vimeo link to preview the video"
}: Props) {
  const [embedId, setEmbedId] = useState<string>("");

  /****************************************/
  /** Extract Embed ID for Youtube Videos */
  const getEmbedId = (input: string): string => {
    const idRegex = /^[A-Za-z0-9_-]{11}$/;
    const shortUrlRegex = /^https?:\/\/youtu\.be\/([A-Za-z0-9_-]{11})$/;
    const fullUrlRegex = (
      /^https?:\/\/(?:www\.)?youtube\.com\/watch\?v=([A-Za-z0-9_-]{11})$/
    );
    const fullUrlMatch = input.match(fullUrlRegex);
    const shortUrlMatch = input.match(shortUrlRegex);
    const idMatch = input.match(idRegex);
    if (fullUrlMatch) {
      return fullUrlMatch[1];
    } else if (shortUrlMatch) {
      return shortUrlMatch[1];
    } else if (idMatch) {
      return idMatch[0];
    }
    return "";
  }


  useEffect(() => {
    if (!url) return;
    const embedId = getEmbedId(url);
    // Render YouTube Video
    if (embedId) setEmbedId(embedId);
    // Render Vimeo Video
    else setEmbedId("");
  }, [url])



  return (
    <Styles>
      {!url ? (
        <div className="video-preview flex center">
          <figure className="flex column center">
            <img src={video_preview} alt="" />
            <figcaption>
              {placeholder}
            </figcaption>
          </figure>
        </div>
      ) : (
        embedId ? (
          <iframe
            src={`https://www.youtube.com/embed/${embedId}`}
            allow={
              `accelerometer; autoplay; clipboard-write;
            encrypted-media; gyroscope; picture-in-picture`
            }
            allowFullScreen
            title="Embedded youtube"
          />
        ) : (
          <>
            <ReactPlayer
              url={url}
              width="100%"
              height="100%"
              controls={true}
            />
          </>
        )
      )}
    </Styles>
  );
}

export default VideoPlayer;