import styled from "styled-components";

import affirmBg from
  "../../../assets/raster-images/core/affirmation-preview-bg.png";
import MissionStyles from "../../Missions/CreateEdit/styles";
import { colors, functions } from "../../../styles";



const Styles = styled(MissionStyles)`
  #previewPage.flex {
    gap: 5rem;
    #phonePreview {
      width: 230px;
      height: 425px;
      border-radius: 10px;
      background-image: url(${affirmBg});
      background-size: cover;
      padding: 2rem;
      display: flex;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 10px;
        background-color: ${colors.grey3};
        width: 40%;
        height: 7px;
        border-radius: 5px;
      }
      .text {
        font-weight: 900;
        font-family: "Nunito", sans-serif;
        color: ${colors.white};
        font-size: 25px;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: ${functions.colorLuminance(functions.randomColor(), -0.2)};
      }
    }
  }
`;

export default Styles;