import styled from "styled-components";

import check_fill from "../../../assets/vector-images/generic/check-fill.svg";
import { colors, functions, properties } from "../../../styles";



export const rootToggleStyles = `
  position: relative;
  font-size: 0.8rem;
  flex-shrink: 0;
  max-width: ${properties.maxFieldWidth};
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    ${'' /* For keyboard focusing */}
    &:focus {
      & ~ label {
        border-color: ${colors.secondary} !important;
      }
    }
  }
  label {
    user-select: none;
    font-size: inherit;
    margin: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    transition: all ${properties.transition};
    ${'' /* For keyboard focusing */}
    border: 1px solid transparent;

  }
`;


const Styles = styled.div`
  ${rootToggleStyles}
  input {
    ${'' /* For keyboard focusing */}
    &:focus {
      & ~ label {
        border-color: ${colors.primary} !important;
      }
    }
  }

  /* First Variation */
  &.style-1 {
    label {
      padding: 7px;
      border: 1px solid ${colors.grey1};
      border-radius: 5px;
      background-color: ${colors.white};
    }
    input:checked ~ label {
      border-color: ${colors.primary};
      background-color: ${functions.rgba(colors.primary, 0.3)};
    }
  }

  /* Second Variation */
  &.style-2 {
    label {
      padding: 24px 20px;
      padding-left: calc(30px + 1.25rem);
      background-color: ${colors.white};
      border: 2px solid ${colors.grey7};
      border-radius: 10px;
      &::before {
        content: "";
        position: absolute;
        left: 24px;
        top: 20px;
        transform: translateY(50%);
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        border: 1px solid ${colors.grey1};
        transition: all ${properties.transition};
      }
    }
    input:checked ~ label {
      border-color: ${colors.primary};
      &::before {
        background-image: url(${check_fill});
        background-size: 110%;
        background-position: center;
        border-color: transparent;
      }
    }
  }
`;

export default Styles;
