import { ChangeEvent, useEffect, useState } from "react";


import { Subscription } from "../../../../../requests/subscriptions/types";
import useSubscriptionTxns from "../../../../../requests/subscriptionTxns";
import { useQueryParams } from "../../../../../utils/components";
import { SelectField } from "../../../../../components/forms";
import filter_icon from
  "../../../../../assets/vector-images/generic/filter.svg";
import { Table } from "../../../../../components/layout";
import { PaginatedResponse } from "../../../../../types";
import {
  TxnTypeOptions, TxnStatusOptions
} from "../../../../../requests/subscriptionTxns/utils";
import {
  SubscriptionTxn
} from "../../../../../requests/subscriptionTxns/types";
import { useInput } from "../../../../../utils/forms";
import { useSubscriptionTxnUtils } from "./utils";



type Props = {
  subscription?: Subscription;
}

function SubscriptionTxns({
  subscription
}: Props) {
  const { locationSearch, queryParams, updateFilters } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || "10";
  const orderBy = queryParams.get("orderBy") || "";
  const status = queryParams.get("status") || "";
  const search = queryParams.get("search") || "";
  const page = queryParams.get("page") || "";
  const type = queryParams.get("type") || "";
  const [loading, setLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<
    PaginatedResponse<SubscriptionTxn>
  >();
  const { listSubscriptionTxns } = useSubscriptionTxns();
  const { tableColumns } = useSubscriptionTxnUtils();

  const {
    value: statusValue,
    setValue: setStatusValue,
  } = useInput<string>({
    initialValue: status,
    selectValues: TxnStatusOptions.map(option => option.value)
  });
  const {
    value: typeValue,
    setValue: setTypeValue,
  } = useInput<string>({
    initialValue: type,
    selectValues: TxnTypeOptions.map(option => option.value)
  });


  /***************************/
  /** List Subscription Txns */
  const retrieveSubscriptionTxns = async (subscription: Subscription) => {
    try {
      setLoading(true);
      const params = {
        subscriptionId: subscription.id,
        search,
        page,
        pageSize,
        orderBy,
        status,
        type
      }
      const subscriptionTxns = await listSubscriptionTxns(params);
      setTableData(subscriptionTxns);
    } catch { }
    finally {
      setLoading(false);
    }
  }

  const updateStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setStatusValue(value);
    updateFilters("status", value);
  }

  const updateType = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setTypeValue(value);
    updateFilters("type", value);
  }


  useEffect(() => {
    if (!subscription) return;
    retrieveSubscriptionTxns(subscription);
  }, [subscription, locationSearch])



  return (
    <div className="transactions">
      <div className="txn-filters flex align-center justify-between">
        <h2 className="main-title">
          Subscription Purchase Attempts
        </h2>
        <div className="flex align-center">
          <SelectField
            options={TxnStatusOptions}
            value={statusValue}
            placeholder="All Statuses"
            onChange={updateStatus}
            icon={filter_icon}
          />
          <SelectField
            options={TxnTypeOptions}
            value={typeValue}
            placeholder="All Types"
            onChange={updateType}
            icon={filter_icon}
          />
        </div>
      </div>
      <Table
        {...tableData}
        columns={tableColumns}
        loading={loading}
      />
    </div>
  );
}

export default SubscriptionTxns;