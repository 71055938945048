import { FormEvent, MouseEvent, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { FormField, FormValues } from "../../../components/forms/Form/types";
import logo from "../../../assets/vector-images/sona/logo.svg";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import { useQueryParams } from "../../../utils/components";
import useAppActions from "../../../redux/actions/app";
import { rootPaths } from "../../../routes/paths";
import { Form } from "../../../components/forms";
import useAuth from "../../../requests/auth";



function ResetPass() {
  const { queryParams } = useQueryParams();
  const username = queryParams.get("username");
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [formValues, setFormValues] = useState<FormValues>({});
  const { setAppLoading } = useAppActions();
  const { requestPasswordReset, resetPassword } = useAuth();


  /****************/
  /** Submit Data */
  const onSubmit = (e: FormEvent | MouseEvent) => {
    e.preventDefault();
    if (!username) {
      navigate(nonAuthPaths.FORGOT_PASSWORD);
      return;
    }
    setAppLoading(true);
    const data = {
      username,
      code: formValues.code,
      password: formValues.password
    }
    resetPassword(data)
      .then(() => {
        navigate(nonAuthPaths.RESET_PASS_SUCCESS);
      })
      .catch(() => null)
      .finally(() => {
        setAppLoading(false);
      });
  }

  /*****************************/
  /** Resend Verification Code */
  const resendCode = () => {
    if (!username) {
      navigate(nonAuthPaths.FORGOT_PASSWORD);
      return;
    }
    requestPasswordReset({ username })
      .then(() => null)
      .catch(() => null);
  }

  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "text",
      name: "code",
      label: "Verification Code",
      placeholder: "Enter the code you received",
      autoComplete: "off",
      groupClass: "field-group"
    },
    {
      type: "password",
      name: "password",
      label: "Password",
      placeholder: "Your new secure password",
      autoComplete: "new-password",
      username: username,
      groupClass: "field-group"
    },
    {
      type: "password",
      name: "confirm-password",
      label: "Confirm Password",
      placeholder: "Re-enter your password",
      autoComplete: "new-password",
      groupClass: "field-group"
    }
  ]



  return (
    <div className="root-content">
      {/* Header */}
      <header className="root-header">
        <Link to={rootPaths.HOMEPAGE} className="home-link-icon">
          <img src={logo} alt="" />
        </Link>
        <div className="description">
          Set your new password, save, then login
        </div>
      </header>

      {/* Main Content */}
      <div className="page-wrapper">
        <Form
          fields={formFields}
          values={formValues}
          setValues={setFormValues}
          onSubmit={onSubmit}
          ref={formRef}
        >
          <button
            className="button submit"
            type="submit"
          >
            Reset Password
          </button>
        </Form>
      </div>

      {/* Footer */}
      <footer className="root-footer">
        <div className="flex">
          <div className="text">
            Didn't receive the code?
          </div>
          <button
            type="button"
            className="link"
            onClick={resendCode}
          >
            Resend Code
          </button>
        </div>
      </footer>
    </div>
  );
}

export default ResetPass;