import { Outlet, useOutletContext } from "react-router-dom";

import { AuthRouteContext } from "../../components/auth/AuthRoute";



export type AccountsContext = AuthRouteContext;

function Accounts() {
  const { user } = useOutletContext<AccountsContext>();

  return (
    <Outlet context={{ user }} />
  );
}

export default Accounts;