import { useEffect } from "react";

import { ToggleTwo } from "../../../components/forms/ToggleField/styles";
import { LabelTooltip } from "../../../components/forms";
import { currencySymbol } from "../../../utils/strings";
import { decimalPrice } from "../../../utils/numbers";
import { ProductFormProps } from "../types";



function StandardVariants({
  product,
  activeVariants,
  setActiveVariants,
  toggleVariantActive
}: ProductFormProps) {
  useEffect(() => {
    if (!product) return;
    setActiveVariants(product.variants.map((variant) => (
      (variant.isActive) ? variant.id : ""
    )));
  }, [product])


  return (
    <>
      {product && product.variants.filter(
        (v) => v.fullPrice && !v.rangedParentId
      ).length > 0 ? (
        <div className="field-column">
          <LabelTooltip>
            Standard Variants
          </LabelTooltip>
          <div className="flex wrap">
            {product?.variants.map((variant) =>
              (variant.fullPrice && !variant.rangedParentId) ? (
                <div className="variant" key={variant.id}>
                  <ToggleTwo className="variant-toggle">
                    <input
                      type="checkbox"
                      name={`variant-${variant.id}`}
                      id={`variant-${variant.id}`}
                      onChange={() => toggleVariantActive(variant.id)}
                      checked={activeVariants.includes(variant.id)}
                    />
                    <label htmlFor={`variant-${variant.id}`} />
                  </ToggleTwo>
                  <div
                    key={variant.id}
                    className={`pseudo-field${" "
                      }${activeVariants.includes(variant.id) ?
                        "active" : ""}`
                    }
                  >
                    {currencySymbol(product.currency)}{" "
                    }{decimalPrice(variant.fullPrice)}
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default StandardVariants;