import axios from "axios";

import { ErrorResponse } from "../../types";



/*  ######################## */
/** ## EVENTS API REQUESTS # */
/*  ######################## */
const useEvents = () => {
  const tempFunnel = () => {
    return new Promise<any>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/events/temp-funnel`
      }).then((res) => {
        const resData = res.data;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    tempFunnel
  }
}

export default useEvents;