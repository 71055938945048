import styled from "styled-components";

import { colors } from "../../../../styles";
import { planStyles } from "../List/styles";



const Styles = styled.div`
  .page-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem
  }
 
  ${'' /* Plan Details */}
  .plan-detail {
    ${planStyles}
    flex: unset;
    .head {
      .plan-region {
        color: ${colors.grey6};
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }
      .name {
        font-size: 1.75rem;
      }
    }
  }

  ${'' /* Plan Prices */}
  .plan-prices {
    flex: 1;
    display: grid;
    gap: 1rem;
    .plan-price {
      max-width: 350px;
    }
  }
`;

export default Styles;