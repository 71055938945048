import { Family } from "../../../requests/families/types";
import { User } from "../../../requests/users/types";
import Styles from "./styles";



type Props = {
  family: Family<undefined | User[]>;
}

function FamilyCrest({ family }: Props) {
  return (
    <Styles className="family-crest flex">
      {family.crest ? (
        <img
          src={family.crest.url}
          className="image"
          alt=""
        />
      ) : null}
      <figcaption className="details flex column justify-center">
        <div className="flex align-center">
          <div className="name">
            {family.name
              ? family.name
              : (
                <div className="no-name">
                  [No Family Name]
                </div>
              )
            }
          </div>
          {family.crest ? (
            <div className="family generic-family">
              {family.crest.name}
            </div>
          ) : null}
        </div>
        <ul className="stats-list">
          <li>
            {family.userCount}{" "}
            {(family.userCount === 1)
              ? "Member"
              : "Members"
            }
          </li>
        </ul>
      </figcaption>
    </Styles>
  )
}

export default FamilyCrest;