import axios from "axios"

import { ErrorResponse, PaginatedResponse } from "../../types"
import { SubscriptionTxn } from "./types"



/*  ################################## */
/** ## SUBSCRIPTION TXN API REQUESTS # */
/*  ################################## */
const useSubscriptionTxns = () => {
  const listSubscriptionTxns = (params?: Record<string, string>) => {
    return new Promise<PaginatedResponse<SubscriptionTxn>>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/subscription-txns/`,
        ...(params ? { params } : {})
      }).then((res) => {
        const resData = res.data as PaginatedResponse<SubscriptionTxn>;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    listSubscriptionTxns
  }
}

export default useSubscriptionTxns;