import { Dispatch, SetStateAction, useState } from "react";

import { SelectFieldOption } from "../../forms/SelectField";
import { PaginationParams, TableProps } from "./types";



export const useTableUtils = (
  props: TableProps,
  tableData: any,
  setTableData: Dispatch<SetStateAction<any>>
) => {
  const {
    columns,
    rowId,
    onRowClick,
    draggable = false,
    dragCallback,
  } = props;
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    count: 0,
    pages: 0,
    pageSize: 0
  });

  const handleDragStart = (index: number) => (e: React.DragEvent) => {
    if (!draggable) return;
    setDraggingIndex(index);
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault(); // Necessary for drop event to be fired
  };

  const handleDrop = (dropIndex: number) => (event: React.DragEvent) => {
    if (!draggable) return;
    if (draggingIndex !== null) {
      const reorderedData = [...tableData];
      const [movedRow] = reorderedData.splice(draggingIndex, 1);
      reorderedData.splice(dropIndex, 0, movedRow);
      setTableData(reorderedData);
      setDraggingIndex(null);
      if (dragCallback)
        dragCallback(reorderedData);
    }
  };

  const handleRowClick = (rowKey: string | number, columnKey: string) => (
    event: React.MouseEvent
  ) => {
    event.preventDefault();
    const row = tableData.find((row: any) =>
      (rowId ? row[rowId] : row.id) === rowKey
    );
    const column = columns.find((col) => col.key === columnKey);
    if (row && column && onRowClick) {
      onRowClick(row, column);
    }
  }

  return {
    handleDragStart,
    handleDragOver,
    handleDrop,
    handleRowClick,

    paginationParams,
    setPaginationParams
  }
}


export const PageSizeOptions: SelectFieldOption[] = [
  {
    value: "10",
    label: "10 Results / Page"
  },
  {
    value: "25",
    label: "25 Results / Page"
  },
  {
    value: "50",
    label: "50 Results / Page"
  },
  {
    value: "100",
    label: "100 Results / Page"
  }
]