import styled from "styled-components";

import checkmark from "../../../assets/vector-images/generic/mini-check.svg";
import { colors, properties } from "../../../styles";



export const rootToggleStyles = `
  position: relative;
  max-width: ${properties.maxFieldWidth};
  display: inline-block;
  width: fit-content;
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
  label {
    user-select: none;
    font-size: inherit;
    margin: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    transition: ${properties.transition};
    ${'' /* For keyboard focusing */}
    border: 1px solid transparent;
  }
`;


const Styles = styled.div`
  ${rootToggleStyles}
  input {
    ${'' /* For keyboard focusing */}
    &:focus {
      & ~ label {
        &::before {
          border-color: ${colors.secondary};
        }
      }
    }
  }
  label {
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      margin-right: 0.5rem;
      border: 1px solid ${colors.grey2};
      background-image: url(${checkmark});
      border-radius: 2px;
      background-color: transparent;
    }
  }
  input:checked ~ label {
    &::before {
      background-color: ${colors.primary};
    }
  }
`;

export default Styles;
