import { useEffect, useState, MouseEvent, ChangeEvent } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

import { PageHeader, Notification, Table } from "../../../components/layout";
import { UserFilters, getVerboseUserType } from "../../../constants/auth";
import { useAppModal } from "../../../components/layout/AppModal/utils";
import del_mdl_icon from "../../../assets/vector-images/modal/delete.svg";
import { AuthRouteContext } from "../../../components/auth/AuthRoute";
import { TableColumn } from "../../../components/layout/Table/types";
import invite_icon from "../../../assets/vector-images/panel/invite.svg";
import { User, UserType } from "../../../requests/users/types";
import { useQueryParams } from "../../../utils/components";
import { SelectField } from "../../../components/forms";
import filter_icon from "../../../assets/vector-images/generic/filter.svg";
import edit_icon from "../../../assets/vector-images/generic/edit.svg";
import { PaginatedResponse } from "../../../types";
import { useInput } from "../../../utils/forms";
import useUsers from "../../../requests/users";
import { collabPaths } from "../../../routes/collaborators/paths";



const List = () => {
  const { user: loggedInUser } = useOutletContext<AuthRouteContext>();
  const {
    updateFilters, locationSearch, queryParams
  } = useQueryParams();
  const search = queryParams.get("search") || "";
  const userType = queryParams.get("userType") || "";
  const page = queryParams.get("page") || "";
  const pageSize = queryParams.get("pageSize") || "";
  const orderBy = queryParams.get("orderBy") || "";
  const { getMe } = useUsers();
  const { setAppModal, closeModal } = useAppModal();
  const { listUsers, deleteUser } = useUsers();
  const [tableData, setTableData] = useState<PaginatedResponse<User>>();
  const [syncing, setSyncing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const {
    value: userTypeValue,
    setValue: setUserTypeValue,
  } = useInput<UserType | "">({
    initialValue: userType as UserType || "",
    selectValues: UserFilters.map(option => option.value)
  });

  const updateUserType = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setUserTypeValue(value as UserType);
    updateFilters("userType", value);
  }

  const resendInvite = (e: MouseEvent, user: User) => {
    e.preventDefault();
    setSyncing(!syncing)
  }

  /**********************/
  /** Get Collaborators */
  const getCollaborators = (e?: MouseEvent) => {
    if (e) e.preventDefault();
    setLoading(true);
    let userTypes: any[] = [userType];
    if (userType === "") {
      userTypes = ["admin", "collaborator"]
    }
    const params = {
      search,
      userType: userTypes.join(),
      page,
      pageSize,
      orderBy
    }
    listUsers(params)
      .then((data) => {
        setTableData(data);
      }).catch(() => null)
      .finally(() => { setLoading(false) });
  }

  /**********************************/
  /** Confirm Collaborator Deletion */
  const confirmDelete = (e: MouseEvent, user: User) => {
    e.preventDefault();
    setAppModal({
      title: "You're about to Delete Collaborator",
      icon: del_mdl_icon,
      children: (
        `When this collaborator is deleted, their access to${" "
        }the dashboard will be revoked and will no longer${" "
        }be able to log into their account.`
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button white-black"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="button error"
            onClick={() => {
              deleteUser(user.id).then(() => {
                toast(
                  <Notification type="success">
                    Collaborator has been successfully deleted.
                  </Notification>
                );
                closeModal();
                window.location.reload();
              });
            }}
          >
            Okay
          </button>
        </div>
      )
    });
  }

  const columns: TableColumn[] = [
    {
      title: "Name",
      key: "id",
      orderByKey: "firstName",
      content: (user: User) => (
        <div className="flex column">
          <div>{user.firstName} {user.lastName}</div>
          <div className="grey-text">{user.username}</div>
        </div>
      )
    },
    {
      title: "Permissions",
      key: "userType",
      orderByKey: "userType",
      content: (user: User) => (
        <div className={`role ${user.userType}`}>
          {getVerboseUserType(user.userType)}
        </div>
      )
    },
    {
      title: "Status",
      key: "lastLogin",
      orderByKey: "lastLogin",
      content: (user: User) =>
        user.lastLogin ? "Active" : "Inactive"
    },
    {
      title: "Date Added",
      key: "createdAt",
      orderByKey: "createdAt",
      content: (user: User) => (
        <div className="grey-text">
          {moment(user.createdAt).format("Do MMM YYYY, hh:mma")}
        </div>
      )
    }
  ]
  if (loggedInUser.userType === "admin") {
    columns.push({
      title: "Actions",
      key: "",
      content: (user: User) => (
        <div className="row flex-end" style={{ gap: "2rem" }}>
          {(
            (loggedInUser.userType === "admin")
          ) ? (
            <>
              <Link to={collabPaths.edit(user.id)}>
                <img src={edit_icon} alt="" />
              </Link>
            </>
          ) : null}
          {/* {!user.lastLogin ? (
            <button
              onClick={(e) => resendInvite(e, user)}
              className={`button transparent ${syncing ? "syncing" : ""}`}
            >
              <img src={sync_icon} alt="" />
            </button>
          ) : null} */}
        </div>
      )
    });
  }


  useEffect(() => {
    getMe();
  }, [])

  useEffect(() => {
    getCollaborators();
  }, [locationSearch])



  return (
    <div className="root-content">
      {/* Header */}
      <PageHeader
        title="List"
        showSearch={true}
      >
        <SelectField
          options={UserFilters}
          value={userTypeValue}
          onChange={updateUserType}
          placeholder="All Users"
          icon={filter_icon}
        />
        <Link
          to={collabPaths.INVITE}
          className="button sm lg-radius"
        >
          Invite
        </Link>
      </PageHeader>

      {/* Main Content */}
      <div className="page-wrapper">
        <Table
          {...tableData}
          columns={columns}
          loading={loading}
          noData={{
            icon: invite_icon,
            title: "No Collaborators Found"
          }}
        />
      </div>
    </div>
  );
}

export default List;