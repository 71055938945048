import { Outlet, useOutletContext } from "react-router-dom";

import { AuthRouteContext } from "../../components/auth/AuthRoute";



const Collaborators = () => {
  const { user } = useOutletContext<AuthRouteContext>();

  return (
    <Outlet context={{ user }} />
  );
}

export default Collaborators;