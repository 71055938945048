import styled from "styled-components";

import { colors, properties } from "../../../../styles";



const Styles = styled.div`
  .merged-table {
    flex: 1;
    border-radius: ${properties.borderRadius};
    background-color: ${colors.white};
    border: 2px solid ${colors.grey7};
    padding: 25px 30px;
    .clan-crest {
      margin-bottom: 2rem;
    }
    .table-root {
      border: none;
      padding: 0;
    }
  }
`;

export default Styles;