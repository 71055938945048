import { User } from "../../../requests/users/types";
import Styles from "./styles";



type Props = {
  user: User
}

function UserAvatar({ user }: Props) {
  return (
    <Styles className="user-avatar flex">
      {user.avatar ? (
        <img
          className="image"
          src={user.avatar.url}
          alt=""
        />
      ) : (
        <div className="avatar-placeholder">
          {user.firstName?.charAt(0)}
          {user.lastName?.charAt(0)}
        </div>
      )}
      <figcaption className="details flex column justify-center">
        <div className="name">
          {`${user.firstName}${" "}
          ${user.middleName}${" "}
          ${user.lastName}`}
        </div>
        <div className="username">
          {user.username}
        </div>
      </figcaption>
    </Styles>
  )
}

export default UserAvatar;