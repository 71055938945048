import { Link } from "react-router-dom";
import moment from "moment";

import { getVerboseInterval } from "../../../../requests/subscriptions/utils";
import { TableColumn } from "../../../../components/layout/Table/types";
import { Subscription } from "../../../../requests/subscriptions/types";
import { billingPaths } from "../../../../routes/billing/paths";
import { optionalString } from "../../../../utils/strings";



const useSubscriptionListUtils = () => {
  const tableColumns: TableColumn[] = [
    {
      title: "Plan",
      key: "planPrice",
      orderByKey: "planPrice.plan.name",
      content: (sub: Subscription) => (
        <Link
          className="table-title"
          to={billingPaths.subscriptionDetail(sub.id)}
        >
          {sub.planPrice
            ? (
              `${sub.planPrice.plan.name} (${getVerboseInterval(
                sub.planPrice.interval
              )})`
            ) : "Free"
          }
        </Link>
      )
    },
    {
      title: "ECommPay Recurring Id",
      key: "recurringId",
      orderByKey: "recurringId",
      content: (sub: Subscription) => (
        optionalString(sub.recurringId)
      )
    },
    {
      title: "Manager",
      key: "manager",
      orderByKey: "manager.username",
      content: (sub: Subscription) => (
        <div>
          {sub.manager.username}
        </div>
      )
    },
    {
      title: "Last Renewed",
      key: "periodStart",
      orderByKey: "periodStart",
      content: (sub: Subscription) => (
        moment(sub.periodStart).format("Do MMM, YYYY")
      )
    },
    {
      title: "Expires On",
      key: "periodEnd",
      orderByKey: "periodEnd",
      content: (sub: Subscription) => (
        sub.periodEnd
          ? moment(sub.periodEnd).format("Do MMM, YYYY")
          : "-"
      )
    },
    {
      title: "Updated At",
      key: "updatedAt",
      orderByKey: "updatedAt",
      content: (sub: Subscription) => (
        moment(sub.updatedAt).format("Do MMM, YYYY")
      )
    },
    {
      title: "Status",
      key: "status",
      orderByKey: "status",
      content: (sub: Subscription) => (
        <div className={`status ${sub.status}`}>
          {sub.status}
        </div>
      )
    }
  ]


  return {
    tableColumns
  }
}

export default useSubscriptionListUtils;