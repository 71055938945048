import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { Loader } from "../../../components/layout";
import { useAppState } from "../../../redux/state";
import { rootPaths } from "../../../routes/paths";



function LoadToken() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { token } = useAppState();


  useEffect(() => {
    if (!token) return;
    if (state && state.previousPath) {
      navigate(state.previousPath);
    } else {
      navigate(rootPaths.HOMEPAGE);
    }
  }, [token, state])



  return (
    <div className="root-content bg-action-pose">
      <div className="page-wrapper centered">
        <Loader />
      </div>
    </div>
  );
}

export default LoadToken;