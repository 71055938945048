import styled from "styled-components";

import { properties } from "../../../styles";



const Styles = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: ${properties.maxFormWidth};
`;

export default Styles;