import { useState } from "react";

import img_icon from "../../../assets/vector-images/panel/image.svg";
import { toast } from "react-toastify";



type FileDetail = {
  title: string;
  icon: string;
}

type FileFieldUtilsProps = {
  name: string;
  onChange: (name: string, file: File | FileList) => void;
  multiple?: boolean;
}

const useFileFieldUtils = ({
  onChange,
  name,
  multiple
}: FileFieldUtilsProps) => {
  const [dragClass, setDragClass] = useState<string>("");
  const [fileDetails, setFileDetails] = useState<FileDetail[]>([]);


  /***********************/
  /** Handle File Change */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? event.target.files : null;
    if (files) updateFiles(files);
  };

  /****************************************/
  /** Dragging a file over this component */
  const handleDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    setDragClass("hover")
  }

  /**************************************/
  /** Drag a file out of this component */
  const handleDragExit = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    setDragClass("");
  }

  /**************************************/
  /** Dropping a file on this component */
  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setDragClass("");
    const files = e.dataTransfer.files;
    updateFiles(files);
  }

  /*************************************/
  /** Set file names and preview icons */
  const updateFiles = (files: FileList) => {
    const maxSizeInBytes = 15 * 1024 * 1024;
    const newFileDetails: FileDetail[] = [];
    Array.from(files).forEach((file) => {
      if (file.size > maxSizeInBytes) {
        toast.error("Please select a file below 15mb");
        return;
      }
      const fileTitle = file.name;
      let fileIcon = img_icon;
      if (file.type.startsWith("image/")) {
        fileIcon = URL.createObjectURL(file);
      }
      newFileDetails.push({
        title: fileTitle,
        icon: fileIcon
      })
    });
    setFileDetails(newFileDetails);
    onChange(name, multiple ? files : files[0]);
  }


  return {
    fileDetails,
    dragClass,
    handleFileChange,
    handleDragOver,
    handleDragExit,
    handleDrop,
    updateFiles
  }
}

export default useFileFieldUtils;