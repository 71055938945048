import { SelectFieldOption } from "../components/forms/SelectField";
import { UserType } from "../requests/users/types";



interface UserTypeSelect extends SelectFieldOption {
  value: UserType;
}
export const UserTypes: UserTypeSelect[] = [
  {
    value: "admin",
    label: "Super Admin"
  },
  {
    value: "collaborator",
    label: "Collaborator"
  }
];

export const getVerboseUserType = (type: UserType) => {
  switch (type) {
    case "admin":
      return "Super Admin";
    case "collaborator":
      return "Collaborator";
    case "basic":
    case "primary":
    case "secondary":
      return "Basic";
    default:
      return "Basic";
  }
}

export const getVerboseStoreName = (name: string) => {
  switch (name) {
    case "sona":
      return "SONA Web Store";
    case "playStore":
      return "Google Play Store";
    case "appStore":
      return "Apple App Store";
    default:
      return "SONA Web Store";
  }
}

export const UserFilters: SelectFieldOption[] = [
  {
    value: "",
    label: "All Users"
  },
  ...UserTypes,
]

export const PlanRegions: SelectFieldOption[] = [
  {
    value: "",
    label: "All Regions"
  },
  {
    value: "UK",
    label: "United Kingdom"
  },
  {
    value: "INTL",
    label: "International"
  }
]

export const PlanIntervals: SelectFieldOption[] = [
  {
    value: "month",
    label: "Monthly"
  },
  {
    value: "year",
    label: "Yearly"
  }
]