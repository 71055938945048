import { ChangeEvent, PropsWithChildren } from "react";

import Styles from "./styles";



export interface Props extends PropsWithChildren, React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement
> {
  value: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

function CheckboxField(props: Props) {
  const {
    value,
    className,
    label,
    ...inputProps
  } = props;



  return (
    <Styles className="checkbox-field">
      <input
        {...inputProps}
        type="checkbox"
        checked={value === "true"}
        id={inputProps.name}
        value={value}
      />
      <label htmlFor={inputProps.name}>
        {label}
      </label>
    </Styles>
  );
}

export default CheckboxField;