import styled from "styled-components";

import { colors } from "../../../styles";



const Styles = styled.div`
  .tree-group {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    .tree-meta {
      .title {
        font-size: 2rem;
        font-weight: 600;
        color: ${colors.grey2};
      }
    }
    
  }
  .table-root {
    .table-wrapper {
      table {
        tr {
          th, td {
            &:nth-of-type(1) {
              width: 2.5rem;
              padding-right: 1rem;
            }
            &:nth-of-type(2) {
              text-align: left;
              width: 40%;
            }
            &:last-child {
              .flex {
                gap: 1rem;
              }
            }
          }
          td {
            img.thumbnail {
              width: 90px;
              height: 50px;
              object-fit: cover;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
`;

export default Styles;