import { offersPaths } from "./paths";
import { RouteType } from "../types";
import {
  OfferMetrics,
  OfferProducts,
  ProductDetail
} from "../../pages/Offers/pages";



/**********/
/* OFFERS */
export const offerRoutes: RouteType[] = [
  {
    path: offersPaths.METRICS,
    element: <OfferMetrics />
  },
  {
    path: offersPaths.PRODUCTS,
    element: <OfferProducts />
  },
  {
    path: offersPaths.PRODUCT_DETAIL,
    element: <ProductDetail />
  }
]