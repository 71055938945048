import axios from "axios";

import useAppActions from "../redux/actions/app";
import { PaginatedResponse } from "../types";
import { Affirmation } from "../types/core";



/*  ################################ */
/** # AFFIRMATION NETWORK REQUESTS # */
/*  ################################ */
const useAffirmations = () => {
  const { setAppLoading } = useAppActions();

  /***********************/
  /** Create Affirmation */
  const createAffirmation = (data: any) => {
    return new Promise<Affirmation>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/affirmations`,
        method: "post",
        data
      }).then((res) => {
        resolve(res.data as Affirmation);
      }).catch((error) => {
        reject(error);
      }).finally(() => {
        setAppLoading(false);
      })
    })
  }

  /**********************************/
  /** Get an existing Affirmation by ID */
  const getAffirmation = (id: number | string) => {
    return new Promise<Affirmation | null>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/affirmations/${id}`
      }).then((res) => {
        const affirmation = res.data as Affirmation;
        resolve(affirmation);
      }).catch(() => {
        reject(null);
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }

  /*******************************/
  /** Get a list of Affirmations */
  const listAffirmations = (params?: any) => {
    return new Promise<PaginatedResponse<Affirmation>>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/affirmations/`,
        params
      }).then((res) => {
        resolve(res.data as PaginatedResponse<Affirmation>);
      }).catch(() => {
        reject([]);
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }

  /*************************/
  /** Update Affirmation by ID */
  const updateAffirmation = (id: number | string, data: any) => {
    return new Promise<Affirmation>((resolve) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/affirmations/${id}/`,
        method: "patch",
        data
      }).then((res) => {
        const affirmation = res.data as Affirmation;
        resolve(affirmation);
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }

  /*****************************************/
  /** Delete an existing Affirmation by ID */
  const deleteAffirmation = (id: number | string) => {
    return new Promise<null>((resolve) => {
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/affirmations/${id}`,
        method: "delete"
      }).then(() => {
        resolve(null);
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }



  return {
    createAffirmation,
    getAffirmation,
    listAffirmations,
    updateAffirmation,
    deleteAffirmation
  }
}

export default useAffirmations