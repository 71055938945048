import styled from "styled-components";


const Styles = styled.div`
  gap: 43px;
  .option-label {
    font-weight: 600;
    font-size: 12px;
    gap: 0.3rem;
  }
`;

export default Styles;