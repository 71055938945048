import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { getName } from "country-list";
import moment from "moment";

import { firstTwoWords, optionalString } from "../../../../utils/strings";
import { accountPaths } from "../../../../routes/accounts/paths";
import {
  FormValues, FormField
} from "../../../../components/forms/Form/types";
import {
  kycRequiredDocs, kycStatuses, KycWithUser
} from "../../../../requests/kyc/types";



type KycTableData = {
  name: string;
  description: ReactNode;
}

const useKycDetailUtils = () => {
  const [kycData, setKycData] = useState<KycTableData[]>([]);
  const [addressData, setAddressData] = useState<KycTableData[]>([]);
  const [formValues, setFormValues] = useState<FormValues>({});


  const fields: FormField[] = [
    {
      type: "text",
      name: "autoKycCount",
      label: "Auto KYC Tries",
      groupClass: "field-row"
    },
    {
      type: "text",
      name: "docUploadCount",
      label: "Document Upload Tries",
      groupClass: "field-row"
    },
    {
      type: "select",
      name: "status",
      label: "Status",
      options: kycStatuses,
      groupClass: "field-group"
    },
    {
      type: "select",
      name: "requiredDocument",
      options: kycRequiredDocs,
      label: "Required Document",
      groupClass: "field-group"
    }
  ]

  const buildData = (kyc: KycWithUser) => {
    const {
      status, requiredDocument, user, autoKycCount, docUploadCount,
      autoKycVerified, documentVerified, watchListCheck, autoKycResponse,
      uk007bCheck, addressLine1, city, country, postCode, docUploadResponse,
      createdAt, updatedAt, countryCode, phoneNumber, houseName, houseNumber,
      street, flat
    } = kyc;
    const {
      firstName, middleName, lastName, dob, username
    } = user;
    setFormValues({
      autoKycCount,
      docUploadCount,
      status,
      requiredDocument,
      autoKycResponse,
      docUploadResponse
    });
    const kycData: KycTableData[] = [
      {
        name: "Email",
        description: (
          <Link
            className="link"
            to={accountPaths.userDetail(user.id)}
          >
            {username}
          </Link>
        )
      },
      {
        name: "Name",
        description: `${firstName} ${middleName} ${lastName}`
      },
      {
        name: "Date of Birth",
        description: `${dob ? (
          moment(dob).format("Do MMMM, YYYY")
        ) : "-"}`
      },
      {
        name: "Auto KYC Verified",
        description: autoKycVerified ? "Yes" : "No"
      },
      {
        name: "Document Verified",
        description: documentVerified ? "Yes" : "No"
      },
      {
        name: "Watchlist Check",
        description: optionalString(watchListCheck)
      },
      {
        name: "KYC Check",
        description: optionalString(uk007bCheck)
      },
      {
        name: "Created At",
        description: (
          createdAt
            ? moment(createdAt).format("Do MMMM, YYYY - hh:mma")
            : "-"
        )
      },
      {
        name: "Last Updated",
        description: (
          updatedAt
            ? moment(updatedAt).format("Do MMMM, YYYY - hh:mma")
            : "-"
        )
      }
    ]
    const addressData: KycTableData[] = [
      {
        name: "Address Line 1",
        description: optionalString(addressLine1)
      },
      {
        name: "House Name",
        description: optionalString(houseName)
      },
      {
        name: "House Number",
        description: optionalString(houseNumber)
      },
      {
        name: "Flat",
        description: optionalString(flat)
      },
      {
        name: "Street",
        description: optionalString(street)
      },
      {
        name: "City",
        description: optionalString(city)
      },
      {
        name: "Post Code",
        description: optionalString(postCode)
      },
      {
        name: "Country",
        description: (
          country
            ? `${firstTwoWords(getName(country))} (${country})`
            : "-"
        )
      },
      {
        name: "Phone Number",
        description: (countryCode && phoneNumber) ?
          `+${countryCode} ${parseInt(phoneNumber.toString())}`
          : "-"
      }
    ]
    setKycData(kycData);
    setAddressData(addressData);
  }


  return {
    fields,
    kycData,
    addressData,
    buildData,
    formValues,
    setFormValues
  }
}

export default useKycDetailUtils;