import styled from "styled-components";

import v_line from "../../../assets/vector-images/patterns/v-line.svg";
import { colors, resolutions, snippets } from "../../../styles";
import seed from "../../../assets/raster-images/core/seed.png";



export const lineSeedPattern = `
  position: relative;
  &::before, &::after {
    content: "";
    z-index: 1;
    position: absolute;
    @media(max-width: ${resolutions.tablet}) {
      display: none;
    }
  }
  &::before {
    height: 100vh;
    width: 12rem;
    background-image: url(${v_line});
    top: 0;
    left: 0;
  }
  &::after {
    width: 200px;
    height: 120px;
    bottom: 2rem;
    right: 2rem;
    background-image: url(${seed});
  }
`;

const Styles = styled.div`
  background-color: ${colors.white};
  ${lineSeedPattern}
  .root-content {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    z-index: 2;
    ${snippets.flexCenter}
    &>* {
      width: 100%;
    }
    .page-wrapper {
      flex: unset;
      form {
        flex: unset;
      }
    }
  }
  
  .root-header {
    ${snippets.flexCenter}
    gap: 1rem;
    &>* {
      text-align: center;
    }
    .description {
      text-align: center;
      color: ${colors.grey6};
    }
  }

  .root-footer {
    font-size: 0.8rem;
    color: ${colors.grey6};
    .link {
      font-size: inherit;
    }
  }
`;

export default Styles;