import styled from "styled-components";

import { colors } from "../../../../styles";



const Styles = styled.div`
  .crest-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 400px));
    .card {
      flex: unset;
      figure {
        gap: 1rem;
        img {
          width: 50px;
          height: 90px;
          object-fit: contain;
          object-position: top;
        }
        figcaption {
          .title {
            color: ${colors.grey3};
            font-weight: 600;
          }
          .description {
            margin-top: 1rem;
            color: ${colors.grey6};
            font-size: 0.9rem;
          }
        }
      }
    }
  }
`;

export default Styles;