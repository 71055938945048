export const billingRoot = "/billing";



/******************/
/** Billing Paths */
export const billingPaths = {
  // Plans
  LIST_PLANS: `${billingRoot}/plans`,
  PLAN_DETAIL: `${billingRoot}/plans/:planId`,
  planDetail: (planId: string | number) =>
    `${billingRoot}/plans/${planId}`,
  EDIT_PLAN: `${billingRoot}/plans/:planId/edit`,
  editPlan: (planId: string | number) =>
    `${billingRoot}/plans/${planId}/edit`,

  // Discounts
  LIST_DISCOUNTS: `${billingRoot}/discounts`,
  ADD_DISCOUNT: `${billingRoot}/discounts/add`,
  EDIT_DISCOUNT: `${billingRoot}/discounts/:planPriceDiscountId`,
  editDiscount: (planPriceDiscountId: string | number) =>
    `${billingRoot}/discounts/${planPriceDiscountId}`,

  // Subscriptions
  LIST_SUBSCRIPTIONS: `${billingRoot}/subscriptions`,
  SUBSCRIPTION_DETAIL: `${billingRoot}/subscriptions/:subscriptionId`,
  subscriptionDetail: (subscriptionId: string | number) =>
    `${billingRoot}/subscriptions/${subscriptionId}`,
  EDIT_SUBSCRIPTION: `${billingRoot}/subscriptions/:subscriptionId/edit`,
  editSubscription: (subscriptionId: string | number) =>
    `${billingRoot}/subscriptions/${subscriptionId}/edit`,
}
