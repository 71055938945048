import { FormEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FormField, FormValues } from "../../components/forms/Form/types";
import { useAppModal } from "../../components/layout/AppModal/utils";
import user_icon from "../../assets/vector-images/modal/user.svg";
import { LabelTooltip } from "../../components/forms";
import useAppActions from "../../redux/actions/app";
import { User } from "../../requests/users/types";
import Form from "../../components/forms/Form";
import { rootPaths } from "../../routes/paths";
import useUsers from "../../requests/users";



const Manage = () => {
  const navigate = useNavigate();
  const { setAppLoading } = useAppActions();
  const { getMe, updateUser } = useUsers();
  const { setAppModal } = useAppModal();
  const formRef = useRef<HTMLFormElement>(null);
  const [profileValues, setProfileValues] = useState<FormValues>({});


  /***************************/
  /** Update Profile Details */
  const updateProfile = (e: FormEvent | MouseEvent) => {
    e.preventDefault();
    updateUser(profileValues)
      .then(() => {
        setAppModal({
          icon: user_icon,
          title: "User Profile Updated",
          children: "Profile has been successfully updated for your account!",
          cancelCallback: () => {
            getMe();
          }
        });
      }).catch(() => null)
  }


  const profileFields: FormField[] = [
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      groupClass: "field-row 1"
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      groupClass: "field-row 1"
    },
    {
      type: "email",
      name: "username",
      label: "Email",
      groupClass: "field-group 1"
    }
  ]


  useEffect(() => {
    setAppLoading(true);
    getMe()
      .then((res) => {
        const user = res as User;
        const { firstName, lastName, username, userType } = user;
        setProfileValues({
          firstName,
          lastName,
          username,
          userType
        });
      })
      .catch(() => null)
      .finally(() => {
        setAppLoading(false);
      });
  }, [])



  return (
    <div id="Manage">
      <div className="page-wrapper">
        {/* Update Account Profile */}
        <div className="update-user form-page">
          <div className="heading flex">
            <div className="num">1</div>
            <div className="text">Update Account Profile</div>
          </div>
          <Form
            fields={profileFields}
            values={profileValues}
            setValues={setProfileValues}
            onSubmit={updateProfile}
            ref={formRef}
          >
            <div className="field-column">
              <LabelTooltip>
                Permissions
              </LabelTooltip>
              <div className="pseudo-field">
                {profileValues["userType"]}
              </div>
            </div>
            <div className="flex justify-between">
              <button
                className="button auto-width white-black"
                type="button"
                onClick={() => navigate(rootPaths.PROFILE)}
              >
                Cancel
              </button>
              <button
                className="button auto-width"
                type="submit"
              >
                Save
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Manage;