import axios from "axios";

import { Email, EmailTemplate, EmailTemplateListResponse } from "./types";
import { ErrorResponse, PaginatedResponse } from "../../types";



const useEmails = () => {
  /*****************/
  /** Create Email */
  const createEmail = (data: any) => {
    return new Promise<Email>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/emails/`,
        method: "post",
        data
      }).then((res) => {
        resolve(res.data as Email);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /****************/
  /** List Emails */
  const listEmails = (params: any) => {
    return new Promise<PaginatedResponse<Email>>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/emails/`,
        params
      }).then((res) => {
        resolve(res.data as PaginatedResponse<Email>);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /***************/
  /** Get Email */
  const getEmail = (id: number | string) => {
    return new Promise<Email>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/emails/${id}`
      }).then((res) => {
        resolve(res.data as Email);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /*************************/
  /** List Email Templates */
  const listEmailTemplates = () => {
    return new Promise<EmailTemplateListResponse>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/emails/mailersend/templates`
      }).then((res) => {
        resolve(res.data as EmailTemplateListResponse);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /***********************/
  /** Get Email Template */
  const getEmailTemplate = (templateId: string) => {
    return new Promise<EmailTemplate>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/emails/mailersend/templates/${templateId}`
      }).then((res) => {
        resolve(res.data as EmailTemplate);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    createEmail,
    listEmails,
    getEmail,
    listEmailTemplates,
    getEmailTemplate
  }
}

export default useEmails;