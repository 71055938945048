import styled from "styled-components";

import { colors, functions, snippets } from "../../../../styles";



const Styles = styled.div`
  .avatar-list {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    .avatar {
      aspect-ratio: 1;
      ${snippets.flexCenter}
      img {
        height: 70px;
      }
    }
  }

  ${'' /* Upload New Avatars */}
  #NewAvatars {
    flex: 1;
    margin-top: 3rem;
    padding-top: 3rem;
    border-top: 1px solid ${functions.rgba(colors.black, 0.1)};
    gap: 0;
    .avatar-list {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
    .uploaded-files {
      padding: 2rem;
      background-color: ${colors.white};
      border: 1px solid ${colors.grey1};
      border-radius: 20px;
    }
  }
`;

export default Styles;