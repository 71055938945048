import { SelectFieldOption } from "../../components/forms/SelectField";
import { Address } from "../addresses/types";
import { User } from "../users/types";



type KYCStatus =
  | "unstarted"
  | "processing"
  | "pass"
  | "fail"
  | "autoFail"
  | "docFail"
  | "completeFail";

type RequiredDocument =
  | "none"
  | "address"
  | "identity"
  | "addressAndIdentity";

export type Kyc = Address & {
  requiredDocument: RequiredDocument;
  documentVerified: boolean;
  docUploadResponse: string;
  autoKycVerified: boolean;
  autoKycResponse: string;
  watchListCheck: string;
  docUploadCount: number;
  documentIds: string[];
  autoKycCount: number;
  uk007bCheck: string;
  uk015Check: string;
  status: KYCStatus;
  createdAt: Date;
  updatedAt: Date;
}

export type KycWithUser = Kyc & { user: User };

export interface StatusOption extends SelectFieldOption {
  value: KYCStatus;
}

export interface RequiredDocsOption extends SelectFieldOption {
  value: RequiredDocument;
}

export const kycStatuses: StatusOption[] = [
  {
    value: "unstarted",
    label: "Unstarted"
  },
  {
    value: "processing",
    label: "Processing"
  },
  {
    value: "pass",
    label: "Pass"
  },
  {
    value: "autoFail",
    label: "Auto KYC Fail"
  },
  {
    value: "docFail",
    label: "Document Upload Fail"
  },
  {
    value: "completeFail",
    label: "Complete Fail"
  }
]

export const kycRequiredDocs: RequiredDocsOption[] = [
  {
    value: "none",
    label: "None"
  },
  {
    value: "address",
    label: "Address"
  },
  {
    value: "identity",
    label: "Identity"
  },
  {
    value: "addressAndIdentity",
    label: "Address and Identity"
  }
]