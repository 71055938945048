import { Link } from "react-router-dom";
import moment from "moment";

import { TableColumn } from "../../../components/layout/Table/types";
import { emailPaths } from "../../../routes/emails/paths";
import { Email } from "../../../requests/emails/types";



const useEmailListUtils = () => {
  const tableColumns: TableColumn[] = [
    {
      title: "Email",
      key: "receivedById",
      orderByKey: "email",
      content: (email: Email) => (
        <Link
          to={emailPaths.view(email.id)}
          className="table-title"
        >
          {email.email}
        </Link>
      )
    },
    {
      title: "Template Name",
      key: "templateName",
      orderByKey: "templateName",
      content: (email: Email) => (
        <div className="grey-text">
          {email.templateName ? email.templateName : "-"}
        </div>
      )
    },
    {
      title: "Created At",
      key: "createdAt",
      orderByKey: "createdAt",
      content: (email: Email) => (
        <div className="grey-text">
          {moment(email.createdAt).isValid()
            ? moment(email.createdAt).format("Do MMMM, YYYY - hh:mma")
            : "-"
          }
        </div>
      )
    },
    {
      title: "Status",
      key: "status",
      orderByKey: "status",
      content: (email: Email) => (
        <div className={`status ${email.status}`}>
          {email.status}
        </div>
      )
    },
    {
      title: "Sent At",
      key: "sentAt",
      orderByKey: "sentAt",
      content: (email: Email) => (
        <div className="grey-text">
          {moment(email.sentAt).isValid()
            ? moment(email.sentAt).format("Do MMMM, YYYY - hh:mma")
            : "-"
          }
        </div>
      )
    }
  ]


  return {
    tableColumns
  }
}

export default useEmailListUtils;