export const emailRoot = "/emails";


const emailId = ":emailId";

export const emailPaths = {
  CREATE: `${emailRoot}/send`,
  LIST: `${emailRoot}/list`,
  VIEW: `${emailRoot}/${emailId}`,
  view: (emailId: number | string) => (
    `${emailRoot}/${emailId}`
  ),
  EDIT: `${emailRoot}/${emailId}/edit`,
  edit: (emailId: number | string) => (
    `${emailRoot}/${emailId}/edit`
  )
}