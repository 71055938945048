import { Link } from "react-router-dom";
import { MouseEvent } from "react";

import menu_icon from "../../../assets/vector-images/generic/menu.svg";
import logo from "../../../assets/vector-images/sona/logo.svg";
import useAppActions from "../../../redux/actions/app";
import { useAppState } from "../../../redux/state";
import { rootPaths } from "../../../routes/paths";
import Styles from "./styles";



function NavHeader() {
  const { setSidebarOpen } = useAppActions();
  const { sidebarOpen } = useAppState();


  /************************/
  /** Toggle Sidebar Menu */
  const toggleMenu = (e: MouseEvent) => {
    e.preventDefault();
    setSidebarOpen(!sidebarOpen);
  }


  return (
    <Styles className="nav-header">
      <div className="header-wrapper">
        <button
          style={{ backgroundImage: `url(${menu_icon})` }}
          onClick={toggleMenu}
          className="sidebar-toggle"
        />
        {/* Main Logo */}
        <Link to={rootPaths.HOMEPAGE} id="logo">
          <img src={logo} alt="" />
        </Link>
      </div>
    </Styles>
  );
}

export default NavHeader;