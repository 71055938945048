import { Link } from "react-router-dom";

import logo from "../../../assets/vector-images/sona/logo.svg";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";



function InvitePassSuccess() {
  return (
    <div className="root-content">
      <div className="page-wrapper">
        {/* Header */}
        <header className="root-header">
          <img src={logo} alt="" />
          <div className="description">
            <h3> Account Password Created</h3>
            <div>
              You've successfully created your account password.
            </div>
          </div>
        </header>

        {/* Footer */}
        <footer>
          <Link
            to={nonAuthPaths.SIGN_IN}
            className="button submit"
          >
            Sign In
          </Link>
        </footer>
      </div>
    </div>
  );
}

export default InvitePassSuccess;