import { useNavigate } from "react-router-dom";

import { useAppModal } from "../../../components/layout/AppModal/utils";
import save_icon from "../../../assets/vector-images/modal/save.svg";
import { FormValues } from "../../../components/forms/Form/types";
import { EmailTemplate } from "../../../requests/emails/types";
import { emailPaths } from "../../../routes/emails/paths";
import useAppActions from "../../../redux/actions/app";
import useEmails from "../../../requests/emails";



type Props = {
  formValues: FormValues;
  template: EmailTemplate | undefined;
}
const useEmailUtils = ({
  formValues,
  template
}: Props) => {
  const navigate = useNavigate();
  const { closeModal, setAppModal } = useAppModal();
  const { setAppLoading } = useAppActions();
  const { createEmail } = useEmails();


  /**********************/
  /** Submit Email Form */
  const sendEmail = () => {
    setAppLoading(true);
    const { username, ...templateParams } = formValues;
    const data = {
      templateId: template?.id,
      templateParams,
      email: username
    }
    createEmail(data)
      .then(() => {
        navigate(emailPaths.LIST);
      })
      .catch(() => null)
      .finally(() => {
        setAppLoading(false);
        closeModal();
      });
  }

  /**********************************/
  /** Confirm Changes before saving */
  const confirmChanges = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    setAppModal({
      icon: save_icon,
      title: "Send Email?",
      children: (
        <>
          <div className="text">
            Are you sure you want to send this email to:
          </div>
          <div className="bold">
            {formValues["username"]}
          </div>
        </>
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button white-black"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="button"
            onClick={sendEmail}
          >
            Confirm
          </button>
        </div>
      )
    });
  }

  return {
    confirmChanges
  }
}

export default useEmailUtils;