import { ChangeEvent, MouseEvent } from "react";

import Styles from "./styles";

import { Props as QuizProps, answers } from "../QuizQuestion";
import delete_icon from "../../../assets/vector-images/generic/delete.svg";
import { QzOption } from "../../../types/core";
import TextField from "../TextField";
import { ToggleOne } from "../ToggleField/styles";


interface Props extends QuizProps {
  quizIndex: number;
  option: QzOption;
};


function QuizOption({ option, quiz, quizIndex, quizzes, setQuizzes }: Props) {
  const { quizId, text, value, isCorrect } = option;
  const { options } = quiz;

  /** Checked onChange handler */
  const setAnswer = () => {
    const updatedOptions = options.map((o) => ({
      ...o,
      isCorrect: o.value === value
    }));
    updateOptions(updatedOptions);
  };

  /** Text onChange handler */
  const setText = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedOptions = [...options];
    const index = options.findIndex((o) => o.value === value);
    updatedOptions[index].text = e.target.value;
    updateOptions(updatedOptions);
  };

  /** Delete handler */
  const deleteOption = (e: MouseEvent) => {
    e.preventDefault();
    const updatedOptions = options.filter((o) => o.value !== value);
    const sortedOptions = updatedOptions.map((o, index) => ({
      ...o,
      value: answers[index]
    }));
    updateOptions(sortedOptions);
  };

  const updateOptions = (opts: QzOption[]) => {
    const updatedQuizzes = [...quizzes];
    updatedQuizzes[quizIndex].options = opts;
    setQuizzes(updatedQuizzes);
  }


  return (
    <Styles className="flex align-center">
      <div className="option-label flex">
        <span>Option</span> <span>{value}</span>
      </div>
      <TextField
        name={`${quizId}-${value}`}
        value={text}
        onChange={setText}
        placeholder="Add answer"
      />
      <div className="flex align-center">
        <ToggleOne>
          <input
            type="checkbox"
            name={`quiz-${quizId}`}
            onChange={setAnswer}
            checked={isCorrect}
          />
          <label htmlFor={`${quizId}-${value}`} />
        </ToggleOne>
        <button className="btn-image delete" onClick={deleteOption}>
          <img src={delete_icon} alt="" />
        </button>
      </div>
    </Styles>
  );
}

export default QuizOption;
