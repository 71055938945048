import MissionList from ".";



function Draft() {
  return (
    <MissionList isPublished={false} />
  )
}

export default Draft;