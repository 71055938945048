import styled from "styled-components";

import { colors, functions, snippets } from "../../../styles";



const Styles = styled.div`
  flex: 1;
  .pseudo-field {
    color: ${colors.black};
    background-color: transparent;
  }
  .title-label {
    font-size: 12px;
    font-weight: 600;
    color: ${colors.grey3};
    margin-bottom: 10px;
  }
  .img-file {
    max-width: 100%;
    .field-column {
      flex: initial;
      &:nth-of-type(1),
      &:nth-of-type(2) {
        .file-field {
          padding: 1rem;
          .description {
            font-size: 10px;
          }
        }
      }
      &:nth-of-type(1) {
        .file-field {
          width: 7rem;
          .preview {
            width: calc((50 / 100) * 90px);
            height: calc((50 / 100) * 50px);
          }
        }
      }
      &:nth-of-type(2) {
        .file-field {
          width: 10rem;
          .preview {
            width: calc((80 / 100) * 90px);
            height: calc((80 / 100) * 70px);
          }
        }
      }
      &:nth-of-type(3) {
        width: 15rem;
      }
    }
  }

  ${'' /** Custom- Variants */}
  .custom-variants {
    .variants {
      gap: 1rem;
      .variant {
        position: relative;
        .remove {
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(50%, -50%);
          z-index: 1;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background-color: ${colors.error};
          svg {
            ${snippets.absoluteCenter}
            height: 80%;
            width: 80%;
            path {
              stroke: ${colors.white};
            }
          }
        }
      }
    }
  }

  ${'' /** Standard Variants */}
  .standard-variants, .existing-variants {
    .flex.wrap {
      gap: 1rem;
    }
    .variant {
      position: relative;
      .variant-toggle {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(50%, -50%);
        z-index: 1;
      }
      .pseudo-field {
        color: ${colors.grey11};
        background-color: ${colors.grey9};
        &.active {
          color: ${colors.grey3};
          background-color: ${colors.white};
        }
      }
    }
  }

  ${''/* Tags */}
  .product-tags {
    .tags-wrapper {
      padding: 1rem;
      border: 1px solid ${colors.grey1};
      border-radius: 6px;
      .text-field,
      .text-field input {
        width: auto;
        max-width: 100px;
        min-width: unset;
        padding: 0;
        display: inline-block;
      }
      .text-field {
        border: none;
        background: unset;
        input {
          font-size: 0.8rem;
        }
      }
      .tag-list {
        .tag {
          font-size: 0.7rem;
          background-color: ${functions.rgba(colors.secondary, 0.1)};
          padding: 5px;
          border-radius: 3px;
        }
        img {
          height: 12px;
        }
      }
    }
  }
`;

export default Styles;