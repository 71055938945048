import { SelectFieldOption } from "../../components/forms/SelectField";
import { PlanPriceInterval } from "../plans/types";
import { SubscriptionStatus } from "./types";



export const getVerboseInterval = (interval: PlanPriceInterval) => {
  switch (interval) {
    case "month":
      return "Monthly";
    case "year":
      return "Annually";
    default:
      return "Monthly";
  }
}


interface SubcriptionStatusOption extends SelectFieldOption {
  value: SubscriptionStatus;
}


export const SubcriptionStatusOptions: SubcriptionStatusOption[] = [
  {
    value: "trial",
    label: "Trial"
  },
  {
    value: "active",
    label: "Active"
  },
  {
    value: "overdue",
    label: "Overdue"
  },
  {
    value: "expired",
    label: "Expired"
  },
  {
    value: "cancelled",
    label: "Cancelled"
  }
]