import styled from "styled-components";

import { colors, functions } from "../../../styles";



const Styles = styled.div`
  border: 1px solid ${colors.warning};
  background-color: ${functions.rgba(colors.warning, 0.12)};
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 1rem 1.1rem;
  border: 1px solid transparent;
  &.error {
    border-color: ${colors.error};
    background-color: ${functions.rgba(colors.error, 0.12)};
    .notify-msg {
      color: ${colors.error};
    }
  }
  &.success {
    border-color: ${colors.success};
    background-color: #e9f5eb;
    .notify-msg {
      color: ${colors.success};
    }
  }
  .icon {
    width: 1rem;
  }
`;


export default Styles;