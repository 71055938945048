export const nonAuthRoot = "/auth";



export const nonAuthPaths = {
  SIGN_IN: `${nonAuthRoot}/sign-in`,
  LOAD_TOKEN: `${nonAuthRoot}/load/`,
  FORGOT_PASSWORD: `${nonAuthRoot}/forgot-password`,
  RESET_PASS: `${nonAuthRoot}/reset-password`,
  RESET_PASS_SUCCESS: `${nonAuthRoot}/reset-password-success`,
  INVITE_SET_PASS: `${nonAuthRoot}/invite/set-password`,
  INVITE_PASS_SUCCESS: `${nonAuthRoot}/invite/password-success`,
  INVITE_EXPIRED: `${nonAuthRoot}/invite/expired`,
}