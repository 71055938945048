import styled from "styled-components";

import { colors, functions, properties, snippets } from "../../styles";



export const personalStyles = `
  .heading {
    justify-content: space-between;
    h3 {
      font-size: 12px;
    }
    .edit {
      width: 13px;
      height: 13px;
    }
  }
  .pseudo-table {
    border: 1px solid ${colors.grey1};
    border-radius: 7px;
    margin: 30px 0 !important;
    .table-row {
      display: flex;
      align-items: cneter;
      justify-content: space-between;
      padding: 30px;
      &:not(:last-child) {
        border-bottom: 1px solid ${colors.grey1};
      }
      .title {
        font-size: 12px;
        color: ${colors.grey6};
      }
      .detail {
        font-size: 12px;
        font-weight: 600;
        &.row {
          gap: 10px;
        }
      }
    }
  }
`;


const Styles = styled.div`
  .root-content {
    width: 100%;
    max-width: ${properties.maxFormWidth};
  }
  #Personal {
    ${personalStyles}
  }
  #Manage {
    .form-page {
      &:nth-of-type(2) {
        margin-top: 55px;
        padding-top: 55px;
        border-top: 1px solid ${colors.grey1};
      }
    }
    .heading {
      margin-bottom: 30px;
      .num {
        color: ${colors.primary};
        background-color: ${functions.rgba(colors.primary, 0.1)};
        font-size: 8px;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        ${snippets.flexCenter};
        font-weight: 600;
      }
      .text {
        color: ${colors.primary};
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
`;

export default Styles;