import {
  Dispatch, MouseEvent, SetStateAction, useEffect, useMemo, useRef, useState
} from "react";
import countries, { getName } from "country-list";

import { getFlagEmoji } from "../../../utils/images";
import RadioButtonField from "../RadioButtonField";
import LabelTooltip from "../LabelTooltip";
import TextField from "../TextField";
import Styles from "./styles";



type Country = {
  code: string;
  name: string;
}

interface Props extends React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement
> {
  country: string;
  countryCh: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setCountry: Dispatch<SetStateAction<string>>;
  search: string;
  searchCh: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setSearch?: Dispatch<SetStateAction<string>>;
}

function CountrySelect({
  country,
  countryCh,
  setCountry,
  search,
  searchCh,
  setSearch
}: Props) {
  const selectRef = useRef<HTMLDivElement>(null);
  const [showResults, setShowResults] = useState<boolean>(false);
  const countryData: Country[] = useMemo(() => (
    countries.getData()
  ), []);

  const filteredCountries = useMemo(() => (
    search ? countryData.filter((country) =>
      country.name.toLowerCase().includes(search.toLowerCase())
    ) : countryData
  ), [search, countryData]);

  const closeResults = () => {
    setShowResults(false);
  }
  const clearCountry = (e: MouseEvent) => {
    e.preventDefault();
    setCountry("");
    if (setSearch)
      setSearch("");
    closeResults();
  }

  // Effect for closing element on outside click
  useEffect(() => {
    const handleOutsideClick: EventListener = (event) => {
      const mouseEvent = event as any;
      const target = mouseEvent.target as Node;
      if (selectRef.current && !selectRef.current.contains(target)) {
        closeResults();
      } else {
        setShowResults(true);
      }
    }
    document.addEventListener(
      "mousedown",
      handleOutsideClick
    );

    return () => (
      document.removeEventListener(
        "mousedown",
        handleOutsideClick
      )
    );
  }, [closeResults]);

  // Effect for closing element on Escape key press
  useEffect(() => {
    const handleEscapePress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeResults();
      }
    }
    document.addEventListener(
      "keydown",
      handleEscapePress
    );

    return () => (
      document.removeEventListener(
        "keydown",
        handleEscapePress
      )
    );
  }, [closeResults]);



  return (
    <Styles
      className="country-select flex align-end"
      ref={selectRef}
    >
      <div className="flex-column countries">
        <div className="field-column">
          <LabelTooltip htmlFor="country-search">
            Country
          </LabelTooltip>
          <TextField
            name="country-search"
            value={search}
            onChange={searchCh}
            required={false}
            placeholder="Filter country"
            autoComplete="off"
          />
        </div>
        {showResults ? (
          <ul className="country-list">
            {filteredCountries.map((c) => (
              <RadioButtonField
                key={c.code}
                name="country"
                value={c.code}
                selectedValue={country}
                onChange={countryCh}
              >
                <div className="flex align-center">
                  <div className="flag">
                    {getFlagEmoji(c.code)}
                  </div>
                  <div className="name">
                    {c.name}
                  </div>
                </div>
              </RadioButtonField>
            ))}
          </ul>
        ) : null}
      </div>
      {country ? (
        <button
          className="selected-country flex align-center"
          onClick={clearCountry}
        >
          <div
            className="country flex">
            <div className="flag">
              {getFlagEmoji(country)}
            </div>
            <div className="name">
              {getName(country)}
            </div>
          </div>
        </button>
      ) : null}
    </Styles>
  );
}

export default CountrySelect;