import { ReactNode, useEffect, useState } from "react";
import { firstTwoWords, optionalString } from "../../../../utils/strings";
import { Address } from "../../../../requests/addresses/types";
import { getName } from "country-list";
import { CardLoader } from "../../../../components/layout";



type TableData = {
  name: string;
  description: ReactNode;
}


type Props = {
  address: Address | null;
}

function AddressData({ address }: Props) {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const setAddressData = (address: Address) => {
    const {
      addressLine1, city, country, postCode,
      countryCode, phoneNumber, houseName, houseNumber,
      street, flat
    } = address;

    const addressData: TableData[] = [
      {
        name: "Address Line 1",
        description: optionalString(addressLine1)
      },
      {
        name: "House Name",
        description: optionalString(houseName)
      },
      {
        name: "House Number",
        description: optionalString(houseNumber)
      },
      {
        name: "Flat",
        description: optionalString(flat)
      },
      {
        name: "Street",
        description: optionalString(street)
      },
      {
        name: "City",
        description: optionalString(city)
      },
      {
        name: "Post Code",
        description: optionalString(postCode)
      },
      {
        name: "Country",
        description: (
          country
            ? `${firstTwoWords(getName(country))} (${country})`
            : "-"
        )
      },
      {
        name: "Phone Number",
        description: (countryCode && phoneNumber) ?
          `+${countryCode} ${parseInt(phoneNumber.toString())}`
          : "-"
      }
    ]

    setTableData(addressData);
  }

  useEffect(() => {
    if (!address) return;
    setAddressData(address);
  }, [address])



  return (
    !(address) ? (
      <CardLoader />
    ) : (
      <div className="card table-data form-width flex-fill">
        <h2 className="title jumbo">
          Address
        </h2>
        {tableData.map((data) =>
          <div
            key={data.name}
            className="table-row flex justify-between align-center"
          >
            <div className="name">
              {data.name}
            </div>
            <div className="description">
              {data.description}
            </div>
          </div>
        )}
      </div>
    )
  )
}

export default AddressData;