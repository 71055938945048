import { useEffect, useRef, useState } from "react";

import { ChildFormProps } from "../../../components/forms/FormSteps/types";
import { FormField } from "../../../components/forms/Form/types";
import { EmailTemplate } from "../../../requests/emails/types";
import { Form } from "../../../components/forms";



function ComposeEmail({
  values,
  template,
  setValues,
  FormNavigation,
  onSubmit
}: ChildFormProps & {
  onSubmit: () => void;
  template: EmailTemplate | undefined
}) {
  const formRef = useRef<HTMLFormElement>(null);
  const [fields, setFields] = useState<FormField[]>([]);


  /****************/
  /** Form Fields */
  const staticFields: FormField[] = [
    {
      type: "react-node",
      name: "title",
      label: "",
      disabled: true,
      groupClass: "field-group",
      reactNode: (
        <div className="field-column template-summary">
          <h1 className="title">
            {template?.name}
          </h1>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="link underline"
            href={`https://app.mailersend.com/templates/${template?.id
              }/edit`}
          >
            View / Edit This Template
          </a>
          <div className="field-column template-preview">
            <img src={template?.image_path || ""} alt="" />
          </div>
        </div>
      )
    },
    {
      type: "email",
      name: "username",
      label: "Email",
      placeholder: "Recipient's Email",
      groupClass: "field-group"
    }
  ]


  useEffect(() => {
    if (!template) return;
    const templateHasVars = Object.keys(
      template.variables.personalization
    ).length > 0;
    if (templateHasVars) {
      const varFields: FormField[] = [];
      for (const variable in template.variables.personalization) {
        const field: FormField = {
          type: "text",
          name: variable,
          label: variable,
          placeholder: variable,
          groupClass: "field-row variables"
        }
        varFields.push(field);
      }
      setFields([
        ...staticFields,
        ...varFields
      ]);
    } else {
      setFields([
        ...staticFields
      ]);
    }
  }, [template])



  return (
    <Form
      values={values}
      setValues={setValues}
      fields={fields}
      onSubmit={onSubmit}
      className="compose-email"
      ref={formRef}
    >
      <FormNavigation
        next={
          <button
            className="button auto-width"
            type="submit"
          >
            Create & Send Email
          </button>
        }
      />
    </Form>
  );
}

export default ComposeEmail;