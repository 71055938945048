import { commaDelimited } from "../../../utils/numbers";
import Styles from "./styles";



type Props = {
  total: number;
  value: number;
  valueStr?: string;
  title: string;
  color?: string;
}

function ProgressBar({
  total, value, title,
  valueStr, color
}: Props) {
  return (
    <Styles color={color}>
      <div className="flex label">
        <div className="title">
          {title}
        </div>
        <div className="value">
          {valueStr
            ? valueStr
            : commaDelimited(value)
          }
        </div>
      </div>
      <div className="bar">
        <div
          className="fill"
          style={{
            width: `${(value / total) * 100}%`
          }}
        />
      </div>
    </Styles >
  )
}

export default ProgressBar;