import Loader, { Props as LoaderProps } from "../Loader";
import Styles from "./styles";



type Props = LoaderProps;

function AppLoader(props: Props) {
  const { ...loaderProps } = props
  return (
    <Styles className="app-loader">
      <Loader {...loaderProps} />
    </Styles>
  );
}

export default AppLoader;