import axios from "axios";

import { useAppModal } from "../../components/layout/AppModal/utils";
import useAppActions from "../../redux/actions/app";
import {
  RequestPasswordResetData,
  ResetPasswordData,
  SetCollabPassword
} from "./types";



const useAuth = () => {
  const { setAppLoading } = useAppActions();
  const { setAppModal } = useAppModal();


  /*****************************************/
  /** Reset a password on behalf of a user */
  const collabSetPassword = (data: SetCollabPassword) => {
    return new Promise<any>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/auth/collab-set-password/`,
        method: "post",
        data
      }).then((res) => {
        resolve(res.data);
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }

  /***************************/
  /** Request Password Reset */
  const requestPasswordReset = (data: RequestPasswordResetData) => {
    return new Promise<void>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/auth/request-password-reset`,
        method: "post",
        data
      }).then((res) => {
        let message = res.data.details[0];
        if (!(message && (typeof message === "string"))) {
          message = "A verification code has been sent to your email!"
        }
        setAppModal({
          title: "Verification Code Sent",
          children: message
        });
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  }

  /*******************/
  /** Password Reset */
  const resetPassword = (data: ResetPasswordData) => {
    return new Promise<any>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/auth/reset-password`,
        method: "post",
        data
      }).then((res) => {
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }
  return {
    collabSetPassword,
    requestPasswordReset,
    resetPassword
  }
}

export default useAuth;