import { RouteType } from "../types";
import { emailPaths } from "./paths";
import {
  CreateEmail,
  EmailList,
  ViewEmail
} from "../../pages/Emails/pages";



/*****************/
/** Email Routes */
export const emailRoutes: RouteType[] = [
  {
    path: emailPaths.LIST,
    element: <EmailList />
  },
  {
    path: emailPaths.CREATE,
    element: <CreateEmail />
  },
  {
    path: emailPaths.VIEW,
    element: <ViewEmail />
  }
]