import { useEffect, useState } from "react";

import {
  ReactComponent as ArrowBottom
} from "../../../assets/vector-images/generic/arrow-bottom.svg";
import {
  ReactComponent as ArrowTop
} from "../../../assets/vector-images/generic/arrow-top.svg";
import { TableColumn, TableRow, TableProps } from "./types";
import { useQueryParams } from "../../../utils/components";
import { commaDelimited } from "../../../utils/numbers";
import binoculars_icon from
  "../../../assets/vector-images/panel/binoculars.svg";
import TablePagination from "./Pagination";
import { useTableUtils } from "./utils";
import InfoPanel from "../InfoPanel";
import Styles from "./styles";
import Loader from "../Loader";



function Table(props: TableProps) {
  const {
    columns,
    rowId,
    results: initialData,
    pages,
    onRowClick,
    draggable = false,
    className,
    noData,
    loading,
    orderBy: initialOrderBy,
  } = props;
  const { queryParams, updateFilters } = useQueryParams();
  const encodedOrderBy = queryParams.get("orderBy") || initialOrderBy || "";
  const [orderBy, setOrderBy] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>(initialData ?? []);
  const {
    handleDragStart, handleDragOver, handleDrop, handleRowClick
  } = useTableUtils(props, tableData, setTableData);


  /**************************/
  /** Update Order by Param */
  const updateOrderBy = (column: TableColumn) => {
    const [currentKey, currentDirection] = orderBy;
    const columnKey = column.orderByKey;
    const direction = (
      (currentKey === columnKey) && (currentDirection === "asc")
        ? "desc" : "asc"
    );
    const newOrderBy = `${column.orderByKey},${direction}`;
    updateFilters("orderBy", newOrderBy);
  }


  useEffect(() => {
    setTableData(initialData ?? []);
  }, [initialData])

  useEffect(() => {
    if (!encodedOrderBy) return;
    const values = decodeURIComponent(encodedOrderBy).split(",")
    if (values.length > 1)
      setOrderBy(values);
  }, [encodedOrderBy])



  return (
    <Styles className={`table-root ${className ? className : ""}`}>
      <div className="card-wrapper">
        <div className="table-wrapper">
          <table>
            {/* HEADING */}
            <thead>
              <tr>
                {columns.map((column: TableColumn, index) =>
                  <th
                    key={index}
                    className={column.className || ""}
                  >
                    {column.orderByKey ? (
                      <div
                        className="column-sort flex inline center"
                        onClick={() => updateOrderBy(column)}
                      >
                        <div className="column-title">
                          {column.title}
                        </div>
                        <div className="sort flex column center">
                          <div className={`icon ${(
                            (column.orderByKey === orderBy[0]) &&
                            (orderBy[1] === "asc"))
                            ? "active" : ""}`}
                          >
                            <ArrowTop />
                          </div>
                          <div className={`icon ${(
                            (column.orderByKey === orderBy[0]) &&
                            (orderBy[1] === "desc"))
                            ? "active" : ""}`}
                          >
                            <ArrowBottom />
                          </div>
                        </div>
                      </div>
                    ) : (
                      column.title
                    )}
                  </th>
                )}
              </tr>
            </thead>
            {/* BODY */}
            {loading ? (null) : (
              <tbody>
                {tableData.map((row: TableRow, index: number) =>
                  <tr
                    key={rowId ? row[rowId] : row.id}
                    className={`${onRowClick ? "clickable" : ""}`}
                    draggable={draggable}
                    onDragStart={handleDragStart(index)}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop(index)}
                  >
                    {columns.map((column: TableColumn) =>
                      <td
                        key={`${row.id}-${column.key}`}
                        onClick={handleRowClick(rowId
                          ? row[rowId] : row.id, column.key)}
                        className={column.className || ""}
                      >
                        {column.content
                          ? column.content(row)
                          : row[column.key]
                        }
                      </td>
                    )}
                  </tr>
                )}
              </tbody>
            )}
          </table>
          {!loading && tableData.length === 0 ? (
            <InfoPanel
              icon={noData?.icon ?? binoculars_icon}
              title={noData?.title ?? "No Data"}
            >
              <div className="description al-center grey-text">
                {noData && noData.description ? (
                  noData.description
                ) : (
                  <>
                    There were no results for this search term.<br />
                    Please try another term and we'll show{" "
                    }results if there are any.
                  </>
                )}
              </div>
            </InfoPanel>
          ) : null}
          {loading ? (
            <div className="table-loading">
              <Loader />
            </div>
          ) : null}
        </div>

        {/* PAGINATION */}
        {pages && pages > 0 ? (
          <TablePagination {...props} />
        ) : null}
      </div>

      {props.count ? (
        <div className="total-count">
          Total Results: {commaDelimited(props.count)}
        </div>
      ) : (null)}
    </Styles>
  );
}

export default Table;