import axios from "axios";

import { ErrorResponse, PaginatedResponse } from "../../types";
import { Kyc, KycWithUser } from "./types";



/*  ######################### */
/** ## KYC NETWORK REQUESTS # */
/*  ######################### */
const useKyc = () => {
  const getKyc = (kycId: string | number) => {
    return new Promise<KycWithUser>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/kyc/${kycId}`
      }).then((res) => {
        const resData = res.data;
        resolve(resData as KycWithUser);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  const listKyc = (params: any) => {
    return new Promise<PaginatedResponse<KycWithUser>>((
      resolve, reject
    ) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/kyc/list`,
        params
      }).then((res) => {
        const resData = res.data;
        resolve(resData as PaginatedResponse<KycWithUser>);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  const updateKyc = (id: number, data: any) => {
    return new Promise<Kyc>((
      resolve, reject
    ) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/kyc/${id}`,
        method: "patch",
        data
      }).then((res) => {
        const resData = res.data;
        resolve(resData as Kyc);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    getKyc,
    listKyc,
    updateKyc
  }
}

export default useKyc;