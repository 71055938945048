import axios from "axios";

import { ErrorResponse, PaginatedResponse } from "../../types";
import { Tribe } from "./types";



/*  ####################### */
/** ## TRIBE API REQUESTS # */
/*  ####################### */
const useTribe = () => {
  /************************/
  /** List Tribe Accounts */
  const listTribeAccounts = (params?: Record<string, string>) => {
    return new Promise<PaginatedResponse<Tribe>>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_PAYMENTS_API_URL
          }/v2/tribe/`,
        ...(params ? { params } : {})
      }).then((res) => {
        const resData = res.data as PaginatedResponse<Tribe>;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /****************************/
  /** Get Tribe Accounts by ID*/
  const getTribeAccount = (id: string) => {
    return new Promise<Tribe>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_PAYMENTS_API_URL
          }/v2/tribe/${id}`,
      }).then((res) => {
        const resData = res.data as Tribe;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    listTribeAccounts,
    getTribeAccount
  }
}

export default useTribe;