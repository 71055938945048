import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";

import { FamilyCrest, PageHeader, Table } from "../../../../components/layout";
import { TableColumn } from "../../../../components/layout/Table/types";
import user_icon from "../../../../assets/vector-images/generic/user-icon.svg";
import { accountPaths } from "../../../../routes/accounts/paths";
import { usePageControls } from "../../../../utils/components";
import { Family } from "../../../../requests/families/types";
import { User } from "../../../../requests/users/types";
import useFamilies from "../../../../requests/families";
import { URLParams } from "../../../../constants";
import Styles from "./styles";



const FamilyDetail = () => {
  const { familyId } = useParams<URLParams>();
  const { getFamily, listFamilyUsers } = useFamilies();
  const { previousPage } = usePageControls();
  const [family, setFamily] = useState<Family<undefined | User[]>>();
  const [users, setUsers] = useState<User[]>([]);


  const columns: TableColumn[] = [
    {
      title: "Name",
      key: "name",
      content: (user: User) => (
        <Link
          to={accountPaths.userDetail(user.id)}
          className="table-title"
        >
          <img
            alt=""
            src={(user.avatar?.url)
              ? user.avatar.url
              : user_icon}
          />
          <div>{user.firstName} {user.lastName}</div>
          <div className={`status ${user.familyRole}`}>
            {user.familyRole === "admin"
              ? "Parent"
              : "Learner"
            }
          </div>
        </Link>
      )
    },
    {
      title: "Email / Username",
      key: "username",
      className: "al-left"
    },
    {
      title: "Last Active",
      key: "lastActive",
      content: (user: User) => (
        <div className="grey">
          {user.lastLogin ? (
            moment(user.lastLogin).format("Do MMM YYYY, hh:mma")
          ) : "Has not signed-in yet"}
        </div>
      )
    }
  ]

  useEffect(() => {
    if (!familyId) {
      previousPage();
      return;
    };
    getFamily(familyId)
      .then((family) => {
        setFamily(family);
        listFamilyUsers(familyId)
          .then((users) => {
            setUsers(users);
          })
          .catch(() => null)
      })
      .catch(() => null);
  }, [familyId])



  return (
    <Styles className="root-content">
      <PageHeader
        className="username-link"
        title={family
          ? family.name ? family.name : family.id
          : "Family's Details"
        }
      />
      {family ? (
        <div className="page-wrapper">
          <div className="merged-table">
            <FamilyCrest family={family} />
            <Table
              columns={columns}
              results={users}
            />
          </div>
        </div>
      ) : null}
    </Styles>
  )
}

export default FamilyDetail;