import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

import { AuthRouteContext } from "../../../components/auth/AuthRoute";
import { SelectField } from "../../../components/forms";
import { EventFunnel } from "../../../requests/eventFunnels/types";
import { generateEventFunnel, generateFakeData } from "../../../constants/events";
import useEvents from "../../../requests/events";



function Events() {
  const { user } = useOutletContext<AuthRouteContext>();

  const [eventFunnel, setEventFunnel] = useState<EventFunnel | null>(null);
  useEffect(() => {
    // Generate fake funnel data on mount
    const funnel = generateEventFunnel();
    setEventFunnel(funnel);
  }, []);


  return (
    <>
      <div className="page-filters">

      </div>
      <div className="page- wrapper">
        {/* {eventFunnel && <EventFunnelChart  />} */}


        <EngagementFunnelChart />
      </div>
    </>
  );
}

export default Events;


interface EventFunnelProps {
  funnel: EventFunnel;
}

const EngagementFunnelChart: React.FC = () => {
  const { tempFunnel } = useEvents();
  const [eventFunnel, setEventFunnel] = useState<EventFunnel | null>(null);
  const [tempData, setTempData] = useState<Record<string, number>[]>();

  useEffect(() => {
    setEventFunnel(generateFakeData());
    tempFunnel()
      .then((resData) => {
        setTempData(resData);
      }).catch(() => null)
  }, []);


  const chartData = tempData
    ? {
      labels: tempData.map(step => step.name),
      datasets: [
        {
          label: 'Events',
          data: tempData.map(step => step.count), // Count events for each step
          backgroundColor: ['#FFA500', '#008000', '#00FF00', '#808080', '#0000FF'],
        },
      ],
    }
    : { labels: [], datasets: [] };

  const options = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top' as const, // Fix legend position
      },
      title: {
        display: true,
        text: 'Engagement Funnel',
        fullSize: true,
      },
    },
  };

  return (
    <div>
      {eventFunnel && <Bar options={options} data={chartData} />}
    </div>
  );
};
