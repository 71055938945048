import styled from "styled-components";

import {
  colors, functions, properties, resolutions, snippets
} from "../../../styles";



const Styles = styled.div`
  padding: 1rem;
  min-width: ${properties.sidebarWidth};
  height: calc(100vh - ${properties.navHeaderHeight});
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background-color: ${colors.white};
  border-right: 1px solid ${colors.grey1};
  ${snippets.scrollBar}
  z-index: ${properties.highestZIndex};
  @media(max-width: ${resolutions.tabletLarge}) {
    top: ${properties.navHeaderHeight};
    left: 0;
    width: 100vw;
    position: absolute;
    transform: translateX(-100%);
    transition: transform 150ms ease-in-out;
    &.open {
      transform: translateX(0%);
    }
  }
  .nav-links {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding-right: 0.5rem;
    .nav-link {
      padding: 10px 16px;
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 24px;
      gap: 16px;
      user-select: none;
      .icon {
        width: 17px;
        height: 17px;
        svg {
          width: inherit;
          height: inherit;
        }
      }
      .text {
        font-size: 15px;
      }
      &.active {
        background-color: ${functions.rgba(colors.primary, 0.1)};
        .icon {
          svg {
            path {
              stroke: ${colors.primary};
            }
          }
        }
        .text {
          color: ${colors.primary} !important;
          font-weight: 600;
        }
      }
    }
    .parent-link {
      .nav-link.parent {
        font-weight: 600;
      }
      .children-links {
        display: flex;
        flex-direction: column;
        margin-left: 2.5rem;
        .nav-link.child {
          padding: 5px 10px;
          width: fit-content;
          .text {
            font-size: 0.8rem;
            color: ${colors.grey6};
          }
        }
      }
    }
  }

  #userPanel {
    margin-top: auto;
    position: relative;
    .user {
      display: flex;
      gap: 10px;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        &.placeholder {
          background-color: ${functions.colorLuminance(functions.randomColor, -0.5)};
          color: ${colors.white};
          text-transform: uppercase;
          font-size: 17px;
          ${snippets.flexCenter};
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        gap: 3px;
        &>* {
          width: fit-content;
        }
      }
    }
    .user-dropdown {
      position: absolute;
      top: -100%;
      transform: translate(50%, -50%);
      right: 50%;
      padding: 0.5rem;
      min-width: 7rem;
      background-color: #fff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      font-size: 0.8rem;
      background-color: ${colors.white};
      &:hover {
        background-color: ${colors.grey1};
      }
    }
  }
`;

export default Styles;