import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { Loader, ModelDetail, PageHeader } from "../../../components/layout";
import { ModelDetailData } from "../../../components/layout/ModelDetail";
import { emailPaths } from "../../../routes/emails/paths";
import { Email } from "../../../requests/emails/types";
import useEmails from "../../../requests/emails";
import { URLParams } from "../../../constants";
import { useEmailDetailUtils } from "./utils";
import Styles from "./styles";



function ViewEmail() {
  const { emailId } = useParams<URLParams>();
  const { getEmail } = useEmails();
  const [loading, setLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<Email>();
  const [emailData, setEmailData] = useState<ModelDetailData[]>([]);
  const { buildEmailData } = useEmailDetailUtils();


  /**********************/
  /** Get Email Details */
  const retrieveEmail = async (id: number | string) => {
    setLoading(true);
    try {
      const email = await getEmail(id);
      setEmail(email);
      setEmailData(buildEmailData(email));
    } catch { }
    finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    if (!emailId) return;
    retrieveEmail(emailId);
  }, [emailId])



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader />

      {/* Main Content */}
      <div className="page-wrapper">
        {loading ? (
          <Loader />
        ) : (
          <ModelDetail
            title={email?.templateName ?? null}
            data={emailData}
          />
        )}
        <Link
          className="button auto-width mlr-auto submit"
          to={emailPaths.LIST}
        >
          View All Emails
        </Link>
      </div>
    </Styles>
  );
}

export default ViewEmail;