import axios from "axios";

import useAppActions from "../redux/actions/app";
import { Product } from "../types/offers";


const useProducts = () => {
  const { setAppLoading } = useAppActions();


  /******************************/
  /** Build/Update product list */
  const buildProducts = () => {
    return new Promise<any>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_OFFERS_API_URL
          }/v2/sochitel/build`,
      }).then((res) => {
        resolve(res.data)
      }).catch((error) => {
        reject(error);
      });
    });
  }

  /**********************************/
  /** List curated list of products */
  const listProducts = (params?: any) => {
    return new Promise<any>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_OFFERS_API_URL
          }/v1/products/admin`,
        params
      }).then((res) => {
        resolve(res.data)
      }).catch((error) => {
        reject(error);
      }).finally(() => {
        setAppLoading(false);
      });
    });
  }

  /**********************/
  /** Get Product by ID */
  const getProduct = (id: string | number) => {
    return new Promise<any>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_OFFERS_API_URL
          }/v1/products/admin/${id}`,
      }).then((res) => {
        resolve(res.data as Product);
      }).catch((error) => {
        reject(error);
      }).finally(() => {
        setAppLoading(false);
      });
    });
  }

  /************************************/
  /** Paritially Update Product by ID */
  const updateProduct = (id: string | number, data: any) => {
    return new Promise<any>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_OFFERS_API_URL
          }/v1/products/${id}`,
        method: "patch",
        data
      }).then((res) => {
        resolve(res.data)
      }).catch((error) => {
        reject(error);
      }).finally(() => {
        setAppLoading(false);
      });
    });
  }


  return {
    buildProducts,
    listProducts,
    getProduct,
    updateProduct
  }
}

export default useProducts;