import AffirmationList from ".";



function Published() {
  return (
    <AffirmationList isPublished={true} />
  );
}

export default Published;