import { profilePaths } from "./paths";
import { RouteType } from "../types";
import {
  PrManage,
  PrPersonal
} from "../../pages/Profile/pages";



/******************/
/* Profile Routes */
export const profileRoutes: RouteType[] = [
  {
    path: profilePaths.PERSONAL,
    element: <PrPersonal />
  },
  {
    path: profilePaths.MANAGE,
    element: <PrManage />
  }
]