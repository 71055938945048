import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import filter_icon from "../../../../assets/vector-images/generic/filter.svg";
import useCrests, { AllCrestTypes } from "../../../../requests/crests";
import { useQueryParams } from "../../../../utils/components";
import { assetPaths } from "../../../../routes/assets/paths";
import { SelectField } from "../../../../components/forms";
import { Crest } from "../../../../requests/crests/types";
import useAppActions from "../../../../redux/actions/app";
import { Card, PageHeader } from "../../../../components/layout";
import { useInput } from "../../../../utils/forms";
import Styles from "./styles";



function CrestList() {
  const { locationSearch, queryParams, updateFilters } = useQueryParams();
  const type = queryParams.get("type") || "";
  const { listCrests } = useCrests();
  const { setAppLoading } = useAppActions();
  const [crests, setCrests] = useState<Crest[]>([]);
  const {
    value: typeValue,
    setValue: setTypeValue,
  } = useInput({
    initialValue: type,
    selectValues: AllCrestTypes.map(option => option.value)
  });

  const updateType = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setTypeValue(value);
    updateFilters("type", value);
  }

  const getCrests = () => {
    const params = { type };
    setAppLoading(true);
    listCrests(params)
      .then((results) => {
        setCrests(results);
      })
      .catch(() => null)
      .finally(() => {
        setAppLoading(false);
      })
  }

  useEffect(() => {
    getCrests();
  }, [locationSearch])



  return (
    <Styles className="root-content">
      <PageHeader>
        {/* Filters */}
        <SelectField
          options={AllCrestTypes}
          value={typeValue}
          onChange={updateType}
          placeholder="All Crests"
          icon={filter_icon}
        />
        <Link
          to={assetPaths.ADD_CREST}
          className="button sm mid-radius">
          + New
        </Link>
      </PageHeader>

      {/* Main Content */}
      <div className="page-wrapper">
        <div className="flex wrap crest-list">
          {crests.map((crest) => (
            <Card key={crest.id}>
              <figure className="flex align-start">
                <img src={crest.url} alt="" />
                <figcaption>
                  <h4 className="title">
                    {crest.name}
                  </h4>
                  <div className="description">
                    {crest.description}
                  </div>
                </figcaption>
              </figure>
              <Link
                to={assetPaths.editCrest(crest.id)}
                className="button sm auto-width mt-1 ml-auto"
              >
                Edit Crest
              </Link>
            </Card>
          ))}
        </div>
      </div>
    </Styles>
  );
}

export default CrestList;