import AffirmationList from ".";



function Draft() {
  return (
    <AffirmationList isPublished={false} />
  );
}

export default Draft;