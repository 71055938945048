import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";

import { Loader, PageHeader } from "../../../../components/layout";
import { Form } from "../../../../components/forms";
import { useSubscriptionEditUtils } from "./utils";
import { URLParams } from "../../../../constants";



function EditSubscription() {
  const { subscriptionId } = useParams<URLParams>();
  const formRef = useRef<HTMLFormElement>(null);
  const {
    formFields, formValues, loading,
    retrieveSubscription, loadPlanPrices, onSubmit, setFormValues
  } = useSubscriptionEditUtils(subscriptionId);


  useEffect(() => {
    loadPlanPrices();
  }, [])

  useEffect(() => {
    if (!subscriptionId) return;
    retrieveSubscription(subscriptionId);
  }, [subscriptionId])



  return (
    <div className="root-content">
      {/* Header */}
      <PageHeader />
      <div className="page-wrapper">
        {loading ? (
          <Loader />
        ) : (
          <Form
            fields={formFields}
            values={formValues}
            setValues={setFormValues}
            onSubmit={onSubmit}
            ref={formRef}
          >
            <button
              className="button submit"
              type="submit"
            >
              Override Subscription
            </button>
          </Form>
        )}
      </div>
    </div>
  );
}

export default EditSubscription;